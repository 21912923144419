export const styles = theme => ({

	root:{
		color: 'rgba(0,0,0,.87)',
		weight: '700',
		'& p': {
			color: '#8a8a8a',
			'& span': {
				color: 'blue'
			}

		}
	},
});


