export const colors = [
    "#f44336",
    "#E91E63",
    "#9C27B0",
    "#673AB7",
    "#3F51B5",
    "#2196F3",
    "#03A9F4",
    "#00BCD4",
    "#009688",
    "#4CAF50",
    "#8BC34A",
    "#CDDC39",
    "#FFEB3B",
    "#FFC107",
    "#FF9800",
    "#FF5722",
    "#795548",
    "#9E9E9E",
    "#607D8B"
]

export const appColor = {
    primary: {
        main: "#8BE4DB"
    },
    secondary: {
        main: "#8BE4DB"
    },
    other: {
        main: "#fafafa"
    },
    grey: "#AAAAAA",
    darkGrey: "#576271",
    lightGrey: "#eeeeee",
    red: "#f44336",
    darkBlue: "#2d3646",
    white: "#ffffff",
    green: "#43a047",
    orange: "#ef6c00",
    black: "#050505",
    deepPurple: "#7e57c2"
}
