import React from "react"
import { withRouter } from "react-router"
import { useAuthContext } from '../../../contexts/auth/Auth';
import ListContainer from '../../list/container/List.container'
import Request from '../api/RiskFactor.api'
import { selectRiskFactors } from "../selector/RiskFactor.selector";


const RiskFactorsListContainer = ({
    
    children,match,
}) => {

    const patientId = match.params.patient
    const { authToken } = useAuthContext()

    return (
        <ListContainer
            request={Request.riskFactorList}
            params={{
                access_token: authToken.access_token,
                patientId: patientId,
            }}
            node='RISK_FACTOR'
            selector={selectRiskFactors}>
            {children}
        </ListContainer>
    )
}

export default withRouter(RiskFactorsListContainer)