import String from "../../res/Strings_ITA.res"

export const GenderConf = [
    {
        label: String.common.male,
        value: 'Male',
    },
    {
        label: String.common.female,
        value: 'Female'
    }
]

export const BeforeAfterMealConf = [
    {
        label: String.therapy.after_meals,
        value: 'After Meal',
    },
    {
        label: String.therapy.empty_stomach,
        value: 'Empty Stomach'
    },
    {
        label: String.therapy.indifferent,
        value: 'Indifferent'
    },
]

export const AmountOfDrugToBeTaken = [
    {
        label: String.therapy.whole,
        value: 'Whole'
    },
    {
        label: String.therapy.half,
        value: 'Half'
    },
    {
        label: String.therapy.quarter,
        value: 'A quarter'
    },
    {
        label: String.therapy.none,
        value: 'None'
    }
]