import { useState } from "react";

const useModal = () => {
  const [isModalShowing, setIsModalShowing] = useState(false);

  function toggleModal() {
    setIsModalShowing(!isModalShowing);
  }

  function openModal() {
    setIsModalShowing(true);
  }

  function closeModal() {
    setIsModalShowing(false);
  }

  return {
    isModalShowing,
    toggleModal,
    openModal,
    closeModal
  };
};

export default useModal;
