import Manager from '../../../api/Manager.api'
import Endpoints from '../../../api/Endpoints.api'


const LoginRequest = ({
    username,
    password,
}) => {
    var body = new FormData();
    body.set("username", username);
    body.set("password", password);
    body.set("grant_type", "password");
    body.set("client_id", Manager.apiConfig.clientId);
    return body
}


const login = ({
    values: {
        username,
        password
    }
}) => {
    return Manager.client().post(Endpoints.login,LoginRequest({username, password}))
}

const Request = {
    login
}


export default Request 