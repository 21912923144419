import React from "react"
import { withStyles } from "@material-ui/core/styles"
import { FormControlLabel, Checkbox } from "@material-ui/core"

import { styles } from "../../../styles/form/checkbox/Checkbox.component.style"

const CheckboxTemplate = ({
    classes,
    label,
    required = false,
    disabled = false,
    inputRef,
    input,
    meta,
    ...rest
}) => {
    return (
        <FormControlLabel
            className={classes.root}
            control={
                <Checkbox
                    onChange={input.onChange}
                    value={input.value}
                    color="primary"
                    checked={input.checked}
                    disabled={disabled}
                />
            }
            label={label}
        />
    )
}

export const CheckboxComponent = withStyles(styles)(CheckboxTemplate)
