import React from "react"
import { withStyles } from "@material-ui/core/styles"
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';

import { withRouter } from "react-router"
import { styles } from "./Login.page.style"
import { LoginFormConf } from '../../conf/login/Login.conf'
import { Path } from '../../conf/paths/Paths.conf'

import ProgressComponent from '../../library/materialUi/progress/Progress.component'
import LoginLogo from "../../assets/img/login/logo-login@3x.png"

import Form from '../../components/form/Form.component';

import AuthenticationContainer from '../../core/authentication/container/Authentication.container'
import Request from '../../core/login/api/Login.api'


const LoginPage = ({
    history,
    classes,
}) => {    

    return (
        <Box className={classes.root}>
            <Box width={1 / 3} />
            <Box>
                <Paper elevation={4}>
                    <AuthenticationContainer
                        request={Request.login}
                        callback={({ success }) => {
                            if (success) {                                                                
                                history.push('/home', Path.home)
                            }
                        }}>
                        {({ item, onSignIn }) => (
                            <>                                
                                {item.loading ? <ProgressComponent /> : null}                                
                                <div className={classes.login}>
                                    <Avatar className={classes.logo} variant="square">
                                        <img src={LoginLogo} className={classes.image} />
                                    </Avatar>
                                    <Form
                                        body={LoginFormConf.body}
                                        footer={LoginFormConf.footer}
                                        columns={LoginFormConf.columns}
                                        onSubmit={onSignIn}
                                        style={{ padding: '0px' }}
                                        loading={false}
                                    />
                                </div>
                            </>
                        )}
                    </AuthenticationContainer>
                </Paper>
            </Box>
            <Box width={1 / 3} />
        </Box>
    )
}

export default withRouter(withStyles(styles)(LoginPage))