import React from "react"
import { useAuthContext } from '../../../contexts/auth/Auth';
import { withRouter } from "react-router"

import AddContainer from '../../add/container/Add.container'
import { selectHistory } from "../selector/History.selector";
import Request from '../api/History.api'

const HistoryAddContainer = ({
    match,
    children
}) => {

    const patientId = match.params.patient
    const { authToken } = useAuthContext()

    return (
        <AddContainer
            request={Request.historyAdd}
            params={{
                access_token: authToken.access_token,
                patientId: patientId
            }}
            node='HISTORY'
            selector={selectHistory}>
            {children}
        </AddContainer>
    )
}

export default withRouter(HistoryAddContainer)