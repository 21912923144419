import React from "react"
import { connect } from "react-redux"
import UpdateActions from "../actions/Update.actions";

const UpdateContainer = ({
    children,
    params,
    request,
    refresh,
    updateItem,
    item,
    node,    
}) => {


    const onSubmit = (values) => {
        updateItem({
            params: {
                ...params,
                values
            },
            request: request,
            node: node,
            refresh: refresh ? refresh : null,
        })
    }

    return (
        <>
            {children({ item, onSubmit })}
        </>
    )
}

const mapStateToProps = (state, ownProps) => ({
    item: ownProps.selector(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    updateItem: (params) => {
        dispatch(UpdateActions.updateItem(params))
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(UpdateContainer)