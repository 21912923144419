import React from "react"
import _first from 'lodash/first'
import Box from '@material-ui/core/Box';
import Form from '../../components/form/Form.component';

import { WarfarinTherapyConf } from '../../conf/therapy/Therapy.conf'
import WarfarinTherapyAddContainer from '../../core/therapy/containers/WarfarinTherapyAdd.container'
import DividerComponent from "../../library/materialUi/divider/Divider.component";

const WarfarinTherapyContentPage = ({
    edit = false,    
    document = {},
    onCreateDocument
}) => {

    const { body, footer, columns } = WarfarinTherapyConf
    
    return (
        <Box width={1}>

            <DividerComponent />

            {/* ADD */}
            <WarfarinTherapyAddContainer
                onCreateDocument={onCreateDocument}>
                {({ onSubmit }) => (
                    <Form
                        body={body}
                        columns={columns}
                        footer={edit ? footer : null}
                        onSubmit={onSubmit}
                        display='block'
                        style={{ padding: '24px' }}
                        showTitle={true}
                        initialValues={document ? document : null}
                        disabled={!edit}
                    />
                )}
            </WarfarinTherapyAddContainer>
        </Box>
    )
}

export default WarfarinTherapyContentPage