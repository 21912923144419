import React, { useState } from "react"

import _first from 'lodash/first'
import Box from '@material-ui/core/Box';

import { StatusConf } from '../../conf/status/Status.conf';

import ProgressComponent from '../../library/materialUi/progress/Progress.component'
import Form from '../../components/form/Form.component';
import FilterComponent from '../../components/filter/Filter.component'

import StatusListContainer from '../../core/status/containers/StatusList.containers'
import StatusDetailContainer from '../../core/status/containers/StatusDetail.container'
import StatusAddContainer from '../../core/status/containers/StatusAdd.container'

const StatusCardiologicalContentPage = ({
    edit = false,
}) => {

    const { body, footer, columns } = StatusConf
    const [documentId, setDocumentId] = useState(null)
    const [document, setDocument] = useState({})

    return (

        <>
            {/* LIST */}
            <Box width={1}>
                <StatusListContainer>
                    {({ list }) => (
                        <>
                            {list.loading ? <ProgressComponent /> : null}
                            <FilterComponent
                                size={12}
                                loading={list.loading}
                                documents={list.data ? list.data : []}
                                documentDefault={list.data && edit === false ? _first(list.data) : null}
                                onSelectDocument={(id) => setDocumentId(id)}
                                edit={edit} />
                        </>
                    )}
                </StatusListContainer>
            </Box>

            {/* DETAIL */}
            <StatusDetailContainer documentId={documentId}>
                {({ detail }) => {
                    setDocument(detail)
                    return (
                        <Box mt={2}>
                            {detail.loading ? <ProgressComponent /> : null}
                        </Box>
                    )
                }}
            </StatusDetailContainer>

            {/* ADD */}
            <Box width={1}>
                <StatusAddContainer>
                    {({ item, onSubmit, ejectionFractions }) => {

                        body.ejection_fraction.options = ejectionFractions.map(item => ({
                            value: item.id,
                            label: item.name
                        }))

                        return (
                            <Form
                                body={body}
                                footer={edit ? footer : null}
                                columns={columns}
                                onSubmit={onSubmit}
                                style={{ padding: '0px' }}
                                showTitle={true}
                                initialValues={document.data ? document.data : null}
                                disabled={!edit}
                            />
                        )
                    }}
                </StatusAddContainer>
            </Box>
        </>
    )
}

export default StatusCardiologicalContentPage