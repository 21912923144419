import { put, call, takeLatest } from 'redux-saga/effects';
import Actions from '../actions/Authentication.actions';
import MessagesActions from '../../messages/actions/Messages.actions';

function* signInSaga(action) {

    const { params, request, callback = () => { } } = action.payload

    try {

        // Login
        let loginResponse = yield call(request, params);

        // Success
        yield put(Actions.accountSignInSuccessAction())        
        callback({ success: true , token: loginResponse.data})

    } catch (e) {
        yield put(Actions.accountSignInFailureAction(e))
        yield put(MessagesActions.setError(e))
        callback({ success: false })
    }
}

function* signOutSaga(action) {

    const { callback = () => { } } = action.payload

    yield put(Actions.accountSignOutSuccessAction())    
    callback({ success: true })
}


export function* authenticationWatcherSaga() {
    yield takeLatest(Actions.accountSignInAction, signInSaga);
    yield takeLatest(Actions.accountSignOutAction, signOutSaga);
    //yield takeLatest(Actions.accountCheckTokenAction, checkTokenSaga);
}