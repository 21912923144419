import React from "react"
import { EditErrorComponent } from "../../../../materialUi/form/editError/EditError.component"
import { useField } from "react-final-form"

export const Error = ({ name }) => {
    const {
        meta: { touched, error }
    } = useField(name, {
        subscription: {
            touched: true,
            error: true
        }
    })

    return touched && error ? (
        <EditErrorComponent message={error} />
    ) : (
        <EditErrorComponent />
    )
}
