import React, { useState } from 'react'
import { withStyles } from "@material-ui/core/styles";
import { styles } from "./PathologyContent.page.style"

import Box from '@material-ui/core/Box'
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';

import AtrialFibrillationContentPage from './AtrialFibrillationContent.page'
import AtrialFlutterContentPage from './AtrialFlutterContent.page'
import PathologiesContentPage from './PathologiesContent.page'

import { Panels } from '../../conf/pathologies/Pathologies.conf'

const renderPanel = (index, edit) => {

    switch (index) {
        case 0:
            return <PathologiesContentPage edit={edit} />

        case 1:
            return <AtrialFibrillationContentPage edit={edit} />

        case 2:
            return <AtrialFlutterContentPage edit={edit} />

        default:
            break;
    }
    
}


const PathologyContentPage = ({
    classes,
    edit,
}) => {

    const [expanded, setExpanded] = useState(Panels[0].id);
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Box  width='100%'>
            <Box className={classes.root} width={2/3}>
                {Panels.map((panel, index) => (
                    <ExpansionPanel key={index} expanded={expanded === panel.id} onChange={handleChange(panel.id)}>
                        <ExpansionPanelSummary className={classes.summary}>
                            <Typography className={classes.title}>{panel.title}</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails className={classes.details}>
                            {expanded === index ? renderPanel(index, edit) : <div />}
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                ))}
            </Box>
        </Box>
    )
}

export default withStyles(styles)(PathologyContentPage)