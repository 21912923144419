import React from "react"
import { withStyles } from "@material-ui/core/styles"
import { compose } from "recompose"
import DialogTitle from "@material-ui/core/DialogTitle"

import { styles } from "../../../styles/modal/title/ModalTitle.component.style"

const ModalTitle = ({ children, classes }) => {
    return <DialogTitle className={classes.root}>{children}</DialogTitle>
}

const ModalTitleWithHoc = compose(withStyles(styles))(ModalTitle)

export default ModalTitleWithHoc
