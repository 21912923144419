import React from 'react'
import { withStyles } from "@material-ui/core/styles"
import { styles } from "./Form.component.style"
import _isEmpty from 'lodash/isEmpty'
import ProgressComponent from "../../library/materialUi/progress/Progress.component"
import FormProvider from '../../library/components/form/provider';

import Form from '../../library/components/form/ui';
import { EditText } from '../../library/components/form/ui/editText';
import { EditArea } from '../../library/components/form/ui/editArea';
import { AsyncSelect } from '../../library/components/form/ui/select/async/single';
import { AsyncSelectMultiple } from '../../library/components/form/ui/select/async/multiple';
import { Select } from '../../library/components/form/ui/select/single';
import { SelectMultiple } from '../../library/components/form/ui/select/multiple';
import { CalendarPicker } from '../../library/components/form/ui/calendar';
import { Checkbox } from '../../library/components/form/ui/checkbox';
import { Radio } from '../../library/components/form/ui/radio';

import Grid from "@material-ui/core/Grid"
import Box from '@material-ui/core/Box';

const form_element_types = {
    EditArea,
    EditText,
    Select,
    SelectMultiple,
    CalendarPicker,
    Checkbox,
    AsyncSelect,
    AsyncSelectMultiple,
    Radio,
};

const setInitialValues = (data, body) => {

    let object = {}

    Object.keys(body).forEach(key => {

        if (data !== null && !_isEmpty(data)) {
            if (body[key].component === 'Select') {                
                object[key] = data[key].id ? data[key].id : data[key]
            } else {
                object[key] = data[key]
            }
        } else {
            if (body[key].component === 'Checkbox') {
                object[key] = false
            }
        }
    });
    
    return object
}

const FormComponent = ({
    classes,
    body,
    footer,
    columns = [{
        size: 1
    }],
    onSubmit,
    onChange = () => { },
    initialValues,
    disabled = false,
    display = 'flex',
    style,
    showTitle = true,
    loading
}) => {

    return (
        <FormProvider
            onSubmit={onSubmit}
            onChange={onChange}
            body={body}
            footer={footer}
            initialValues={setInitialValues(initialValues, body)}>
            {loading ? <ProgressComponent /> : null}
            <Form style={style}>
                <Box display={display} flexWrap='wrap' flexDirection="row">
                    {columns.map((column, index) => {
                        return (
                            <Box
                                width={column.size}
                                key={index}>
                                <Box
                                    className={classes.content}
                                    borderTop={column.border.top}
                                    borderRight={column.border.right}
                                    borderBottom={column.border.bottom}
                                    borderLeft={column.border.left}>
                                    {showTitle ? <Box className={classes.title}> {column.title} </Box> : null}
                                    <Grid container item spacing={3}>
                                        {Object.keys(body).map(key => {
                                            const element = body[key]
                                            const FormElement = form_element_types[element.component]
                                            return (
                                                element.column === index ?
                                                    <Grid item xs={element.xs} key={key}>
                                                        <FormElement
                                                            item={element}
                                                            calendarType={element.calendarType}
                                                            disableFuture={element.disableFuture}
                                                            options={element.options}
                                                            disabled={element.disabled ? element.disabled : disabled}
                                                            node={element.node} />
                                                    </Grid> : null
                                            )
                                        })}
                                    </Grid>
                                </Box>
                            </Box>
                        )
                    })}
                </Box>
            </Form>
        </FormProvider>
    )
}

export default withStyles(styles)(FormComponent)