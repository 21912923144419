import React from "react"
import { withRouter } from "react-router"
import DashboardComponent from "../../../components/dashboard/Dashboard.component"
import AuthenticationContainer from '../../authentication/container/Authentication.container'
import { usePatient } from '../../../contexts/patient/Patient'

const DashboardContainer = ({
    children,
    history,
}) => {

    const { removePatient } = usePatient();

    const goToLinkAction = (link) => {
        history.push(link)
    }

    return (
        <AuthenticationContainer
            callback={({ success }) => {
                if (success) {
                    removePatient()
                    history.push('/')
                }
            }}>
            {({ onSignOut }) => (
                <DashboardComponent
                    goToLinkAction={goToLinkAction}
                    signOutAction={onSignOut}>
                    {children}
                </DashboardComponent>
            )}
        </AuthenticationContainer>
    )
}


export default withRouter(DashboardContainer)
