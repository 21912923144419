import React from "react"
import { withRouter } from "react-router"
import { useAuthContext } from '../../../contexts/auth/Auth';
import ListContainer from '../../list/container/List.container'
import Request from '../api/Treatment.api'
import { selectTreatments } from "../selector/Treatment.selector";

const TreatmentListContainer = ({
    match,
    children,
    callback,
}) => {

    const patientId = match.params.patient
    const { authToken } = useAuthContext()

    return (
        <ListContainer
            request={Request.treatmentsList}
            params={{
                access_token: authToken.access_token,
                patientId: patientId,
            }}
            callback={callback}
            node='TREATMENTS'
            selector={selectTreatments}>
            {children}
        </ListContainer>
    )
}

export default withRouter(TreatmentListContainer)