import { combineReducers } from "redux"
import { connectRouter } from "connected-react-router"

import AuthenticationActions from '../core/authentication/actions/Authentication.actions'
import { Path } from '../conf/paths/Paths.conf'

import { patientsReducer, patientReducer } from "../core/patients/reducer/Patients.reducer"
import { bloodPressuresReducer, bloodPressureReducer } from "../core/bloodPressure/reducer/BloodPressure.reducer"
import { riskFactorsReducer, riskFactorReducer } from "../core/risk/reducer/RiskFactors.reducer"
import { weightReducer, weightsReducer } from '../core/weight/reducer/Weight.reducer'
import { treatmentsReducer, treatmentReducer } from '../core/treatment/reducer/Treatment.reducer'
import { allergiesReducer, allergyReducer } from '../core/allergies/reducer/Allergies.reducer'
import { historiesReducer, historyReducer } from '../core/history/reducer/History.reducer'
import { statusesReducer, statusReducer } from '../core/status/reducer/Status.reducer'
import { therapiesReducer, therapyReducer } from '../core/therapy/reducer/Therapy.reducer'
import { cigarettesReducer, cigaretteReducer } from '../core/cigarettes/reducer/Cigarettes.reducer'
import { heartRatesReducer, heartRateReducer } from '../core/heartRate/reducer/HeartRate.reducer'
import { pathologiesReducer, pathologyReducer } from '../core/pathology/reducer/Pathology.reducer'

import { selectsReducer } from '../core/selects/reducer/Selects.reducer'

import authenticationReducer from "../core/authentication/reducer/Authentication.reducer"
import messageReducer from '../core/messages/reducer/Messages.reducer'


const user = combineReducers({
    authentication: authenticationReducer
})

const patients = combineReducers({
    patients: patientsReducer,
    patient: patientReducer
})

const bloodPressure = combineReducers({
    bloodPressures: bloodPressuresReducer,
    bloodPressure: bloodPressureReducer,
})

const riskFactor = combineReducers({
    riskFactors: riskFactorsReducer,
    riskFactor: riskFactorReducer,
})

const weight = combineReducers({
    weights: weightsReducer,
    weight: weightReducer,
})

const treatments = combineReducers({
    treatments: treatmentsReducer,
    treatment: treatmentReducer,
})

const history = combineReducers({
    histories: historiesReducer,
    history: historyReducer,
})

const status = combineReducers({
    statues: statusesReducer,
    status: statusReducer,
})
const therapy = combineReducers({
    therapies: therapiesReducer,
    therapy: therapyReducer,
})
const allergies = combineReducers({
    allergies: allergiesReducer,
    allergy: allergyReducer,
})

const cigarettes = combineReducers({
    cigarettes: cigarettesReducer,
    cigarette: cigaretteReducer,
})

const heartRate = combineReducers({
    heartRates: heartRatesReducer,
    heartRate: heartRateReducer,
})

const pathology = combineReducers({
    pathologies: pathologiesReducer,
    pathology: pathologyReducer,
})



const documents = combineReducers({
    bloodPressure: bloodPressure,
    riskFactor: riskFactor,
    weight: weight,
    treatments: treatments,
    history: history,
    status: status,
    therapy: therapy,
    allergies: allergies,
    cigarettes: cigarettes,
    heartRate: heartRate,
    pathology: pathology
})

const appReducer = history =>
    combineReducers({
        router: connectRouter(history),
        messages: messageReducer,
        user: user,
        patients: patients,
        documents: documents,
        selects: selectsReducer
    })

export const rootReducer = history => (state, action) => {

    if (action.type === '@@router/LOCATION_CHANGE' && action.payload.action === 'PUSH') {
        const { location } = action.payload
        switch (location.state.path) {
            case Path.home:
                break;
            case Path.detail:
                state.documents.pathology.pathology = undefined
                break;
            case Path.overview:
                break;
            case Path.personal:
                break;
            case Path.risk:
                state.documents.riskFactor.riskFactor = undefined
                state.documents.pathology.pathology = undefined
                break;
            case Path.visit:
                state.documents.riskFactor.riskFactor = undefined
                state.documents.bloodPressure.bloodPressure = undefined
                state.documents.weight.weight = undefined
                state.documents.history.history = undefined
                state.documents.status.status = undefined
                state.documents.allergies.allergy = undefined
                state.documents.therapy.therapy = undefined
                break;
            case Path.history:
                state.documents.history.history = undefined
                break;
            case Path.status:
                state.documents.status.status = undefined
                state.documents.weight.weight = undefined
                state.documents.allergies.allergy = undefined
                break;
            case Path.therapy:
                state.documents.therapy.therapy = undefined
                break;
            default:
                break
        }
    }


    // Reset state Logout
    if (action.type === AuthenticationActions.accountSignOutSuccessAction) {
        state = undefined;
    }

    // Reset patient detail
    if (action.type === 'GET_PATIENT_LIST_SUCCESS') {
        state.documents = undefined
        state.patients.patient = undefined
    }

    return appReducer(history)(state, action)
}
