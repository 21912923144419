import { put, call, takeEvery } from 'redux-saga/effects';
import Actions from '../actions/Detail.actions';
import MessagesActions from '../../messages/actions/Messages.actions';

function* getDetailSaga(action) {

    const { params, request, node, callback = () => { } } = action.payload;

    // Set Loading State
    yield put({
        type: `GET_${node}_DETAIL`,
        action: action
    })

    try {
        let detail = yield call(request, params);

        // Save Values
        yield put({
            type: `GET_${node}_DETAIL_SUCCESS`,
            action: Actions.getDetailSuccess(detail.data.data)
        })

        callback({ success: true })

    } catch (e) {
        // Save Error
        yield put({
            type: `GET_${node}_DETAIL_ERROR`,
            action: Actions.getDetailError(e)
        })
        yield put(MessagesActions.setError(e))
        callback({ success: false })
    }
}



export function* detailWatcherSaga() {
    yield takeEvery(Actions.getDetail, getDetailSaga);
}