import { ListReducer, InitialState as InitialStateList } from '../../../core/list/reducer/List.reducer'
import { DetailReducer, InitialState as InitialStateDetail } from '../../../core/detail/reducer/Detail.reducer'
import { AddReducer } from '../../../core/add/reducer/Add.reducer'

export const riskFactorsReducer = (state = InitialStateList, action) => {
    switch (action.type) {
        case 'GET_RISK_FACTOR_LIST':
        case 'GET_RISK_FACTOR_LIST_SUCCESS':
        case 'GET_RISK_FACTOR_LIST_ERROR':
            return ListReducer(state, action.action)
        default:
            return state
    }
}


export const riskFactorReducer = (state = InitialStateDetail, action) => {
    switch (action.type) {
        
        case 'GET_RISK_FACTOR_DETAIL':
        case 'GET_RISK_FACTOR_DETAIL_SUCCESS':
        case 'GET_RISK_FACTOR_DETAIL_ERROR':
            return DetailReducer(state, action.action)

        case 'ADD_RISK_FACTOR':
        case 'ADD_RISK_FACTOR_SUCCESS':
        case 'ADD_RISK_FACTOR_ERROR':
            return AddReducer(state, action.action)

        default:
            return state
    }
}
