import Background from "../../assets/img/login/background@3x.png"

export const styles = theme => ({
    root: {
        height: '100vh',
        width: '100%',
        display: 'flex',
        alignItems: "center",
        backgroundImage: `url(${Background})`,
        backgroundSize: 'cover'        
    },
    login: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(2)
    },
    logo: {
        width: '121px',
        height: '102px',
        objectFit: 'contain',
        backgroundColor: 'transparent'
    },
    image: {
        backgroundRepeat: "no-repeat",
        width: '121px',
        height: '102px',
        objectFit: 'contain',
    },
});