import React, { useState } from 'react'
import { withStyles } from "@material-ui/core/styles";
import { styles } from "./TherapyContent.page.style"

import _first from 'lodash/first'
import Box from '@material-ui/core/Box'
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';

import DOACTherapyContent from './DOACTherapyContent.page'
import WarfarinTherapyContent from './WarfarinTherapyContent.page'
import AntiplateletTherapyContent from './AntiplateletTherapyContent.page'
import OtherTherapyContent from './OtherTherapyContent.page'
import TherapyDrugsList from './TherapyDrugsList.page'
import { Panels } from '../../conf/therapy/Therapy.conf'
import Strings from '../../res/Strings_ITA.res';
import TherapyListContainer from '../../core/therapy/containers/TherapyList.container';
import ProgressComponent from '../../library/materialUi/progress/Progress.component';
import FilterComponent from '../../components/filter/Filter.component';
import TherapyDetailContainer from '../../core/therapy/containers/TherapyDetail.container';

const renderPanel = (index, edit, drug, onCreateDocument) => {

    switch (index) {
        case 0:
            return <DOACTherapyContent document={drug} edit={edit} onCreateDocument={onCreateDocument} />

        case 1:
            return <WarfarinTherapyContent document={drug} edit={edit} onCreateDocument={onCreateDocument} />

        case 2:
            return <AntiplateletTherapyContent document={drug} edit={edit} onCreateDocument={onCreateDocument} />

        case 3:
            return <OtherTherapyContent document={drug} edit={edit} onCreateDocument={onCreateDocument} />

        default:
            break;
    }
}


const TherapyContentPage = ({
    classes,
    edit = false,
}) => {

    const [expanded, setExpanded] = useState(null);
    const [documentId, setDocumentId] = useState(null)
    const [document, setDocument] = useState({
        loading: false,
        error: null,
        data: {
            nao: [],
            warfarin: [],
            anti_platelet: [],
            other_drugs: []
        }
    })
    const [drug, setDrug] = useState(null)

    const onChangePanel = (panel) => (event, isExpanded) => {
        if (edit) {
            setExpanded(isExpanded ? panel : false)
        }
    };

    const onSelectDrug = (drug, panelId) => {
        setExpanded(panelId)
        setDrug(drug)
    }

    const onCreateDocument = (key, object) => {
        setDocument({
            ...document,
            data: {
                ...document.data,
                [key]: [...document.data[key], object.data]
            }
        })
    }

    return (

        <>
            {/* LIST */}
            <TherapyListContainer>
                {({ list }) => (
                    <>
                        {list.loading ? <ProgressComponent /> : null}
                        <FilterComponent
                            loading={list.loading}
                            documents={list.data ? list.data : []}
                            documentDefault={list.data && edit === false ? _first(list.data) : null}
                            onSelectDocument={(id) => setDocumentId(id)}
                            edit={edit} />
                    </>
                )}
            </TherapyListContainer>

            {/* DETAIL */}
            {documentId ?
                <TherapyDetailContainer
                    documentId={documentId}>
                    {({ detail }) => {
                        setDocument(detail)
                        return (
                            <Box mt={2}>
                                {detail.loading ? <ProgressComponent /> : null}
                            </Box>
                        )
                    }}
                </TherapyDetailContainer> : <Box mt={2} />}


            <Box display="flex" width='100%'>
                <Box width={2 / 3}>
                    {Panels.map((panel, index) => (
                        <ExpansionPanel key={index} expanded={expanded === panel.id} onChange={onChangePanel(panel.id)}>
                            <ExpansionPanelSummary className={classes.summary}>
                                <Typography className={classes.title}>{panel.title}</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails className={classes.details}>
                                {expanded === index ? renderPanel(index, edit, drug, onCreateDocument) : <div />}
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    ))}
                </Box>
                <Box width={1 / 3}>
                    <Box boxShadow={1} className={classes.list}>
                        <Typography className={classes.listTitle}>{Strings.therapy.list}</Typography>
                        <Box mt={3}>
                            <TherapyDrugsList
                                edit={edit}
                                document={document}
                                onDetailDrug={(drug, panelId) => onSelectDrug(drug, panelId)} />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default withStyles(styles)(TherapyContentPage)