import { createContext, useContext, useState } from 'react';

export const AuthContext = createContext();

export const useAuthContext = () => {
    return useContext(AuthContext)
}

export const useAuth = () => {

    const existingToken = JSON.parse(sessionStorage.getItem("token"));
    const [authToken, setAuthToken] = useState(existingToken);

    const setToken = (data) => {
        sessionStorage.setItem("token", JSON.stringify(data));
        setAuthToken(data);
    }

    const removeToken = () => {
        sessionStorage.removeItem('token')
        setAuthToken(null);
    }

    return { authToken, setToken, removeToken }
}