import { ListReducer, InitialState as InitialStateList } from '../../../core/list/reducer/List.reducer'
import { DetailReducer, InitialState as InitialStateDetail } from '../../../core/detail/reducer/Detail.reducer'
import { AddReducer } from '../../../core/add/reducer/Add.reducer'

export const treatmentsReducer = (state = InitialStateList, action) => {
    switch (action.type) {
        case 'GET_TREATMENTS_LIST':
        case 'GET_TREATMENTS_LIST_SUCCESS':
        case 'GET_TREATMENTS_LIST_ERROR':
            return ListReducer(state, action.action)
        default:
            return state
    }
}

export const treatmentReducer = (state = InitialStateDetail, action) => {
    switch (action.type) {
        case 'GET_TREATMENTS_DETAIL':
        case 'GET_TREATMENTS_DETAIL_SUCCESS':
        case 'GET_TREATMENTS_DETAIL_ERROR':
            return DetailReducer(state, action.action)

        case 'ADD_TREATMENTS':
        case 'ADD_TREATMENTS_SUCCESS':
        case 'ADD_TREATMENTS_ERROR':
            return AddReducer(state, action.action)

        default:
            return state
    }
}