import React from "react"
import { withRouter } from "react-router"
import { useAuthContext } from '../../../contexts/auth/Auth';
import AddContainer from '../../add/container/Add.container'
import Request from '../api/RiskFactor.api'
import { selectRiskFactor } from "../selector/RiskFactor.selector";


const RiskFactorAddContainer = ({
    match,
    children
}) => {

    const patientId = match.params.patient
    const { authToken } = useAuthContext()

    return (
        <AddContainer
            request={Request.riskFactorAdd}
            params={{
                access_token: authToken.access_token,
                patientId: patientId
            }}
            node='RISK_FACTOR'
            selector={selectRiskFactor}>
            {children}
        </AddContainer>
    )
}

export default withRouter(RiskFactorAddContainer)