import moment from 'moment'
import Manager from '../../../api/Manager.api'
import Endpoints from '../../../api/Endpoints.api'


const TreatmentsUpsertRequest = ({ values, id }) => {
    var body = new FormData();

    // ID
    if (id) {
        body.set('id', id);
    }

    if (values.type) {
        values.type.map(item =>
            body.set('type', item.value)
        )
    }

    if (values.date) {
        body.set('date', moment(values.date).format('YYYY-MM-DD HH:mm:ss'));
    }

    return body
}


const treatmentsList = ({
    access_token,
    patientId,
}) => {
    return Manager.client(access_token).get(Endpoints.treatments(patientId))
}

const treatmentsDetail = ({
    access_token,
    patientId,
    id
}) => {
    return Manager.client(access_token).get(`${Endpoints.treatmentsDetail(patientId)}${id}/`)    
}

const treatmentsAdd = ({
    access_token,
    patientId,
    values
}) => {
    return Manager.client(access_token).post(Endpoints.treatments(patientId), TreatmentsUpsertRequest({ values }))
}


const Request = {
    treatmentsList,
    treatmentsDetail,
    treatmentsAdd,
}


export default Request