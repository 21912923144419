import { appColor } from '../../library/config/colors/colors'

export const styles = theme => ({
    root: {
        width: "100%",
    },
    backButton: {
        color: appColor.primary.main,
        width: theme.spacing(22),
		height: theme.spacing(5),
        fontSize: theme.spacing(2),
        fontWeight: 'bold',
		fontStretch: 'normal',
		fontStyle: 'normal',
    },
    nextButton: {
        color: appColor.white,
        width: theme.spacing(22),
		height: theme.spacing(5),
        fontSize: theme.spacing(2),
        fontWeight: 'bold',
		fontStretch: 'normal',
		fontStyle: 'normal',
    }
});