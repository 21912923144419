import React from "react"
import { withRouter } from "react-router"
import { useAuthContext } from '../../../contexts/auth/Auth';
import ListContainer from '../../list/container/List.container'
import Request from '../api/BloodPressure.api'
import { selectBloodPressures } from "../selector/BloodPressure.selector";

const BloodPressureListContainer = ({
    match,
    children,
    callback,
    range
}) => {

    const patientId = match.params.patient
    const { authToken } = useAuthContext()

    return (
        <ListContainer
            request={Request.bloodPressureList}
            params={{
                access_token: authToken.access_token,
                patientId: patientId,
                range: range
            }}
            callback={callback}
            node='BLOOD_PRESSURE'
            selector={selectBloodPressures}
            refresh={[range]}>
            {children}
        </ListContainer>
    )
}

export default withRouter(BloodPressureListContainer)