import { useEffect } from "react"
import { connect } from "react-redux"
import ListActions from "../actions/List.actions";

const ListContainer = ({
    children,
    params,
    request,
    getList,
    list,
    node,
    refresh = [],
    callback = () => { },
}) => {


    useEffect(() => {
        getList({
            params: params,
            request: request,
            node: node,
            callback: callback
        })
    }, refresh)

    return children? children({ list: list }) : null            
}

const mapStateToProps = (state, ownProps) => ({
    list: ownProps.selector(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    getList: (params) => {
        dispatch(ListActions.getList(params))
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(ListContainer)