import Logo from "../../assets/logo.png"

export const DrawerWidth = 250

export const styles = theme => ({
    drawerPaper: {
        position: "relative",
        whiteSpace: "nowrap",
        width: DrawerWidth,
        background: theme.palette.primary.main,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    drawerPaperClose: {
        overflowX: "hidden",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(11)
        }
    },
    toolbarIcon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0 8px",
        ...theme.mixins.toolbar
    },
    toolbarLogo: {
        backgroundImage: `url(${Logo})`,
        backgroundSize: "contain",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        width: "100%",
        height: "100%"
    },
    icon: {
        color: theme.palette.secondary.main
    }
})
