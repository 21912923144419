import React from "react"
import { withRouter } from "react-router"
import { useAuthContext } from '../../../contexts/auth/Auth';
import AddContainer from '../../add/container/Add.container'
import Request from '../api/Pathology.api'


const PathologiesAddContainer = ({
    match,
    children
}) => {

    const patientId = match.params.patient
    const { authToken } = useAuthContext()

    return (
        <AddContainer
            request={Request.pathologyAdd}
            params={{
                access_token: authToken.access_token,
                patientId: patientId
            }}
            callback={(response) => console.log(response)}>
            {children}
        </AddContainer>
    )
}

export default withRouter(PathologiesAddContainer)