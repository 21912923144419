import React from "react"
import { withStyles } from "@material-ui/core/styles"
import FormControl from "@material-ui/core/FormControl"
import OutlinedInput from "@material-ui/core/OutlinedInput"
import InputLabel from "@material-ui/core/InputLabel"

import { styles } from "../../../styles/form/editText/EditText.component.style"

const EditTextTemplate = ({
    classes,
    label,
    required = false,
    disabled = false,
    type = "text",
    inputRef,
    input,
    meta,
    ...rest
}) => {
    const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched

    return (
        <FormControl
            className={classes.formControl}
            error={showError}
            variant='outlined'>
            <InputLabel required={required} >{label}</InputLabel>
            <OutlinedInput
                label={label}
                fullWidth
                disabled={disabled}
                inputRef={inputRef}
                error={showError}
                // labelWidth={170}
                {...input}
                {...rest}
            />
        </FormControl>
    )
}

export const EditTextComponent = withStyles(styles)(EditTextTemplate)
