import React from "react"
import { useAuthContext } from '../../../contexts/auth/Auth';
import { withRouter } from "react-router"

import AddContainer from '../../add/container/Add.container'
import { selectWeight } from "../selector/Weight.selector";
import Request from '../api/Weight.api'

const WeightAddContainer = ({
    match,
    children
}) => {

    const patientId = match.params.patient
    const { authToken } = useAuthContext()

    return (
        <AddContainer
            request={Request.weightAdd}
            params={{
                access_token: authToken.access_token,
                patientId: patientId
            }}
            node='WEIGHT'
            selector={selectWeight}>
            {children}                    
        </AddContainer>
    )
}

export default withRouter(WeightAddContainer)