import Manager from '../../../api/Manager.api'
import Endpoints from '../../../api/Endpoints.api'


const StatusUpsertRequest = ({ values, id }) => {
    var body = new FormData();

    // ID
    if (id) {
        body.set('id', id);
    }

    if (values.oxigen_saturation) {
        body.set('oxigen_saturation', values.oxigen_saturation);
    }

    if (values.ejection_fraction) {
        body.set('ejection_fraction', values.ejection_fraction);
    }

    body.set('pacemaker', values.pacemaker);
    body.set('defibrillator', values.defibrillator);
    body.set('loop_recorder', values.loop_recorder);
    body.set('previous_stemi', values.previous_stemi);
    body.set('previous_nstemiAC', values.previous_nstemiAC);
    body.set('previous_pci', values.previous_pci);
    
    return body
}


const statusList = ({
    access_token,
    patientId,
}) => {
    return Manager.client(access_token).get(Endpoints.status(patientId))
}

const statusDetail = ({
    access_token,
    patientId,
    id
}) => {
    return Manager.client(access_token).get(`${Endpoints.status(patientId)}${id}/`)
}

const statusUpdate = ({
    access_token,
    patientId,
    values,
    id
}) => {
    return Manager.client(access_token).update(Endpoints.status(patientId), StatusUpsertRequest({ id, values }))
}


const statusAdd = ({
    access_token,
    patientId,
    values,
}) => {
    return Manager.client(access_token).post(Endpoints.status(patientId), StatusUpsertRequest({ values }))
}



const Request = {
    statusList,
    statusDetail,
    statusAdd,
    statusUpdate,
}


export default Request