import React from "react"
import { Field } from "react-final-form"
import { SelectMultipleComponent } from "../../../../../materialUi/form/select/SelectMultiple.component"
import { Error } from "../../error"

export const SelectMultiple = ({
    item,
    errors,
    options = [],
    disabled,    
}) => {

    return (
        <>
            <Field
                name={item.name}
                label={item.label}
                required={item.required}
                disabled={disabled}
                error={errors ? errors[item.name] : null}
                component={SelectMultipleComponent}
                values={options}
            />
            <Error name={item.name} />
        </>
    )
}
