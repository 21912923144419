import React, { useState } from "react"
import _first from 'lodash/first'
import Box from '@material-ui/core/Box';
import Form from '../../components/form/Form.component';
import ProgressComponent from '../../library/materialUi/progress/Progress.component'
import FilterComponent from '../../components/filter/Filter.component'

import { AFlutterConf } from '../../conf/pathologies/Pathologies.conf'
import AtrialFlutterAddContainer from '../../core/pathology/containers/AtrialFlutterAdd.container'
import AtrialFlutterListContainer from '../../core/pathology/containers/AtrialFlutterList.container'
import AtrialFlutterDetailContainer from '../../core/pathology/containers/AtrialFlutterDetail.container'

const AtrialFlutterContentPage = ({
    edit = false,    
}) => {

    const { body, footer, columns } = AFlutterConf
    const [documentId, setDocumentId] = useState(null)
    const [document, setDocument] = useState({})

    return (
        <Box width={1}>
            {/* LIST */}
            <AtrialFlutterListContainer>
                {({ list }) => (
                    <>
                        {list.loading ? <ProgressComponent /> : null}
                        <FilterComponent
                            loading={list.loading}
                            documents={list.data ? list.data : []}
                            documentDefault={list.data && edit === false ? _first(list.data) : null}
                            onSelectDocument={(id) => setDocumentId(id)}
                            edit={edit} />
                    </>
                )}
            </AtrialFlutterListContainer>

            {/* DETAIL */}
            <AtrialFlutterDetailContainer
                documentId={documentId}>
                {({ detail }) => {
                    setDocument(detail)
                    return (
                        <Box mt={2}>
                            {detail.loading ? <ProgressComponent /> : null}
                        </Box>
                    )
                }}
            </AtrialFlutterDetailContainer>  

            {/* ADD */}
            <AtrialFlutterAddContainer>
                {({ AtrialFlutterType, onSubmit }) => {

                    body.type.options = AtrialFlutterType.map(item => ({
                        value: item.id,
                        label: item.name
                    }))

                    return (
                        <Form
                            body={body}
                            columns={columns}
                            footer={edit ? footer : null}
                            onSubmit={onSubmit}
                            display='block'
                            style={{ padding: '12px' }}
                            showTitle={true}
                            initialValues={document.data ? document.data : null}
                            disabled={!edit}
                        />
                    )
                    }}
            </AtrialFlutterAddContainer>
        </Box>
    )
}

export default AtrialFlutterContentPage