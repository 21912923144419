import String from "../../res/Strings_ITA.res"

export const PatientsConf = {
    toolbar: {
        title: ''
    },
    head: {
        cells: [
            { label: String.home.fullname, component: 'CellText' },
            { label: String.home.birthDate, component: 'CellText' },
            { label: String.home.fiscal_code, component: 'CellText' },
            { label: '', component: 'CellText' },
        ]
    },
    body: {
        cells: [            
            { key: 'first_name', otherKey: 'last_name', component: 'CellText' },
            { key: 'date_of_birth', component: 'CellText' },
            { key: 'fiscal_code', component: 'CellText' },
            { key: '', component: 'CellActions', edit: false, detail: true },
        ]
    },
    pagination: {
        rowsPerPageOptions: [5, 10, 15, 25],
        rowsPerPage: 10,
        page: 0
    }
};