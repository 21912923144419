import { createAction } from 'redux-actions';

export const ADD_ITEM = 'ADD_ITEM';
export const ADD_ITEM_SUCCESS = 'ADD_ITEM_SUCCESS';
export const ADD_ITEM_ERROR = 'ADD_ITEM_ERROR';

export const addItem = createAction(ADD_ITEM)
export const addItemSuccess = createAction(ADD_ITEM_SUCCESS)
export const addItemError = createAction(ADD_ITEM_ERROR)


export default {
    ADD_ITEM,
    ADD_ITEM_SUCCESS,
    ADD_ITEM_ERROR,        
    
    addItem,
    addItemSuccess,
    addItemError,        
}