import React from 'react'
import { connect } from "react-redux"
import { withRouter } from "react-router"
import { selectPatient } from "../../patients/selector/Patients.selector";
import BreadcrumbsComponent from '../../../components/breadcrumbs/Breadcrumbs.component'
import { Path, Paths } from '../../../conf/paths/Paths.conf'
import Strings from '../../../res/Strings_ITA.res'

function getPaths(path, patient) {

    switch (path) {
        case Paths.home:
            return [
                {
                    title: Strings.home.title
                }
            ]
        case Paths.detail:
            return [
                {
                    title: Strings.home.title,
                    path: Path.home,
                },
                {
                    title: `${patient.first_name} ${patient.last_name}`,
                    path: `/detail/${patient.patientId}/${Path.detail}`
                }
            ]
        case Paths.overview:
            return [
                {
                    title: Strings.home.title,
                    path: Path.home
                },
                {
                    title: `${patient.first_name} ${patient.last_name}`,
                    path: `/detail/${patient.patientId}/${Path.detail}`
                },
                {
                    title: Strings.overview.title,
                    path: `/detail/${patient.patientId}/${Path.overview}`
                },
            ]
        case Paths.personal:
            return [
                {
                    title: Strings.home.title,
                    path: Path.home
                },
                {
                    title: `${patient.first_name} ${patient.last_name}`,
                    path: `/detail/${patient.patientId}/${Path.detail}`
                },
                {
                    title: Strings.personalData.title,
                    path: `/detail/${patient.patientId}/${Path.personal}`
                },
            ]
        case Paths.risk:
            return [
                {
                    title: Strings.home.title,
                    path: Path.home
                },
                {
                    title: `${patient.first_name} ${patient.last_name}`,
                    path: `/detail/${patient.patientId}/${Path.detail}`
                },
                {
                    title: Strings.riskFactors.title,
                    path: `/detail/${patient.patientId}/${Path.risk}`
                },
            ]
        case Paths.visit:
            return [
                {
                    title: Strings.home.title,
                    path: Path.home
                },
                {
                    title: `${patient.first_name} ${patient.last_name}`,
                    path: `/detail/${patient.patientId}/${Path.detail}`
                },
                {
                    title: Strings.visit.title,
                    path: `/detail/${patient.patientId}/${Path.visit}`
                },
            ]
        case Paths.history:
            return [
                {
                    title: Strings.home.title,
                    path: Path.home
                },
                {
                    title: `${patient.first_name} ${patient.last_name}`,
                    path: `/detail/${patient.patientId}/${Path.detail}`
                },
                {
                    title: Strings.history.title,
                    path: `/detail/${patient.patientId}/${Path.history}`
                },
            ]
        case Paths.status:
            return [
                {
                    title: Strings.home.title,
                    path: Path.home
                },
                {
                    title: `${patient.first_name} ${patient.last_name}`,
                    path: `/detail/${patient.patientId}/${Path.detail}`
                },
                {
                    title: Strings.statusData.title,
                    path: `/detail/${patient.patientId}/${Path.status}`
                },
            ]
            case Paths.therapy:
                return [
                    {
                        title: Strings.home.title,
                        path: Path.home
                    },
                    {
                        title: `${patient.first_name} ${patient.last_name}`,
                        path: `/detail/${patient.patientId}/${Path.detail}`
                    },
                    {
                        title: Strings.therapy.title,
                        path: `/detail/${patient.patientId}/${Path.therapy}`
                    },
                ]
        default:
            break
    }
}



const BreadcrumbsContainer = ({ match,
    patient,
}) => {

    return (
        <BreadcrumbsComponent paths={getPaths(match.path, patient.data ? patient.data : { first_name: '', last_name: '', patientId: '' })} />
    )
}

const mapStateToProps = state => ({
    patient: selectPatient(state)
});

export default withRouter(connect(mapStateToProps, null)(BreadcrumbsContainer))