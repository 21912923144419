import React from 'react'

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';


const Message = ({
    onCloseAlert,
    severity,
    message
}) => {

    return (        
        <Snackbar
            open={true}
            autoHideDuration={2000}
            onClose={onCloseAlert}
            anchorOrigin={{
                horizontal: 'center',
                vertical: 'top'
            }}>            
            <MuiAlert elevation={6} variant="filled" onClose={onCloseAlert} severity={severity}>
                {message}
            </MuiAlert>
        </Snackbar>
    )
}

export default Message