export const styles = theme => ({

    title:{
        "& div": {
            color: theme.palette.primary.main,
        }
    },
    listMain: {
        width:"100%"
    },
    listHead:{
        "& thead": {
            width: "100%",            
            "& th":{
                color:theme.palette.primary.main,
                border: "transparent",
            }
        }


    }
  
});