import React from "react"
import { withStyles } from "@material-ui/core/styles"
import TableRow from "@material-ui/core/TableRow"

import { styles } from "../../../styles/table/row/Row.component.style"

const RowTemplate = ({
    classes,
    type = 'body',
    children,
    onClick= () => {}
}) => {
    return (
        <TableRow className={classes[type]} onClick={onClick}>
            {children}
        </TableRow>
    )
}

export const RowComponent = withStyles(styles)(RowTemplate)
