import React from "react"
import { withStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"

import { styles } from "../../styles/grid/Grid.component.style"

const GridTemplate = ({ classes, children, container, item, xs, spacing }) => {
    return (
        <Grid
            className={classes.root}
            container={container}
            item={item}
            xs={xs}
            spacing={spacing}
        >
            {children}
        </Grid>
    )
}

export const GridComponent = withStyles(styles)(GridTemplate)
