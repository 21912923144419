import React from "react"
import _first from 'lodash/first'
import Box from '@material-ui/core/Box';
import Form from '../../components/form/Form.component';

import { DOACTherapyConf } from '../../conf/therapy/Therapy.conf'
import DOACTherapyAddContainer from '../../core/therapy/containers/DOACTherapyAdd.container'
import DividerComponent from "../../library/materialUi/divider/Divider.component";

const DOACTherapyContentPage = ({
    edit = false,
    document = {},
    onCreateDocument = () => { }
}) => {

    const { body, footer, columns } = DOACTherapyConf

    return (
        <Box width={1}>

            <DividerComponent />

            {/* ADD */}
            <DOACTherapyAddContainer
                onCreateDocument={onCreateDocument}>
                {({ NAODrugs, onSubmit }) => {

                    body.drug.options = NAODrugs.map(item => ({
                        value: item.id,
                        label: item.name
                    }))

                    return (
                        <Form
                            body={body}
                            columns={columns}
                            footer={edit ? footer : null}
                            onSubmit={onSubmit}
                            display='block'
                            style={{ padding: '12px' }}
                            showTitle={true}
                            initialValues={document ? document : null}
                            disabled={!edit}
                        />
                    )
                }}
            </DOACTherapyAddContainer>
        </Box>
    )
}

export default DOACTherapyContentPage