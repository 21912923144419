export const styles = theme => ({    
    paper: {
        cursor: 'pointer',
    },
    title: {
        width: '100%',
        height: '100%',
        fontSize: '18px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        textAlign: 'center',
        color: '#728388',
    },
    card: {
        maxWidth: 345,
        background: 'transparent'
    },
    media: {
        height: 0,
        paddingTop: '56.25%',
    },
});