import { ListReducer, InitialState as InitialStateList } from '../../../core/list/reducer/List.reducer'
import { DetailReducer, InitialState as InitialStateDetail } from '../../../core/detail/reducer/Detail.reducer'
import { AddReducer } from '../../../core/add/reducer/Add.reducer'

export const therapiesReducer = (state = InitialStateList, action) => {
    switch (action.type) {
        case 'GET_THERAPIES_LIST':
        case 'GET_THERAPIES_LIST_SUCCESS':
        case 'GET_THERAPIES_LIST_ERROR':
            return ListReducer(state, action.action)
        default:
            return state
    }
}

export const therapyReducer = (state = InitialStateDetail, action) => {
    switch (action.type) {
        case 'GET_THERAPY_DETAIL':
        case 'GET_THERAPY_DETAIL_SUCCESS':
        case 'GET_THERAPY_DETAIL_ERROR':
            return DetailReducer(state, action.action)

        case 'ADD_THERAPY':
        case 'ADD_THERAPY_SUCCESS':
        case 'ADD_THERAPY_ERROR':
            return AddReducer(state, action.action)

        default:
            return state
    }
}