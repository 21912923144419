import { createAction } from 'redux-actions';

export const GET_DETAIL = 'GET_DETAIL';
export const GET_DETAIL_SUCCESS = 'GET_DETAIL_SUCCESS';
export const GET_DETAIL_ERROR = 'GET_DETAIL_ERROR';

export const getDetail = createAction(GET_DETAIL)
export const getDetailSuccess = createAction(GET_DETAIL_SUCCESS)
export const getDetailError = createAction(GET_DETAIL_ERROR)


export default {
    GET_DETAIL,
    GET_DETAIL_SUCCESS,
    GET_DETAIL_ERROR,        
    
    getDetail,
    getDetailSuccess,
    getDetailError,        
}