import { appColor } from "../../../config/colors/colors"
export const styles = theme => ({
    body:{        
        fontSize: '16px',
    },
    header:{
        color: appColor.grey,
        fontSize: '20px',
        fontWeight: 'bold',
        textTransform: 'uppercase'
    }
});