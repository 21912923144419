import Manager from '../../../api/Manager.api'
import Endpoints from '../../../api/Endpoints.api'

const AtrialFibrillationUpsertRequest = ({ values, id }) => {
    var body = new FormData();

    // ID
    if (id) {
        body.set('id', id);
    }
    
    if (values.atrial_fibrillation) {
        body.set('atrial_fibrillation', values.atrial_fibrillation);
    }

    body.set('mechanical_valve', values.mechanical_valve);

    return body
}

const AtrialFibrillationList = ({
    access_token,
    patientId,
}) => {
    return Manager.client(access_token).get(Endpoints.pathology(patientId))
}

const AtrialFibrillationDetail = ({
    access_token,
    patientId,
    id
}) => {
    return Manager.client(access_token).get(`${Endpoints.pathology(patientId)}${id}/`)
}

const AtrialFibrillationUpdate = ({
    access_token,
    patientId,
    values,
    id
}) => {
    return Manager.client(access_token).update(Endpoints.pathology(patientId), AtrialFibrillationUpsertRequest({ id, values }))
}

const AtrialFibrillationAdd = ({
    access_token,
    patientId,
    values,
}) => {
    return Manager.client(access_token).post(Endpoints.AFibrillationPathology(patientId), AtrialFibrillationUpsertRequest({ values }))
}

const Request = {
    AtrialFibrillationList,
    AtrialFibrillationDetail,
    AtrialFibrillationUpdate,
    AtrialFibrillationAdd
}

export default Request
