import React from 'react'
import { withStyles } from "@material-ui/core/styles";
import { styles } from "./NewVisitFooter.component.style";
import Button from '@material-ui/core/Button';
import Strings from "../../res/Strings_ITA.res"
import Box from '@material-ui/core/Box'

const NewVisitFooterComponent = ({
    classes,
    onNextAction,
    onBackAction,
    onEndAction,
    stepperCurrent,
    stepperCount
}) => {

    return (
        <Box display="flex" width='100%'>
            <Box p={2}>
                <Button
                    variant="outlined"
                    color="primary"
                    disabled={stepperCurrent === 0}
                    onClick={onBackAction}
                    className={classes.backButton}>
                    {Strings.visit.back}
                </Button>
            </Box>
            <Box flexGrow={1} />
            <Box p={2}>
                {(stepperCurrent < stepperCount - 1) ?
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onNextAction}
                        disabled={stepperCurrent >= stepperCount - 1}
                        className={classes.nextButton}>
                        {Strings.visit.saveAndGoOn}
                    </Button> :
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onEndAction}
                        disabled={stepperCurrent !== stepperCount - 1}
                        className={classes.nextButton}>
                        {Strings.visit.end}
                    </Button>
                }
            </Box>
        </Box>
    )
}

export default withStyles(styles)(NewVisitFooterComponent)