import { appColor } from '../../library/config/colors/colors'

export const styles = theme => ({
    root: {
        backgroundColor: appColor.white
    },
    title: {
        fontSize: '16px',
        fontWeight: 'bold',
        textAlign: 'left',
        color: appColor.darkGrey,
        borderColor: 'transparent',
        height: theme.spacing(4),
        cursor: 'pointer'
    },
    icon: {
        color: appColor.darkGrey,
        cursor: 'pointer'
    }
});