import React from "react"
import { withRouter } from "react-router"
import _isEmpty from 'lodash/isEmpty'
import { connect } from "react-redux"
import { useAuthContext } from '../../../contexts/auth/Auth';
import AddContainer from '../../add/container/Add.container'
import Request from '../api/DOACTherapy.api'
import { selects } from '../../selects/selector/Selects.selector'
import { selectDrugs } from "../selector/Therapy.selector";

const DOACTherapyAddContainer = ({
    match,
    children,
    selects,
    onCreateDocument
}) => {

    const patientId = match.params.patient
    const { authToken } = useAuthContext()

    return (
        _isEmpty(selects.data) ? null :
            <AddContainer
                request={Request.DOACTherapyAdd}
                params={{
                    access_token: authToken.access_token,
                    patientId: patientId
                }}
                node='DRUG'
                selector={selectDrugs}
                callback={(response) => {
                    if(response.success){
                        onCreateDocument('nao',response.item)
                    }
                }}>
                {({ item, onSubmit }) => (
                    children({ item, onSubmit, NAODrugs: selects.data.NAODrugs })
                )}
            </AddContainer>
    )
}

const mapStateToProps = (state, ownProps) => ({
    selects: selects(state)
});

export default withRouter(connect(mapStateToProps, null)(DOACTherapyAddContainer))