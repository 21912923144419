import String from "../../res/Strings_ITA.res"

export const StatusConf = {
    columns: [

        {
            size: 1/2,
            title: String.statusData.dia,
            border: { top: 1 , right: 1, bottom: 0, left: 0 }
        },
        {
            size: 1/2,
            title: String.statusData.previousEvents,
            border: { top: 1, right: 0, bottom: 0, left: 0 }
        },
        {
            size: 1,
            title: String.statusData.ejectionFractionTitle,
            border: { top: 1, right: 0, bottom: 0, left: 0 }
        },
        {
            size: 1,
            title: String.statusData.oxigenSaturationTitle,
            border: { top: 1, right: 0, bottom: 0, left: 0 }
        },                                              
    ],
    body: {
        pacemaker: {
            component: 'Checkbox',
            name: 'pacemaker',
            label: String.statusData.PM,
            required: false,
            disabled: false,
            xs: 12,
            column: 0,
            validation: {
                message: String.general.alert
            },
        },
        defibrillator: {
            component: 'Checkbox',
            name: 'defibrillator',
            label: String.statusData.ICD,
            required: false,
            disabled: false,
            xs: 12,
            column: 0,
            validation: {
                message: String.general.alert
            },
        },
        loop_recorder: {
            component: 'Checkbox',
            name: 'loop_recorder',
            label: String.statusData.ILR,
            required: false,
            disabled: false,
            xs: 12,
            column: 0,
            validation: {
                message: String.general.alert
            },
        },
        previous_stemi: {
            component: 'Checkbox',
            name: 'previous_stemi',
            label: String.statusData.stemi,
            required: false,
            disabled: false,
            xs: 12,
            column: 1,
            validation: {
                message: String.general.alert
            },
        },
        previous_nstemiAC: {
            component: 'Checkbox',
            name: 'previous_stemiAC',
            label: String.statusData.nstemiac,
            required: false,
            disabled: false,
            xs: 12,
            column: 1,
            validation: {
                message: String.general.alert
            },
        },
        previous_pci: {
            component: 'Checkbox',
            name: 'previous_pci',
            label: String.statusData.pci,
            required: false,
            disabled: false,
            xs: 12,
            column: 1,
            validation: {
                message: String.general.alert
            },
        },
        ejection_fraction: {
            component: 'Select',
            name: 'ejection_fraction',
            label: String.statusData.ejectionFractionTitle,
            required: true,
            disabled: false,
            xs: 12,
            column: 2,
            validation: {
                message: String.general.alert
            },
        },
        oxigen_saturation: {
            component: 'EditText',
            name: 'oxigen_saturation',
            label: String.statusData.oxigenSaturation,
            required: false,
            disabled: false,
            xs: 12,
            column: 3,
            validation: {
                message: String.general.alert
            },
        },
    },
    footer: {
        columns: [
            {
                component: 'ButtonSubmit',
                title: String.general.save,
                variant: 'contained',
                color: 'primary',
            },
        ]
    }
};

export const AllergiesStatusConf = {
    columns: [
        {
            size: 1,
            title: String.statusData.allergies,
            border: { top: 1 , right: 0, bottom: 0, left: 0 }
        }
    ],
    body: {
        drugs: {
            component: 'Checkbox',
            name: 'drugs',
            label: String.statusData.drugs,
            required: false,
            disabled: false,
            xs: 12,
            column: 0,
            validation: {
                message: String.general.alert
            },
        },
        food: {
            component: 'Checkbox',
            name: 'food',
            label: String.statusData.food,
            required: false,
            disabled: false,
            xs: 12,
            column: 0,
            validation: {
                message: String.general.alert
            },
        },
        other: {
            component: 'EditArea',
            name: 'other',
            label: String.statusData.describe,
            required: false,
            disabled: false,
            rows: 1,
            xs: 12,
            column: 0,
            validation: {
                message: String.general.alert
            },
        }
    },
    footer: {
        columns: [
            {
                component: 'ButtonSubmit',
                title: String.general.save,
                variant: 'contained',
                color: 'primary',
            },
        ]
    }
};

export const BMIStatusConf = {
    columns: [
        {
            size: 1,
            title: String.statusData.bmi,
            border: { top: 1, right: 0, bottom: 0, left: 0 }
        }
    ],
    body: {
        height: {
            component: 'EditText',
            name: 'height',
            label: String.statusData.height,
            required: true,
            disabled: false,
            xs: 12,
            column: 0,
            validation: {
                message: String.general.alert
            },
        },
        weight: {
            component: 'EditText',
            name: 'weight',
            label: String.statusData.weight,
            required: true,
            disabled: false,
            xs: 12,
            column: 0,
            validation: {
                message: String.general.alert
            },
        },
        bmi: {
            component: 'EditText',
            name: 'bmi',
            label: String.statusData.bmi,
            required: false,
            disabled: true,
            xs: 12,
            column: 0,
            validation: {
                message: String.general.alert
            },
        }
    },
    footer: {
        columns: [
            {
                component: 'ButtonSubmit',
                title: String.general.save,
                variant: 'contained',
                color: 'primary',
            },
        ]
    }
};

export const TreatmentStatusConf = {
    columns: [
        {
            size: 1,
            title: String.statusData.surgery,
            border: { top: 1 , right: 0, bottom: 0, left: 0 }
        }
    ],
    body: {
        date: {
            component: 'CalendarPicker',
            name: 'date',
            label: String.statusData.surgery_date,
            calendarType: 'DateTimePicker',
            required: false,
            disabled: false,
            column: 0,
            xs: 6,
            validation: {
                message: String.general.alert
           },
        },        
        type: {
            component: 'SelectMultiple',
            name: 'type',
            label: String.statusData.treatment,
            node: 'name',
            required: false,
            disabled: false,            
            xs: 12,
            column: 0,
            validation: {
                message: String.general.alert
            },
        },        
    },
    footer: {
        columns: [
            {
                component: 'ButtonSubmit',
                title: String.general.save,
                variant: 'contained',
                color: 'primary',
            },
        ]
    }
};

export const TreatmentStatusList = {
    toolbar: {
        title: ''
    },
    head: {
        cells: [
            { label: 'Data', component: 'CellText' },
            { label: 'Intervento', component: 'CellText' },
            { label: '', component: 'CellText' },
        ]
    },
    body: {
        cells: [
            { key: 'created_at', component: 'CellCalendar' },
            { key: 'treatment', component: 'CellText' },
            { key: '', component: 'CellActions', edit: false, detail: false },
        ]
    },
    pagination: {
        rowsPerPageOptions: [5, 10, 15, 25],
        rowsPerPage: 5,
        page: 0
    }
};