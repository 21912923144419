import React from "react"
import { withRouter } from "react-router"
import { connect } from "react-redux"
import _isEmpty from 'lodash/isEmpty'
import { useAuthContext } from '../../../contexts/auth/Auth';

import DetailContainer from '../../detail/container/Detail.container'
import { selectStatus } from "../selector/Status.selector";
import Request from '../api/Status.api'

import { selects } from '../../selects/selector/Selects.selector'

const StatusDetailContainer = ({
    children,
    match,
    selects,
    documentId,
}) => {

    const patientId = match.params.patient
    const { authToken } = useAuthContext()

    return (
        _isEmpty(selects.data) ? null :
            <DetailContainer
                params={{
                    access_token: authToken.access_token,
                    patientId: patientId,
                    id: documentId
                }}
                request={Request.statusDetail}
                selector={selectStatus}
                node='STATUS'>                
                {({ detail }) => (
                    children({ detail, ejectionFractions: selects.data.ejectionFractions })
                )}
            </DetailContainer>
    )
}

const mapStateToProps = (state, ownProps) => ({    
    selects: selects(state)
});

export default withRouter(connect(mapStateToProps, null)(StatusDetailContainer))