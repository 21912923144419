import { createAction } from 'redux-actions';

export const GET_MULTIPLE_LIST = 'GET_MULTIPLE_LIST';
export const GET_MULTIPLE_LIST_SUCCESS = 'GET_MULTIPLE_LIST_SUCCESS';
export const GET_MULTIPLE_LIST_ERROR = 'GET_MULTIPLE_LIST_ERROR';

export const getMultipleList = createAction(GET_MULTIPLE_LIST)
export const getMultipleListSuccess = createAction(GET_MULTIPLE_LIST_SUCCESS)
export const getMultipleListError = createAction(GET_MULTIPLE_LIST_ERROR)


export default {
    GET_MULTIPLE_LIST,
    GET_MULTIPLE_LIST_SUCCESS,
    GET_MULTIPLE_LIST_ERROR,        
    
    getMultipleList,
    getMultipleListSuccess,
    getMultipleListError,        
}