import React from 'react'
import Box from '@material-ui/core/Box'
import { withStyles } from "@material-ui/core/styles";
import { styles } from "./TherapyListItem.component.style";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

const TherapyListItem = ({
    classes,
    drug,
    onDetailAction = () => { },
    onDeleteAction = () => { }
}) => {

    return (
        <Box display="flex" width='100%' className={classes.root}>
            <Box flexGrow={1} className={classes.title} onClick={() => onDetailAction(drug)}>
                {drug.name}
            </Box>
            {/* <Box className={classes.icon} onClick={() => onDeleteAction(drug)}>
                <DeleteForeverIcon />
            </Box> */}
        </Box>
    )
}

export default withStyles(styles)(TherapyListItem)