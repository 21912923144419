import { createContext, useContext } from 'react';

export const PatientContext = createContext();

export const usePatientContext = () => {
    return useContext(PatientContext)
}

export const usePatient = () => {

    const getPatientId = () => {
        return sessionStorage.getItem("patient");
    }

    const setPatient = (data) => {
        sessionStorage.setItem("patient", data);        
    }

    const removePatient = () => {
        sessionStorage.removeItem('patient')        
    }

    return { getPatientId, setPatient, removePatient }
}