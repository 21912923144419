import React from "react"
import { withRouter } from "react-router"
import Box from '@material-ui/core/Box';

import StatusWeightContent from './StatusWeightContent.page'
import StatusAllergiesContent from './StatusAllergiesContent.page'
import StatusCardiologicalContent from './StatusCardiologicalContent.page'
import StatusTreatmentContent from './StatusTreatmentContent.page'
import ContentColumnsTemplate from '../../template/ContentColumns.template'
import ContentTemplate from '../../template/Content.template'

import String from "../../res/Strings_ITA.res"

const StatusContentPage = ({
    edit = false
}) => {

    return (
        <Box display='flex' flexWrap='wrap' flexDirection="row">
            <Box width={1 / 2}>
                <Box mr={1}>
                    <StatusWeightContent edit={edit} />
                </Box>
            </Box>
            <Box width={1 / 2}>
                <Box ml={1}>
                    <StatusAllergiesContent edit={edit} />
                </Box>
            </Box>
            <Box mt={2}>
                <ContentTemplate title={String.statusData.title}>
                    <Box display='flex' bgcolor='white' boxShadow={1}>
                        <ContentColumnsTemplate
                            titles={[{
                                title: String.statusData.title_section,
                                size: 1,
                                border: { top: 0, right: 0, bottom: 1, left: 0 }
                            }]}
                            column={{
                                size: 1/2
                            }}
                            border={{ top: 0, right: 1, bottom: 0, left: 0 }} >
                            <StatusCardiologicalContent edit={edit} />
                        </ContentColumnsTemplate>
                        <ContentColumnsTemplate
                            titles={[{
                                title: String.statusData.surgery,
                                size: 1,
                                border: { top: 0, right: 0, bottom: 1, left: 0 }
                            }]}
                            column={{
                                size: 1 / 2
                            }}
                            border={{ top: 0, right: 0, bottom: 0, left: 0 }} >
                            <StatusTreatmentContent edit={edit} />
                        </ContentColumnsTemplate>

                    </Box>
                </ContentTemplate>
            </Box>
        </Box>
    )
}

export default withRouter(StatusContentPage)