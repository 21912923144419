import React, { useState } from "react"
import Box from '@material-ui/core/Box'
import { GridComponent } from '../../library/materialUi/grid/Grid.component'
import BloodPressureListContainer from '../../core/bloodPressure/containers/BloodPressureList.containers'
import BloodPressureDetailContainer from '../../core/bloodPressure/containers/BloodPressureDetail.container'

import ProgressComponent from '../../library/materialUi/progress/Progress.component'
import OverviewChart from '../../components/overview/OverviewChart.component'
import OverviewList from '../../components/overview/OverviewList.components'
import Form from '../../components/form/Form.component'
import { BloodPressureChart, BloodPressureList, BloodPressureForm } from '../../conf/bloodPressure/BloodPressure.conf'

const BlodPressureOverviewPage = ({
    classes,
    range
}) => {

    const [documentId, setDocumentId] = useState(null)
    const { body, columns } = BloodPressureForm
    
    return (
        <BloodPressureListContainer
            range={range}            
            callback={(data) =>
                setDocumentId(data.length > 0 ? data[0].documentId : null)
            }>
            {({ list }) => (
                <>
                    {list.loading ? <ProgressComponent /> : null}
                    <GridComponent container>
                        <GridComponent item xs={12}>
                            <Box borderBottom={1} className={classes.content}>
                                <OverviewChart
                                    component="LineChartComponent"
                                    values={list.data ? list.data.sort((a, b) => new Date(a.blood_pressure_time) < new Date(b.blood_pressure_time) ? -1 : 1) : []}
                                    dataKeys={BloodPressureChart}
                                    XDataKey='blood_pressure_time' />
                            </Box>
                        </GridComponent>
                        <GridComponent item xs={6}>
                            <Box borderRight={1} className={classes.content}>
                                <OverviewList
                                    values={list.data ? list.data.sort((a, b) => new Date(a.blood_pressure_time) > new Date(b.blood_pressure_time) ? -1 : 1) : []}
                                    conf={BloodPressureList}
                                    detailDocumentAction={(document) => setDocumentId(document.documentId)} />
                            </Box>
                        </GridComponent>
                        <GridComponent item xs={6}>
                            <Box className={classes.content}>
                                <BloodPressureDetailContainer
                                    documentId={documentId}>
                                    {({ detail, positionValues }) => {

                                        body.position_value.options = positionValues.map(item => ({
                                            value: item.id,
                                            label: item.name
                                        }))

                                        return (
                                            <>
                                                {detail.loading ? <ProgressComponent /> : null}
                                                <Form
                                                    body={body}
                                                    columns={columns}
                                                    disabled={true}
                                                    display='flex'
                                                    style={{ padding: '0px' }}
                                                    onSubmit={() => { }}
                                                    showTitle={false}
                                                    initialValues={detail.data ? detail.data : {}} />
                                            </>
                                        )
                                    }}
                                </BloodPressureDetailContainer>
                            </Box>
                        </GridComponent>
                    </GridComponent>
                </>
            )}
        </BloodPressureListContainer>
    )
}

export default BlodPressureOverviewPage