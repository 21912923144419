import { DrawerWidth } from "../drawer/Drawer.component.style"
import { appColor } from "../../config/colors/colors"

import Logo from "../../../assets/img/dashboard/logo@3x.png"

export const styles = theme => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        background: appColor.white,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    appBarShift: {
        marginLeft: DrawerWidth,
        width: `calc(100% - ${DrawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },

    toolbar: {
        paddingRight: 24,
        background: appColor.white
    },
    content:{
        width: '100%',
        display: 'flex',
        justifyContent: "center",
        alignItems: "center",
        color: theme.palette.primary.main        
    },
    logo:{
        width: '52px',
        height: '47px',
        backgroundImage: `url(${Logo})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover", 
    }
})
