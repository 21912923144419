import React from "react"
import { withStyles } from "@material-ui/core/styles"
import TablePagination from "@material-ui/core/TablePagination"

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { itIT } from '@material-ui/core/locale';

import { styles } from "../../../styles/table/pagination/Pagination.component.style"

const theme = createMuiTheme({}, itIT);

const PaginationTemplate = ({
    rowsPerPageOptions,
    rowsPerPage,
    page,
    count,
    onChangePage,
    onChangeRowsPerPage
}) => {
    return (
        <ThemeProvider theme={theme}>
        <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
                "aria-label": "Previous Page"
            }}
            nextIconButtonProps={{
                "aria-label": "Next Page"
            }}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
        />
        </ThemeProvider>
    )
}
export const PaginationComponent = withStyles(styles)(PaginationTemplate)
