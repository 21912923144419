import { put, call, takeEvery } from 'redux-saga/effects';
import Actions from '../actions/Update.actions';
import ActionsDetail from '../../detail/actions/Detail.actions';
import MessagesActions from '../../messages/actions/Messages.actions';
import Strings from '../../../res/Strings_ITA.res';

function* updateItemSaga(action) {

    const { params, request, node, refresh } = action.payload;

    // Set Loading State
    yield put({
        type: `UPDATE_${node}`,
        action: action
    })

    try {
        let update = yield call(request, params);

        // Save Values
        yield put({
            type: `UPDATE_${node}_SUCCESS`,
            action: Actions.updateItemSuccess(update.data.data)
        })
        yield put(MessagesActions.setSuccess(Strings.message.successUpdate))

        if (refresh) {
            yield put(ActionsDetail.getDetail({
                params: refresh.params,
                request: refresh.request,
                node: refresh.node
            }))
        }

    } catch (e) {
        // Save Error
        yield put({
            type: `UPDATE_${node}_ERROR`,
            action: Actions.updateItemError(e)
        })
        yield put(MessagesActions.setError(e))
    }
}



export function* updateWatcherSaga() {
    yield takeEvery(Actions.updateItem, updateItemSaga);
}