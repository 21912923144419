import React from "react"
import { withRouter } from "react-router"
import { useAuthContext } from '../../../contexts/auth/Auth';
import ListContainer from '../../list/container/List.container'
import Request from '../api/Cigarettes.api'
import { selectCigarettes } from "../selector/Cigarettes.selector";

const CigarettesListContainer = ({
    match,
    children,
    callback,
    range
}) => {

    const patientId = match.params.patient
    const { authToken } = useAuthContext()

    return (
        <ListContainer 
            request={Request.cigarettesList}
            params={{
                access_token: authToken.access_token,
                patientId: patientId,
                range:range
            }}
            node='CIGARETTES'
            selector={selectCigarettes}
            callback={callback}
            refresh={[range]}>
            {children}
        </ListContainer>
    )
}

export default withRouter(CigarettesListContainer)