import React from 'react'
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "./ReadOnlyMessage.component.style";
import Strings from '../../res/Strings_ITA.res'

const ReadOnlyMessageComponent = ({
    classes
}) => {

    return (
        <Paper className={classes.root}>
            {Strings.message.readOnly}
        </Paper>
    )
}

export default withStyles(styles)(ReadOnlyMessageComponent)