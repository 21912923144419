import { ListReducer, InitialState as InitialStateList } from '../../../core/list/reducer/List.reducer'
import { DetailReducer, InitialState as InitialStateDetail } from '../../../core/detail/reducer/Detail.reducer'
import { AddReducer } from '../../../core/add/reducer/Add.reducer'
import { UpdateReducer } from '../../../core/update/reducer/Update.reducer'

export const patientsReducer = (state = InitialStateList, action) => {
    switch (action.type) {
        case 'GET_PATIENT_LIST':
        case 'GET_PATIENT_LIST_SUCCESS':
        case 'GET_PATIENT_LIST_ERROR':
            return ListReducer(state, action.action)
        default:
            return state
    }
}


export const patientReducer = (state = InitialStateDetail, action) => {
    switch (action.type) {
        case 'GET_PATIENT_DETAIL':
        case 'GET_PATIENT_DETAIL_SUCCESS':
        case 'GET_PATIENT_DETAIL_ERROR':
            return DetailReducer(state, action.action)

        case 'ADD_PATIENT':
        case 'ADD_PATIENT_SUCCESS':
        case 'ADD_PATIENT_ERROR':
            return AddReducer(state, action.action)

        case 'UPDATE_PATIENT':
        case 'UPDATE_PATIENT_SUCCESS':
        case 'UPDATE_PATIENT_ERROR':
            return UpdateReducer(state, action.action)


        default:
            return state
    }
}
