import React, { useState } from "react"

import Box from '@material-ui/core/Box';
import _first from 'lodash/first';

import { BloodPressureForm } from '../../conf/bloodPressure/BloodPressure.conf'
import String from "../../res/Strings_ITA.res"

import ContentTemplate from '../../template/Content.template'
import FilterComponent from '../../components/filter/Filter.component'
import ProgressComponent from '../../library/materialUi/progress/Progress.component'
import Form from '../../components/form/Form.component';

import BloodPressureListContainer from '../../core/bloodPressure/containers/BloodPressureList.containers'
import BloodPressureDetailContainer from '../../core/bloodPressure/containers/BloodPressureDetail.container'
import BloodPressureAddContainer from '../../core/bloodPressure/containers/BloodPressureAdd.container'

const BloodPressureContentPage = ({
    edit = false,
}) => {

    const { body, footer, columns } = BloodPressureForm
    const [documentId, setDocumentId] = useState(null)
    const [document, setDocument] = useState({})

    return (
        <Box>
            {/* LIST */}
            <BloodPressureListContainer>
                {({ list }) => (
                    <>
                        {list.loading ? <ProgressComponent /> : null}
                        <FilterComponent
                            loading={list.loading}
                            documents={list.data ? list.data : []}
                            documentDefault={list.data && edit === false ? _first(list.data) : null}
                            onSelectDocument={(id) => setDocumentId(id)}
                            edit={edit} />
                    </>
                )}
            </BloodPressureListContainer>

            {/* DETAIL */}
            <Box>
                <BloodPressureDetailContainer
                    documentId={documentId}>
                    {({ detail }) => {
                        setDocument(detail)
                        return (
                            <Box mt={2}>
                                {detail.loading ? <ProgressComponent /> : null}
                            </Box>
                        )
                    }}
                </BloodPressureDetailContainer>
            </Box>

            {/* ADD */}
            <ContentTemplate title={String.bloodPressure.pressure}>
                <BloodPressureAddContainer>
                    {({ item, onSubmit, positionValues }) => {
                        body.position_value.options = positionValues.map(item => ({
                            value: item.id,
                            label: item.name
                        }))                        

                        return (
                            <Form
                                body={body}
                                footer={footer}
                                columns={columns}
                                onSubmit={onSubmit}
                                style={{ padding: '0px' }}
                                showTitle={false}
                                initialValues={document.data ? document.data : null}
                                disabled={!edit}
                            />
                        )
                    }}
                </BloodPressureAddContainer>
            </ContentTemplate>
        </Box>
    )
}

export default BloodPressureContentPage