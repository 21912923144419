import React from "react"
import { Field } from "react-final-form"
import { SelectComponent } from "../../../../../materialUi/form/select/Select.component"
import { Error } from "../../error"

export const Select = ({
    item,
    errors,
    disabled,
    options,
    placeholder
}) => {
    return (
        <>
            <Field
                name={item.name}
                label={item.label}
                required={item.required}
                disabled={disabled}
                error={errors ? errors[item.name] : null}
                component={SelectComponent}
                values={options}
                placeholder={placeholder}
            />
            <Error name={item.name} />
        </>
    )
}
