// Login
const login = "/api-auth/token/"

// Patients
const patients = "/api/v1/patients/"

// BloodPressure
const bloodPressure = (patientID) => `api/v1/patients/${patientID}/documents/bloodPressure/`

// Risk Factor
const riskFactor = (patientID) => `api/v1/patients/${patientID}/documents/riskFactor/`

// History
const history = (patientID) => `api/v1/patients/${patientID}/documents/history/`

// Status
const status = (patientID) => `api/v1/patients/${patientID}/documents/status/`

// Allergies
const allergies = (patientID) => `api/v1/patients/${patientID}/documents/allergies/`

// Weight
const weight = (patientID) => `api/v1/patients/${patientID}/documents/weight/`

// Cigarettes
const cigarettes = (patientID) => `api/v1/patients/${patientID}/documents/cigarettes/`

// Heart Rate
const heartRate = (patientID) => `api/v1/patients/${patientID}/documents/heart-rate/`

// Treatments
const treatments = (patientID) => `api/v1/patients/${patientID}/documents/treatment/`

// Therapy
const therapyPlan = (patientID) => `api/v1/patients/${patientID}/documents/therapy-plan/`
const therapyNAO = (patientID) => `api/v1/patients/${patientID}/documents/nao-drugs/`
const therapyWarfarin = (patientID) => `api/v1/patients/${patientID}/documents/warfarin-drugs/`
const therapyAntiPlatelet = (patientID) => `api/v1/patients/${patientID}/documents/anti-platelet-drugs/`
const therapyOtherDrug = (patientID) => `api/v1/patients/${patientID}/documents/other-drugs/`

// Pathologies
const pathology = (patientID) => `api/v1/patients/${patientID}/documents/pathology/`
const AFibrillationPathology = (patientID) => `api/v1/patients/${patientID}/documents/afib/`
const AFlutterPathology = (patientID) => `api/v1/patients/${patientID}/documents/afl/`

// Settings
const positionValues = "/api/v1/position-values/"
const treatmentsType = "/api/v1/treatment-types/"
const ejectionFractions = "/api/v1/ejection-fractions/"
const NAODrugs = "/api/v1/nao-drugs/"
const ANTIPLATELETDrugs = "/api/v1/anti-platelet-drugs/"
const AtrialFibrillationType = "/api/v1/atrial-fibrillation-types/"
const AtrialFlutterType = "/api/v1/atrial-flutter-types/"


const endpoints = {
    login,
    patients,
    bloodPressure,
    riskFactor,
    history,
    status,
    allergies,
    weight,
    cigarettes,
    heartRate,
    treatments,    
    pathology,
    positionValues,
    treatmentsType,
    ejectionFractions,
    NAODrugs,
    ANTIPLATELETDrugs,
    therapyPlan,
    therapyNAO,
    therapyWarfarin,
    therapyAntiPlatelet,
    therapyOtherDrug,
    AtrialFibrillationType,
    AtrialFlutterType,
    AFibrillationPathology,
    AFlutterPathology
}



export default endpoints
