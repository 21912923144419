import Manager from '../../../api/Manager.api'
import Endpoints from '../../../api/Endpoints.api'

const AtrialFlutterUpsertRequest = ({ values, id }) => {
    var body = new FormData();

    // ID
    if (id) {
        body.set('id', id);
    }
    
    if (values.atrial_flutter) {
        body.set('atrial_flutter', values.atrial_flutter);
    }

    body.set('mechanical_valve', values.mechanical_valve);

    return body
}

const AtrialFlutterList = ({
    access_token,
    patientId,
}) => {
    return Manager.client(access_token).get(Endpoints.pathology(patientId))
}

const AtrialFlutterDetail = ({
    access_token,
    patientId,
    id
}) => {
    return Manager.client(access_token).get(`${Endpoints.pathology(patientId)}${id}/`)
}

const AtrialFlutterUpdate = ({
    access_token,
    patientId,
    values,
    id
}) => {
    return Manager.client(access_token).update(Endpoints.pathology(patientId), AtrialFlutterUpsertRequest({ id, values }))
}

const AtrialFlutterAdd = ({
    access_token,
    patientId,
    values,
}) => {
    return Manager.client(access_token).post(Endpoints.AFlutterPathology(patientId), AtrialFlutterUpsertRequest({ values }))
}

const Request = {
    AtrialFlutterList,
    AtrialFlutterDetail,
    AtrialFlutterUpdate,
    AtrialFlutterAdd
}

export default Request
