import String from "../../res/Strings_ITA.res"

export const DateRangeConf = {
	columns: [
		{
			size: 1,
			title: String.rangeDate.subtitle,
			border: { top: 0, right: 0, bottom: 0, left: 0 }
		},        
	],
	body: {	
        range: {
            component: 'Radio',
            name: 'range',
            required: false,
            disabled: false,
            xs: 6,
            column: 0,
            options:[
                {
                    value: 'week',
                    label: String.rangeDate.week
                },
                {
                    value: 'month',
                    label: String.rangeDate.month
                },
                {
                    value: 'month_3',
                    label: String.rangeDate.month_3
                },
                {
                    value: 'year',
                    label: String.rangeDate.year
                }
            ],
            validation: {
                message: String.general.alert
            },
        },	                
	},
	footer: {
		columns: [
			{
				component: "ButtonSubmit",
				title: String.rangeDate.search,
				variant: "contained",
				color: "primary"
			}
		]
	}
}

