import String from "../../res/Strings_ITA.res"

export const UpdateRiskFactorConf = {
    columns: [
        {
            size: 1 / 3,
            title: String.riskFactors.title,
            border: { top: 0, right: 0, bottom: 0, left: 0 }
        },
        {
            size: 1 / 3,
            title: String.riskFactors.messages,
            border: { top: 0, right: 1, bottom: 0, left: 1 }
        },
        {
            size: 1 / 3,
            title: String.riskFactors.note,
            border: { top: 0, right: 0, bottom: 0, left: 0 }
        }
    ],
    body: {
        smoke_value: {
            component: 'Checkbox',
            name: 'smoke_value',
            label: String.riskFactors.smoke,
            required: true,
            disabled: false,
            xs: 12,
            column: 0,
            validation: {
                message: String.general.alert
            },
        },
        smoke_alert: {
            component: 'EditText',
            name: 'smoke_alert',
            label: String.riskFactors.smoke,
            required: false,
            disabled: false,
            xs: 12,
            column: 1,
            validation: {
                message: String.general.alert
            },
        },
        hypertension_value: {
            component: 'Checkbox',
            name: 'hypertension_value',
            label: String.riskFactors.hypertension,
            required: true,
            disabled: false,
            xs: 12,
            column: 0,
            validation: {
                message: String.general.alert
            },
        },
        hypertension_alert: {
            component: 'EditText',
            name: 'hypertension_alert',
            label: String.riskFactors.hypertension,
            required: false,
            disabled: false,
            xs: 12,
            column: 1,
            validation: {
                message: String.general.alert
            },
        },
        dyslipidemia_value: {
            component: 'Checkbox',
            name: 'dyslipidemia_value',
            label: String.riskFactors.dyslipidemia,
            required: true,
            disabled: false,
            xs: 12,
            column: 0,
            validation: {
                message: String.general.alert
            },
        },
        dyslipidemia_alert: {
            component: 'EditText',
            name: 'dyslipidemia_alert',
            label: String.riskFactors.dyslipidemia,
            required: false,
            disabled: false,
            xs: 12,
            column: 1,
            validation: {
                message: String.general.alert
            },
        },
        diabetes_value: {
            component: 'Checkbox',
            name: 'diabetes_value',
            label: String.riskFactors.diabetes,
            required: true,
            disabled: false,
            xs: 12,
            column: 0,
            validation: {
                message: String.general.alert
            },
        },
        diabetes_alert: {
            component: 'EditText',
            name: 'diabetes_alert',
            label: String.riskFactors.diabetes,
            required: false,
            disabled: false,
            xs: 12,
            column: 1,
            validation: {
                message: String.general.alert
            },
        },
        familiarity_value: {
            component: 'Checkbox',
            name: 'familiarity_value',
            label: String.riskFactors.familiarity,
            required: true,
            disabled: false,
            xs: 12,
            column: 0,
            validation: {
                message: String.general.alert
            },
        },
        familiarity_alert: {
            component: 'EditText',
            name: 'familiarity_alert',
            label: String.riskFactors.familiarity,
            required: false,
            disabled: false,
            xs: 12,
            column: 1,
            validation: {
                message: String.general.alert
            },
        },
        chemotherapy_value: {
            component: 'Checkbox',
            name: 'chemotherapy_value',
            label: String.riskFactors.chemotherapy,
            required: true,
            disabled: false,
            xs: 12,
            column: 0,
            validation: {
                message: String.general.alert
            },
        },
        chemotherapy_alert: {
            component: 'EditText',
            name: 'chemotherapy_alert',
            label: String.riskFactors.chemotherapy,
            required: false,
            disabled: false,
            xs: 12,
            column: 1,
            validation: {
                message: String.general.alert
            },
        },
        radiotherapy_value: {
            component: 'Checkbox',
            name: 'radiotherapy_value',
            label: String.riskFactors.radiotherapy,
            required: true,
            disabled: false,
            xs: 12,
            column: 0,
            validation: {
                message: String.general.alert
            },
        },
        radiotherapy_alert: {
            component: 'EditText',
            name: 'radiotherapy_alert',
            label: String.riskFactors.radiotherapy,
            required: false,
            disabled: false,
            xs: 12,
            column: 1,
            validation: {
                message: String.general.alert
            },
        },
        hiv_value: {
            component: 'Checkbox',
            name: 'hiv_value',
            label: String.riskFactors.hiv,
            required: true,
            disabled: false,
            xs: 12,
            column: 0,
            validation: {
                message: String.general.alert
            },
        },
        hiv_alert: {
            component: 'EditText',
            name: 'hiv_alert',
            label: String.riskFactors.hiv,
            required: false,
            disabled: false,
            xs: 12,
            column: 1,
            validation: {
                message: String.general.alert
            },
        },
        renalimpairment_value: {
            component: 'Checkbox',
            name: 'renalimpairment_value',
            label: String.riskFactors.renalImpairment,
            required: true,
            disabled: false,
            xs: 12,
            column: 0,
            validation: {
                message: String.general.alert
            },
        },
        renalimpairment_alert: {
            component: 'EditText',
            name: 'renalimpairment_alert',
            label: String.riskFactors.renalImpairment,
            required: false,
            disabled: false,
            xs: 12,
            column: 1,
            validation: {
                message: String.general.alert
            },
        },
        note_message: {
            component: 'EditArea',
            name: 'note_message',
            label: String.general.note,
            required: false,
            disabled: false,
            rows: 9,
            type: 'text',
            xs: 12,
            column: 2,
            validation: {
                message: String.general.alert
            },
        },

    },
    footer: {
        columns: [
            {
                component: 'ButtonSubmit',
                title: String.general.save,
                variant: 'contained',
                color: 'primary',
            },
        ]
    }
};