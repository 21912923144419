import moment from 'moment'
import Manager from '../../../api/Manager.api'
import Endpoints from '../../../api/Endpoints.api'


const heartRateList = ({
    access_token,
    patientId,
    range
}) => {
    
    var params = new URLSearchParams();
    if (range) {
        params.append("from", moment(range.from).format('YYYY-MM-DD'));
        params.append("to", moment(range.to).format('YYYY-MM-DD'));
    }

    return Manager.client(access_token).get(Endpoints.heartRate(patientId), { params })
}

const heartRateDetail = ({
    access_token,
    patientId,
    id
}) => {
    return Manager.client(access_token).get(`${Endpoints.heartRate(patientId)}${id}/`)
}

const Request = {
    heartRateList,
    heartRateDetail,
}


export default Request