import React from "react"
import clsx from "clsx"
import { withStyles } from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Box from '@material-ui/core/Box'
import { styles } from "../../styles/appBar/AppBar.component.style"
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import IconButton from '@material-ui/core/IconButton';

const AppBarTemplate = ({
    classes,
    openDrawer,
    onOpenDrawer,
    onOpenNotification,
    onClickLogout,
    title,
    badge
}) => {

    return (
        <AppBar
            position="absolute"
            className={clsx(classes.appBar, openDrawer && classes.appBarShift)}>
            <Toolbar className={classes.toolbar}>
                <Box className={classes.content}>
                    <Box>
                        <div className={classes.logo} />
                    </Box>
                    <Box fontWeight="fontWeightBold" fontSize={22} ml={2}>
                        {title}
                    </Box>
                </Box>
                <IconButton onClick={onClickLogout}>
                    <ExitToAppIcon />
                </IconButton>                
            </Toolbar>
        </AppBar>
    )
}

export const AppBarComponent = withStyles(styles)(AppBarTemplate)
