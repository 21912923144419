import React from "react"
import { withStyles } from "@material-ui/core/styles"
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { styles } from "../../../styles/form/radio/Radio.component.style"

const RadioTemplate = ({    
    disabled = false,
    label,
    input,
}) => {


    return (
        <FormControlLabel
            control={
                <Radio
                    onChange={input.onChange}
                    value={input.value}
                    color="primary"
                    checked={input.checked}
                    disabled={disabled}
                />
            }
            label={label} />
    )
}

export const RadioComponent = withStyles(styles)(RadioTemplate)
