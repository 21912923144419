import React from "react"
import _first from 'lodash/first'
import Box from '@material-ui/core/Box';
import Form from '../../components/form/Form.component';

import { OtherTherapyConf } from '../../conf/therapy/Therapy.conf'
import OtherTherapyAddContainer from '../../core/therapy/containers/OtherTherapyAdd.container'
import DividerComponent from "../../library/materialUi/divider/Divider.component";

const OtherTherapyContentPage = ({
    edit = false,
    document = {},
    onCreateDocument
}) => {

    const { body, footer, columns } = OtherTherapyConf

    return (
        <Box width={1}>

            <DividerComponent />

            {/* ADD */}
            <OtherTherapyAddContainer
                onCreateDocument={onCreateDocument}>
                {({ onSubmit }) => (
                    <Form
                        body={body}
                        columns={columns}
                        footer={edit ? footer : null}
                        onSubmit={onSubmit}
                        display='block'
                        style={{ padding: '12px' }}
                        showTitle={true}
                        initialValues={document ? document : null}
                        disabled={!edit}
                    />
                )}
            </OtherTherapyAddContainer>
        </Box>
    )
}

export default OtherTherapyContentPage