import { put, call, takeEvery } from 'redux-saga/effects';
import Actions from '../actions/List.actions';
import MessagesActions from '../../messages/actions/Messages.actions';

function* getListSaga(action) {

    const { params, request, node, callback = () => { } } = action.payload;

    // Set Loading State
    yield put({
        type: `GET_${node}_LIST`,
        action: action
    })

    try {
        let list = yield call(request, params);

        // Save Values
        yield put({
            type: `GET_${node}_LIST_SUCCESS`,
            action: Actions.getListSuccess(list.data.data)
        })

        callback(list.data.data)

    } catch (e) {
        // Save Error
        yield put({
            type: `GET_${node}_LIST_ERROR`,
            action: Actions.getListError(e)
        })
        yield put(MessagesActions.setError(e))
    }
}



export function* listWatcherSaga() {
    yield takeEvery(Actions.getList, getListSaga);
}