import { ListReducer, InitialState as InitialStateList } from '../../../core/list/reducer/List.reducer'
import { DetailReducer, InitialState as InitialStateDetail } from '../../../core/detail/reducer/Detail.reducer'
import { AddReducer } from '../../../core/add/reducer/Add.reducer'

export const bloodPressureReducer = (state = InitialStateDetail, action) => {
    switch (action.type) {
        case 'GET_BLOOD_PRESSURE_DETAIL':
        case 'GET_BLOOD_PRESSURE_DETAIL_SUCCESS':
        case 'GET_BLOOD_PRESSURE_DETAIL_ERROR':
            return DetailReducer(state, action.action)
        default:
            return state
    }
}

export const bloodPressuresReducer = (state = InitialStateList, action) => {
    switch (action.type) {

        case 'GET_BLOOD_PRESSURE_LIST':
        case 'GET_BLOOD_PRESSURE_LIST_SUCCESS':
        case 'GET_BLOOD_PRESSURE_LIST_ERROR':
            return ListReducer(state, action.action)

        case 'ADD_BLOOD_PRESSURE':
        case 'ADD_BLOOD_PRESSURE_SUCCESS':
        case 'ADD_BLOOD_PRESSURE_ERROR':
            return AddReducer(state, action.action)

        default:
            return state
    }
}