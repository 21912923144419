import axios from "axios"

const apiConfig = {
    baseURL: "https://cardiofilo.abinsula.com",
    clientId: "GB4DAgp2iSh4mujndNf9tk65qFaGyu0Okac1JnNP",
    clientSecret: ""
}

const API = access_token => {
    var headers = {
        "Content-Type": "application/json"
    }

    if (access_token) {
        headers.Authorization = "Bearer " + access_token
    }

    return axios.create({
        baseURL: apiConfig.baseURL,
        headers: headers
    })
}

const Manager = {
    apiConfig: apiConfig,
    client: access_token => API(access_token),
}

export default Manager
