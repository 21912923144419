import Manager from '../../../api/Manager.api'
import Endpoints from '../../../api/Endpoints.api'

const HistoryUpsertRequest = ({ values, id }) => {
    var body = new FormData();

    // ID
    if (id) {
        body.set('id', id);
    }

    if (values.general_history) {
        body.set('general_history', values.general_history);
    }

    if (values.cardiovascular_history) {
        body.set('cardiovascular_history', values.cardiovascular_history);
    }


    if (values.recent_history) {
        body.set('recent_history', values.recent_history);
    }

    if (values.conclusions) {
        body.set('conclusions', values.conclusions);
    }


    if (values.therapy) {
        body.set('therapy', values.therapy);
    }

    return body
}


const historyList = ({
    access_token,
    patientId,
}) => {
    return Manager.client(access_token).get(Endpoints.history(patientId))
}

const historyDetail = ({
    access_token,
    patientId,
    id
}) => {
    return Manager.client(access_token).get(`${Endpoints.history(patientId)}${id}/`)
}


const historyAdd = ({
    access_token,
    values,
    patientId,
}) => {
    return Manager.client(access_token).post(Endpoints.history(patientId), HistoryUpsertRequest({ values }))
}

const historyUpdate = ({
    access_token,
    id,
    values
}) => {
    return Manager.client(access_token).put(`${Endpoints.history}${id}/`, HistoryUpsertRequest({id, values}))
}

const historyDelete = ({
    access_token,
    id,
}) => {
    return Manager.client(access_token).delete(`${Endpoints.history}${id}/`)
}


const Request = {
    historyList,
    historyDetail,    
    historyAdd,
    historyUpdate,
    historyDelete
}


export default Request