export const styles = theme => ({
	button: {
		margin: theme.spacing(1)
	},
	submit: {
		width: theme.spacing(22),
		height: theme.spacing(5),
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
		borderRadius: theme.spacing(1),
		objectFit: 'contain',
		boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
		backgroundImage: 'linear-gradient(180deg, #38e6ca, #3ac2c0)',
		fontSize: theme.spacing(2),
		fontWeight: 'bold',
		fontStretch: 'normal',
		fontStyle: 'normal',
		color: '#ffffff',
	},
	logout: {
		width: theme.spacing(22),
		height: theme.spacing(5),
		borderRadius: theme.spacing(1),
		objectFit: 'contain',
		boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
		backgroundImage: 'linear-gradient(180deg, #38e6ca, #3ac2c0)',
		fontSize: theme.spacing(2),
		fontWeight: 'bold',
		fontStretch: 'normal',
		fontStyle: 'normal',
		color: '#ffffff',
	},
	add: {
		width: theme.spacing(22),
		height: theme.spacing(5),
		borderRadius: theme.spacing(1),
		objectFit: 'contain',
		boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
		backgroundImage: 'linear-gradient(180deg, #38e6ca, #3ac2c0)',
		fontSize: theme.spacing(2),
		fontWeight: 'bold',
		fontStretch: 'normal',
		fontStyle: 'normal',
		color: '#ffffff',
	},
	delete: {
		width: theme.spacing(22),
		height: theme.spacing(5),
		borderRadius: theme.spacing(1),
		objectFit: 'contain',
		boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
		backgroundImage: 'linear-gradient(180deg, #38e6ca, #3ac2c0)',
		fontSize: theme.spacing(2),
		fontWeight: 'bold',
		fontStretch: 'normal',
		fontStyle: 'normal',
		color: '#ffffff',
	},

});
