import React from "react"
import { withRouter } from "react-router"
import { useAuthContext } from '../../../contexts/auth/Auth';

import DetailContainer from '../../detail/container/Detail.container'
import { selectWeight } from "../selector/Weight.selector";
import Request from '../api/Weight.api'


const WeightDetailContainer = ({
    documentId,
    match,    
    children
}) => {
    
    const patientId = match.params.patient
    const { authToken } = useAuthContext()

    return (
        <DetailContainer
            params={{
                access_token: authToken.access_token,
                patientId: patientId,
                id: documentId
            }}
            request={Request.weightDetail}
            selector={selectWeight}
            node='WEIGHT'>
            {children}
        </DetailContainer>
    )
}

export default withRouter(WeightDetailContainer)