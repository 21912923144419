import React from "react"
import { useAuthContext } from '../../../contexts/auth/Auth';
import { withRouter } from "react-router"

import AddContainer from '../../add/container/Add.container'
import { selectAllergy } from "../selector/Allergies.selector";
import Request from '../api/Allergies.api'

const AllergiesAddContainer = ({    match,
    children
}) => {

    const patientId = match.params.patient
    const { authToken } = useAuthContext()    

    return (
        <AddContainer
            request={Request.allergiesAdd}
            params={{
                access_token: authToken.access_token,
                patientId: patientId
            }}
            node='ALLERGIES'
            selector={selectAllergy}>
            {children}                    
        </AddContainer>
    )
}

export default withRouter(AllergiesAddContainer)