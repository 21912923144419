import { put, call, all, takeEvery } from 'redux-saga/effects';
import Actions from '../actions/Multiple.actions';
import MessagesActions from '../../messages/actions/Messages.actions';

function* getMultipleListSaga(action) {

    const { params, requests, node, callback } = action.payload;
    
    // Set Loading State
    yield put({
        type: `GET_${node}_MULTIPLE_LIST`,
        action: action
    })

    try {

        let yields = {}
        requests.forEach(element => {
            yields[element.node] = call(element.api, params)
        });

        const results = yield all(yields)      
        
        let data = {}
        Object.keys(results).forEach(key => {            
            data[key] = results[key].data.data
        });

        // Save Values
        yield put({
            type: `GET_${node}_MULTIPLE_LIST_SUCCESS`,
            action: Actions.getMultipleListSuccess(data)
        })

        callback(results)

    } catch (e) {
        // Save Error
        yield put({
            type: `GET_${node}_MULTIPLE_LIST_ERROR`,
            action: Actions.getMultipleListError(e)
        })
        yield put(MessagesActions.setError(e))
    }
}



export function* multipleWatcherSaga() {
    yield takeEvery(Actions.getMultipleList, getMultipleListSaga);
}