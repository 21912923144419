export const styles = theme => ({
    root: {
        flexGrow: 1,
        paddingLeft: theme.spacing(30),
        paddingRight: theme.spacing(30)
    },
    pathology: {
        marginTop: theme.spacing(4),
        marginLeft: theme.spacing(36)
    }
});