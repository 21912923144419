import String from "../../res/Strings_ITA.res"
import { BeforeAfterMealConf, AmountOfDrugToBeTaken } from "../common/Common.conf";

export const start = {
    component: 'CalendarPicker',
    name: 'start',
    label: String.therapy.start,
    calendarType: 'DatePicker',
    required: true,
    disabled: false,
    disableFuture: false,
    validation: {
        message: String.general.alert
    },
}

export const end = {
    component: 'CalendarPicker',
    name: 'end',
    label: String.therapy.end,
    calendarType: 'DatePicker',
    required: true,
    disabled: false,
    disableFuture: false,
    validation: {
        message: String.general.alert
    },
}

export const next_check = {
    component: 'CalendarPicker',
    name: 'next_check',
    label: String.anticoagulant_therapy.next,
    calendarType: 'DatePicker',
    required: true,
    disabled: false,
    disableFuture: false,    
    validation: {
        message: String.general.alert
    },
}

export const monday = {
    component: 'Checkbox',
    name: 'monday',
    label: String.therapy.monday,
    required: false,
    disabled: false,
    validation: {
        message: String.general.alert
    },
}

export const tuesday = {
    component: 'Checkbox',
    name: 'tuesday',
    label: String.therapy.tuesday,
    required: false,
    disabled: false,
    validation: {
        message: String.general.alert
    },
}

export const wednesday = {
    component: 'Checkbox',
    name: 'wednesday',
    label: String.therapy.wednesday,
    required: false,
    disabled: false,
    validation: {
        message: String.general.alert
    },
}

export const thursday = {
    component: 'Checkbox',
    name: 'thursday',
    label: String.therapy.thursday,
    required: false,
    disabled: false,
    validation: {
        message: String.general.alert
    },
}

export const friday = {
    component: 'Checkbox',
    name: 'friday',
    label: String.therapy.friday,
    required: false,
    disabled: false,
    validation: {
        message: String.general.alert
    },
}

export const saturday = {
    component: 'Checkbox',
    name: 'saturday',
    label: String.therapy.saturday,
    required: false,
    disabled: false,
    validation: {
        message: String.general.alert
    },
}

export const sunday = {
    component: 'Checkbox',
    name: 'sunday',
    label: String.therapy.sunday,
    required: false,
    disabled: false,
    validation: {
        message: String.general.alert
    },
}

export const time1 = {
    component: 'Checkbox',
    name: 'time1',
    label: String.therapy.time1,
    required: false,
    disabled: false,
    validation: {
        message: String.general.alert
    },
}

export const time2 = {
    component: 'Checkbox',
    name: 'time2',
    label: String.therapy.time2,
    required: false,
    disabled: false,
    validation: {
        message: String.general.alert
    },
}

export const time3 = {
    component: 'Checkbox',
    name: 'time3',
    label: String.therapy.time3,
    required: false,
    disabled: false,
    validation: {
        message: String.general.alert
    },
}

export const time4 = {
    component: 'Checkbox',
    name: 'time4',
    label: String.therapy.time4,
    required: false,
    disabled: false,
    validation: {
        message: String.general.alert
    },
}

export const has_drug_taken_before_or_after_meal = {
    component: 'Radio',
    name: 'has_drug_taken_before_or_after_meal',
    label: String.therapy.mode,
    required: true,
    disabled: false,
    options: BeforeAfterMealConf,
    validation: {
        message: String.general.alert
    },
}


export const Panels = [
    {
        id: 0,
        title: String.therapy.anticoagulant,
    },
    {
        id: 1,
        title: String.therapy.warfarin,
    },
    {
        id: 2,
        title: String.therapy.antiplatelet,
    },
    {
        id: 3,
        title: String.therapy.other_drugs,
    }
]

export const DOACTherapyConf = {
    columns: [
        {
            size: 1,
            title: String.anticoagulant_therapy.doac,
            border: { top: 0, right: 0, bottom: 0, left: 0 }
        },
        {
            size: 1,
            title: String.therapy.days_of_therapy,
            border: { top: 1, right: 0, bottom: 0, left: 0 }
        },
        {
            size: 1,
            title: String.therapy.time,
            border: { top: 1, right: 0, bottom: 0, left: 0 }
        },
        {
            size: 1,
            title: String.therapy.assumption,
            border: { top: 1, right: 0, bottom: 0, left: 0 }
        },

    ],
    body: {
        serum_creatinine: {
            component: 'EditText',
            name: 'serum_creatinine',
            label: String.anticoagulant_therapy.creatinine,
            required: true,
            disabled: false,
            type: 'number',
            xs: 6,
            column: 0,
            validation: {
                message: String.general.alert
            },
        },
        verapamil_therapy: {
            component: 'Checkbox',
            name: 'verapamil_therapy',
            label: String.anticoagulant_therapy.verapamil,
            required: false,
            disabled: false,
            xs: 6,
            column: 0,
            validation: {
                message: String.general.alert
            },
        },
        drug: {
            component: 'Select',
            name: 'drug',
            label: String.therapy.select,
            required: true,
            disabled: false,
            xs: 6,
            column: 0,
            validation: {
                message: String.general.alert
            },
        },
        dosage: {
            component: 'EditText',
            name: 'dosage',
            label: String.therapy.dosage,
            required: true,
            disabled: false,
            type: 'number',
            xs: 6,
            column: 0,
            validation: {
                message: String.general.alert
            },
        },
        start: {
            ...start,
            column: 1,
            xs: 6,
        },
        end: {
            ...end,
            column: 1,
            xs: 6,
        },
        monday: {
            ...monday,
            xs: 3,
            column: 1,
        },
        tuesday: {
            ...tuesday,
            xs: 3,
            column: 1,
        },
        wednesday: {
            ...wednesday,
            xs: 3,
            column: 1,
        },
        thursday: {
            ...thursday,
            xs: 3,
            column: 1,
        },
        friday: {
            ...friday,
            xs: 3,
            column: 1,
        },
        saturday: {
            ...saturday,
            xs: 3,
            column: 1,
        },
        sunday: {
            ...sunday,
            xs: 3,
            column: 1,
        },
        time1: {
            ...time1,
            xs: 3,
            column: 2,
        },
        time2: {
            ...time2,
            xs: 3,
            column: 2,
        },
        time3: {
            ...time3,
            xs: 3,
            column: 2,
        },
        time4: {
            ...time4,
            xs: 3,
            column: 2,
        },
        has_drug_taken_before_or_after_meal: {
            ...has_drug_taken_before_or_after_meal,
            xs: 12,
            column: 3,
        }
    },
    footer: {
        columns: [
            {
                component: 'ButtonSubmit',
                title: String.therapy.button,
                variant: 'contained',
                color: 'primary',
            },
        ]
    }
};

export const WarfarinTherapyConf = {
    columns: [
        {
            size: 1,
            title: String.anticoagulant_therapy.info,
            border: { top: 0, right: 0, bottom: 0, left: 0 }
        },
        {
            size: 1,
            title: String.therapy.time,
            border: { top: 1, right: 0, bottom: 0, left: 0 }
        },
        {
            size: 1,
            title: String.therapy.assumption,
            border: { top: 0, right: 0, bottom: 0, left: 0 }
        },
        {
            size: 1,
            title: String.therapy.monday,
            border: { top: 1, right: 0, bottom: 0, left: 0 }
        },
        {
            size: 1,
            title: String.therapy.tuesday,
            border: { top: 1, right: 0, bottom: 0, left: 0 }
        },
        {
            size: 1,
            title: String.therapy.wednesday,
            border: { top: 1, right: 0, bottom: 0, left: 0 }
        },
        {
            size: 1,
            title: String.therapy.thursday,
            border: { top: 1, right: 0, bottom: 0, left: 0 }
        },
        {
            size: 1,
            title: String.therapy.friday,
            border: { top: 1, right: 0, bottom: 0, left: 0 }
        },
        {
            size: 1,
            title: String.therapy.saturday,
            border: { top: 1, right: 0, bottom: 0, left: 0 }
        },
        {
            size: 1,
            title: String.therapy.sunday,
            border: { top: 1, right: 0, bottom: 0, left: 0 }
        },
    ],
    body: {
        start: {
            ...start,
            column: 0,
            xs: 4,
        },
        end: {
            ...end,
            column: 0,
            xs: 4,
        },
        next_check: {
            ...next_check,
            column: 0,
            xs: 4,            
        },
        current_INR: {
            component: 'EditText',
            name: 'current_INR',
            label: String.anticoagulant_therapy.current_inr,
            required: true,
            disabled: false,
            type: 'number',
            xs: 4,
            column: 0,
            validation: {
                message: String.general.alert
            },
        },
        weekly_dose: {
            component: 'EditText',
            name: 'weekly_dose',
            label: String.anticoagulant_therapy.weekly_dose,
            required: true,
            disabled: false,
            xs: 4,
            column: 0,
            validation: {
                message: String.general.alert
            },
        },
        drug: {
            component: 'EditText',
            name: 'drug',
            label: String.therapy.select,
            required: true,
            disabled: false,
            xs: 6,
            column: 1,
            validation: {
                message: String.general.alert
            },
        },
        dosage: {
            component: 'EditText',
            name: 'dosage',
            label: String.therapy.dosage,
            required: true,
            disabled: false,
            type: 'number',
            xs: 6,
            column: 1,
            validation: {
                message: String.general.alert
            },
        },
        time: {
            component: 'CalendarPicker',
            name: 'time',
            label: String.anticoagulant_therapy.time,
            calendarType: 'TimePicker',
            required: true,
            disabled: false,
            disableFuture: false,
            column: 2,
            xs: 6,
            validation: {
                message: String.general.alert
            },
        },        
        monday: {
            component: 'Radio',
            name: 'monday',
            label: String.therapy.monday,
            required: false,
            disabled: false,            
            xs: 12,
            column: 3,
            options: AmountOfDrugToBeTaken,
            validation: {
                message: String.general.alert
            },
        },
        tuesday: {
            component: 'Radio',
            name: 'tuesday',
            label: String.therapy.tuesday,
            required: false,
            disabled: false,
            xs: 12,
            column: 4,
            options: AmountOfDrugToBeTaken,
            validation: {
                message: String.general.alert
            },
        },
        wednesday: {
            component: 'Radio',
            name: 'wednesday',
            label: String.therapy.wednesday,
            required: false,
            disabled: false,
            xs: 12,
            column: 5,
            options: AmountOfDrugToBeTaken,
            validation: {
                message: String.general.alert
            },
        },
        thursday: {
            component: 'Radio',
            name: 'thursday',
            label: String.therapy.thursday,
            required: false,
            disabled: false,
            xs: 12,
            column: 6,
            options: AmountOfDrugToBeTaken,
            validation: {
                message: String.general.alert
            },
        },
        friday: {
            component: 'Radio',
            name: 'friday',
            label: String.therapy.friday,
            required: false,
            disabled: false,
            xs: 12,
            column: 7,
            options: AmountOfDrugToBeTaken,
            validation: {
                message: String.general.alert
            },
        },
        saturday: {
            component: 'Radio',
            name: 'saturday',
            label: String.therapy.saturday,
            required: false,
            disabled: false,
            xs: 12,
            column: 8,
            options: AmountOfDrugToBeTaken,
            validation: {
                message: String.general.alert
            },
        },
        sunday: {
            component: 'Radio',
            name: 'sunday',
            label: String.therapy.sunday,
            required: false,
            disabled: false,
            xs: 12,
            column: 9,
            options: AmountOfDrugToBeTaken,
            validation: {
                message: String.general.alert
            },
        },
    },
    footer: {
        columns: [
            {
                component: 'ButtonSubmit',
                title: String.therapy.button,
                variant: 'contained',
                color: 'primary',
            },
        ]
    }
};
export const AntiPlateletTherapyConf = {
    columns: [
        {
            size: 1,
            title: String.antiplatelet_therapy.drugs,
            border: { top: 0, right: 0, bottom: 0, left: 0 }
        },
        {
            size: 1,
            title: String.therapy.days_of_therapy,
            border: { top: 1, right: 0, bottom: 0, left: 0 }
        },
        {
            size: 1,
            title: String.therapy.time,
            border: { top: 1, right: 0, bottom: 0, left: 0 }
        },
        {
            size: 1,
            title: String.therapy.assumption,
            border: { top: 1, right: 0, bottom: 0, left: 0 }
        },

    ],
    body: {
        drug: {
            component: 'Select',
            name: 'drug',
            label: String.therapy.select,
            required: true,
            disabled: false,
            xs: 6,
            column: 0,            
            validation: {
                message: String.general.alert
            },
        },        
        dosage: {
            component: 'EditText',
            name: 'dosage',
            label: String.therapy.dosage,
            required: true,
            disabled: false,
            xs: 6,
            column: 0,
            validation: {
                message: String.general.alert
            },
        },
        start: {
            ...start,
            xs: 6,
            column: 1,            
        },
        end: {
            ...end,
            xs: 6,
            column: 1,            
        },
        monday: {
            ...monday,            
            xs: 3,
            column: 1,            
        },
        tuesday: {
            ...tuesday,            
            xs: 3,
            column: 1,            
        },
        wednesday: {
            ...wednesday,            
            xs: 3,
            column: 1,            
        },
        thursday: {
            ...thursday,            
            xs: 3,
            column: 1,            
        },
        friday: {
            ...friday,            
            xs: 3,
            column: 1,            
        },
        saturday: {
            ...saturday,            
            xs: 3,
            column: 1,            
        },
        sunday: {
            ...sunday,            
            xs: 3,
            column: 1,            
        },
        time1: {
            ...time1,
            xs: 3,
            column: 2,
        },
        time2: {
            ...time2,
            xs: 3,
            column: 2,
        },
        time3: {
            ...time3,
            xs: 3,
            column: 2,
        },
        time4: {
            ...time4,
            xs: 3,
            column: 2,
        },
        has_drug_taken_before_or_after_meal: {
            ...has_drug_taken_before_or_after_meal,
            xs: 12,
            column: 3,
        }
    },
    footer: {
        columns: [
            {
                component: 'ButtonSubmit',
                title: String.therapy.button,
                variant: 'contained',
                color: 'primary',
            },
        ]
    }
};

export const OtherTherapyConf = {
    columns: [
        {
            size: 1,
            title: String.therapy.select,
            border: { top: 0, right: 0, bottom: 0, left: 0 }
        },
        {
            size: 1,
            title: String.therapy.days_of_therapy,
            border: { top: 1, right: 0, bottom: 0, left: 0 }
        },
        {
            size: 1,
            title: String.therapy.time,
            border: { top: 1, right: 0, bottom: 0, left: 0 }
        },
        {
            size: 1,
            title: String.therapy.assumption,
            border: { top: 1, right: 0, bottom: 0, left: 0 }
        },

    ],
    body: {
        drug: {
            component: 'EditText',
            name: 'drug',
            label: String.therapy.select,
            required: false,
            disabled: false,
            xs: 6,
            column: 0,
            validation: {
                message: String.general.alert
            },
        },
        dosage: {
            component: 'EditText',
            name: 'dosage',
            label: String.therapy.dosage,
            required: false,
            disabled: false,
            xs: 6,
            column: 0,
            validation: {
                message: String.general.alert
            },
        },
        start: {
            ...start,
            xs: 6,
            column: 1,            
        },
        end: {
            ...end,
            xs: 6,
            column: 1,            
        },
        monday: {
            ...monday,            
            xs: 3,
            column: 1,            
        },
        tuesday: {
            ...tuesday,            
            xs: 3,
            column: 1,            
        },
        wednesday: {
            ...wednesday,            
            xs: 3,
            column: 1,            
        },
        thursday: {
            ...thursday,            
            xs: 3,
            column: 1,            
        },
        friday: {
            ...friday,            
            xs: 3,
            column: 1,            
        },
        saturday: {
            ...saturday,            
            xs: 3,
            column: 1,            
        },
        sunday: {
            ...sunday,            
            xs: 3,
            column: 1,            
        },
        time1: {
            ...time1,
            xs: 3,
            column: 2,
        },
        time2: {
            ...time2,
            xs: 3,
            column: 2,
        },
        time3: {
            ...time3,
            xs: 3,
            column: 2,
        },
        time4: {
            ...time4,
            xs: 3,
            column: 2,
        },
        has_drug_taken_before_or_after_meal: {
            ...has_drug_taken_before_or_after_meal,
            xs: 12,
            column: 3,
        }
    },
    footer: {
        columns: [
            {
                component: 'ButtonSubmit',
                title: String.therapy.button,
                variant: 'contained',
                color: 'primary',
            },
        ]
    }
};