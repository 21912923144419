import React from 'react'
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import { styles } from "./MenuDetail.component.style";

import OverviewImage from "../../assets/img/dashboard/overview_default_illustration@3x.png";
import PersonalDataImage from "../../assets/img/dashboard/personal-data_active_illustration@3x.png";
import RiskFactorsImage from "../../assets/img/dashboard/risk-factors_active_illustration@3x.png";
import StatusImage from "../../assets/img/dashboard/status_active_illustration@3x.png";
import HistoryImage from "../../assets/img/dashboard/history_practitioner_active_illustration@3x.png";
import TherapyImage from "../../assets/img/dashboard/therapy_active_illustration@3x.png";

import Strings from '../../res/Strings_ITA.res'

import ProgressComponent from '../../library/materialUi/progress/Progress.component'

const MenuDetail = ({
    classes,
    loading,
    onOverviewAction,
    onPersonalDataAction,
    onRiskFactorsAction,
    onStatusAction,
    onHistoryAction,
    onTherapyAction,
    onRecorderEventsAction,
}) => {

    const menus = [
        {
            image: OverviewImage,
            size: 4,
            action: onOverviewAction,
            title: Strings.detail.overview
        },
        {
            image: PersonalDataImage,
            size: 4,
            action: onPersonalDataAction,
            title: Strings.detail.personalData
        },
        {
            image: RiskFactorsImage,
            size: 4,
            action: onRiskFactorsAction,
            title: Strings.detail.riskFactors
        },
        {
            image: StatusImage,
            size: 4,
            action: onStatusAction,
            title: Strings.detail.status
        },
        {
            image: HistoryImage,
            size: 4,
            action: onHistoryAction,
            title: Strings.detail.history
        },
        {
            image: TherapyImage,
            size: 4,
            action: onTherapyAction,
            title: Strings.detail.therapy
        },
    ]

    return (
        <>
            {loading ? <ProgressComponent /> : null}
            <Grid container spacing={5}>
                {menus.map((element, index) => {
                    return (
                        <Grid item xs={element.size} key={index}>
                            <Paper elevation={2} onClick={element.action} className={classes.paper}>
                                <Card className={classes.card}>
                                    <CardMedia
                                        className={classes.media}
                                        image={element.image}
                                        title={element.title} />
                                    <CardContent>
                                        <Typography className={classes.title}>
                                            {element.title}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Paper>
                        </Grid>
                    )
                })}
            </Grid>
        </>
    )
}

export default withStyles(styles)(MenuDetail)