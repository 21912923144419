import React from "react"
import BreadcrumbsContainer from '../core/breadcrumbs/containers/Breadcrumbs.container'
import Box from '@material-ui/core/Box';

const PageTemplate = ({    
    children,
}) => {

    return (
        <>
            <Box mb={2}>
                <BreadcrumbsContainer />
            </Box>
            <Box>
                {children}
            </Box>
        </>
    )
}

export default PageTemplate