import Manager from '../../../api/Manager.api'
import Endpoints from '../../../api/Endpoints.api'
import moment from 'moment'

// Warfarin
const WarfarinTherapyUpsertRequest = ({ values, id }) => {
    var body = new FormData();
    console.log(values)
    // ID
    if (id) {
        body.set('id', id);
    }

    if (values.dosage) {
        body.set('dosage', values.dosage);
    }

    body.set('monday', values.monday);
    body.set('thuesday', values.tuesday);
    body.set('wednesday', values.wednesday);
    body.set('thursday', values.thursday);
    body.set('friday', values.friday);
    body.set('saturday', values.saturday);
    body.set('sunday', values.sunday);

    if (values.time) {
        body.set('time', moment(values.time).format('HH:mm:ss'));
    }

    if (values.start) {
        body.set('start', moment(values.start).format('YYYY-MM-DD'));
    }

    if (values.end) {
        body.set('end', moment(values.end).format('YYYY-MM-DD'));
    }

    if (values.next_check) {
        body.set('next_check', moment(values.next_check).format('YYYY-MM-DD'));
    }

    if (values.current_INR) {
        body.set('current_inr', values.current_INR);
    }

    if (values.weekly_dose) {
        body.set('weekly_dose', values.weekly_dose);
    }

    if (values.drug) {
        body.set('drug', values.drug);
    }

    return body
}

const warfarinTherapyAdd = ({
    access_token,
    patientId,
    values,
}) => {
    return Manager.client(access_token).post(Endpoints.therapyWarfarin(patientId), WarfarinTherapyUpsertRequest({ values }))
}

const warfarinTherapyUpdate = ({
    access_token,
    patientId,
    values,
    id
}) => {
    return Manager.client(access_token).update(Endpoints.therapyPlan(patientId), WarfarinTherapyUpsertRequest({ id, values }))
}

const warfarinTherapyList = ({
    access_token,
    patientId,
}) => {
    return Manager.client(access_token).get(Endpoints.therapyPlan(patientId))
}

const warfarinTherapyDetail = ({
    access_token,
    patientId,
    id
}) => {
    return Manager.client(access_token).get(`${Endpoints.therapyPlan(patientId)}${id}/`)
}

const Request = {
    warfarinTherapyAdd,
    warfarinTherapyUpdate,
    warfarinTherapyList,
    warfarinTherapyDetail
}

export default Request

