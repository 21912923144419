import React, { useState, useEffect } from "react"
import { withStyles } from "@material-ui/core/styles"

import FormControl from "@material-ui/core/FormControl"
import Chip from "@material-ui/core/Chip"
import TextField from "@material-ui/core/TextField"
import CircularProgress from "@material-ui/core/CircularProgress"
import Autocomplete from "@material-ui/lab/Autocomplete"

import { styles } from "../../../styles/form/select/Select.component.style"

const SelectMultipleTemplate = ({
    classes,
    values,
    label,
    required = false,
    disabled = false,
    input,
    meta,
    loading
}) => {
    const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched
    const [selected, setSelected] = useState([])

    useEffect(() => {
        setSelected(meta.initial ? meta.initial : [])
    }, [meta.initial])

    let intersection = values.filter(
        item1 =>
            !selected.some(item2 => {
                if (item2 !== undefined) {
                    return (
                        item2.label === item1.label &&
                        item2.value === item1.value
                    )
                }
                return null
            })
    )

    return (
        <FormControl className={classes.formControl} error={showError}>
            <Autocomplete
                multiple
                options={intersection}
                getOptionLabel={option => option.label}
                style={{ width: "100%" }}
                disabled={disabled}
                disableCloseOnSelect
                //disableClearable
                filterSelectedOptions
                loading={loading}
                value={selected}
                onChange={(event, option) => {
                    if (!option.includes(undefined)) {
                        setSelected(option)
                        input.onChange(option)
                    }
                }}
                renderTags={(value, getTagProps) => {
                    return value.map((option, index) =>
                        option !== undefined ? (
                            <Chip
                                disabled={disabled}
                                variant="outlined"
                                label={option.label}
                                {...getTagProps({ index })}
                            />
                        ) : null
                    )
                }}
                renderInput={params => (
                    <TextField
                        {...params}
                        label={label}
                        fullWidth
                        required={required}
                        variant='outlined'
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? (
                                        <CircularProgress
                                            color="inherit"
                                            size={20}
                                        />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            )
                        }}
                    />
                )}
            />
        </FormControl>
    )
}

export const SelectMultipleComponent = withStyles(styles)(SelectMultipleTemplate)
