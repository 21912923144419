import String from "../../res/Strings_ITA.res"

export const BloodPressureChart = [
    {
        name: String.bloodPressure.systolic,
        dataKey: "systolic_value",
        color: "#103c38"
    },
    {
        name: String.bloodPressure.diastolic,
        dataKey: "diastolic_value",
        color: "#32b3a6"
    },
    {
        name: String.bloodPressure.meanPressure,
        dataKey: "mean_arterial_pressure_value",
        color: "#6eddd2"
    },
    {
        name: String.bloodPressure.pulsePressure,
        dataKey: "pulse_pressure_value",
        color: "#1f7470"
    },
]


export const BloodPressureList = {
    toolbar: {
        title: String.bloodPressure.list
    },
    head: {
        cells: [
            { label: 'Date', component: 'CellText' },
            { label: '', component: 'CellText' },
        ]
    },
    body: {
        cells: [
            { key: 'blood_pressure_time', component: 'CellCalendar' },
            { key: '', component: 'CellActions', edit: false, detail: true },
        ]
    },
    pagination: {
        rowsPerPageOptions: [5, 10, 15, 25],
        rowsPerPage: 10,
        page: 0
    }
};

export const BloodPressureForm = {
    columns: [
        {
            size: 1,
            title: String.bloodPressure.pressure,
            border: { top: 0 , right: 0, bottom: 0, left: 0 }            
        }
    ],
    body: {
        blood_pressure_time: {
            component: 'CalendarPicker',
            name: 'blood_pressure_time',
            label: String.general.date,
            calendarType: 'DateTimePicker',
            required: true,
            disabled: false,
            column: 0,
            xs: 6,
            validation: {
                message: String.general.alert
            },
        },
        position_value: {
            component: 'Select',
            name: 'position_value',
            label: String.bloodPressure.position,            
            required: true,
            disabled: false,
            column: 0,
            xs: 6,
            validation: {
                message: String.general.alert
            },
        },
        systolic_value: {
            component: 'EditText',
            name: 'systolic_value',
            label: String.bloodPressure.systolicMeasure,
            type: 'number',
            required: true,
            disabled: false,
            column: 0,
            xs: 6,
            validation: {
                message: String.bloodPressure.alertSystolic
            },
        },
        diastolic_value: {
            component: 'EditText',
            name: 'diastolic_value',
            label: String.bloodPressure.diastolicMeasure,
            type: 'number',
            required: true,
            disabled: false,
            column: 0,
            xs: 6,
            validation: {
                message:String.bloodPressure.alertDiastolic
            },
        },
        mean_arterial_pressure_value: {
            component: 'EditText',
            name: 'mean_arterial_pressure_value',
            label: String.bloodPressure.meanPressureMeasure,
            type: 'number',
            required: true,
            disabled: false,
            column: 0,
            xs: 6,
            validation: {
                message: String.bloodPressure.alertMean
            },
        },
        pulse_pressure_value: {
            component: 'EditText',
            name: 'pulse_pressure_value',
            label: String.bloodPressure.pulsePressureMeasure,
            type: 'number',
            required: true,
            disabled: false,
            column: 0,
            xs: 6,
            validation: {
                message:String.bloodPressure.alertPulse
            },
        },
        comment_value: {
            component: 'EditArea',
            name: 'comment_value',
            label: String.bloodPressure.comment,
            required: false,
            disabled: false,
            rows: 4,
            type: 'text',
            column: 0,
            xs: 12,
            validation: {
                message: String.general.alert
            },
        },
    },
    footer: {
        columns: [
            {
                component: 'ButtonSubmit',
                title: String.general.save,
                variant: 'contained',
                color: 'primary',
            },
        ]
    }
};