import { appColor } from "../../config/colors/colors"

export const styles = theme => ({
    root: {
        paddingRight: theme.spacing(2),
        minHeight: theme.spacing(8),
        backgroundColor: appColor.secondary.main
    },
    toolbar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        ...theme.mixins.toolbar
    },
    text: {
        flexGrow: 1,
        color: "#FFF",
        fontWeight: "bold",
        fontSize: "x-large"
    }
})
