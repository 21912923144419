import React from "react"
import { withStyles } from "@material-ui/core/styles"
import TableBody from "@material-ui/core/TableBody"

import { styles } from "../../../styles/table/body/Body.component.style"

const BodyTemplate = ({ children }) => {
    return <TableBody>{children}</TableBody>
}

export const BodyComponent = withStyles(styles)(BodyTemplate)
