import React from "react"
import { withDashboardContext } from "../../hoc"
import BoxComponent from "../../../../materialUi/box/Box.component"

const DashboardFooter = ({ dashboardContext }) => {
    const {
        footer
    } = dashboardContext

    return (
        <BoxComponent
            mt={4}
            mb={2}
            height={20}
            textAlign='center'
            fontStyle="oblique"
            fontWeight="fontWeightLight"
            fontSize= "16px">
            {footer}
        </BoxComponent>
    )
}

export default withDashboardContext(DashboardFooter)
