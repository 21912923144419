import React from "react"
import { withStyles } from "@material-ui/core/styles"
import TableCell from "@material-ui/core/TableCell"
import Moment from "moment"

import { styles } from "../../../styles/table/calendar/CellCalendar.component.style"

const CellCalendarTemplate = ({ label }) => {

    const data = Moment(label).format("YYYY-MM-DD")

    return <TableCell>{data}</TableCell>
}

export const CellCalendarComponent = withStyles(styles)(CellCalendarTemplate)
