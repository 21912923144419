import React from "react"
import { withRouter } from "react-router"
import { useAuthContext } from '../../../contexts/auth/Auth';
import ListContainer from '../../list/container/List.container'
import Request from '../api/Patients.api'
import { selectPatients } from "../selector/Patients.selector";


const PatientsListContainer = ({
    
    children
}) => {

    const { authToken } = useAuthContext()

    return (
        <ListContainer
            request={Request.patientList}
            params={{
                access_token: authToken.access_token,
            }}
            node='PATIENT'
            selector={selectPatients}>
            {children}
        </ListContainer>
    )
}

export default withRouter(PatientsListContainer)