import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"

import { styles } from "../../styles/toolbar/Toolbar.component.style"

const ToolbarComponent = ({
    classes,
    disableGutters,
    leftButtons,
    title,
    rightButtons
}) => {
    return (
        <>
            <Toolbar disableGutters={disableGutters} className={classes.root}>
                {leftButtons}
                <Typography
                    variant="h6"
                    color="inherit"
                    noWrap
                    className={classes.text}
                >
                    {title}
                </Typography>
                {rightButtons}
            </Toolbar>
        </>
    )
}

ToolbarComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    disableGutters: PropTypes.bool,
    leftButtons: PropTypes.node,
    title: PropTypes.string,
    rightButtons: PropTypes.node
}

ToolbarComponent.defaultProps = {
    disableGutters: false,
    leftButtons: null,
    title: "",
    rightButtons: null
}

export default withStyles(styles)(ToolbarComponent)
