import { appColor } from '../../library/config/colors/colors'

export const styles = theme => ({
    root:{
        width: '100%',
        backgroundColor: appColor.white
    },
    title: {
        fontSize: '18px',
        fontWeight: '500',
        textAlign: 'left',
        color: appColor.primary.main, 
    },
    documents: {
        fontSize: '14px',
        fontWeight: '300',
        textAlign: 'left',
        color: appColor.darkGrey,
        marginBottom: theme.spacing(1)
    },
})