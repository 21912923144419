import Actions from '../actions/Detail.actions';

export const InitialState = {
    loading: false,
    error: null,
    data: null
}

export const DetailReducer = (state = InitialState, action) => {
    switch (action.type) {

        case Actions.GET_DETAIL:
            
            return {
                ...state,
                loading: true,
            }

        case Actions.GET_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                data: action.payload
            };

        case Actions.GET_DETAIL_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default:
            return state
    }
}



