export const styles = theme => ({
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        maxHeight:'100%',
        height: '80vh',
        '& tr':{
            height: '70px',
        }
    }
})
