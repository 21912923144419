import React from "react"
import PageTemplate from '../../template/Page.template'
import RiskFactorContent from './RiskFactorContent.page'
import PathologyContent from '../pathology/PathologyContent.page'
const RiskFactorPage = () => {
    return (
        <PageTemplate>
            <RiskFactorContent/>
        </PageTemplate>
    )
}

export default RiskFactorPage