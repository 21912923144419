import { appColor } from '../library/config/colors/colors'

export const styles = theme => ({
    root: {
        padding: theme.spacing(2),
    },    
    title: {
        width: theme.spacing(100),
        height: theme.spacing(3),
        fontSize: '22px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        textAlign: 'left',
        color: appColor.primary.main,
    },  
})