import { ListReducer, InitialState as InitialStateList } from '../../../core/list/reducer/List.reducer'
import { DetailReducer, InitialState as InitialStateDetail } from '../../../core/detail/reducer/Detail.reducer'
import { AddReducer } from '../../../core/add/reducer/Add.reducer'

export const pathologiesReducer = (state = InitialStateList, action) => {
    switch (action.type) {
        case 'GET_PATHOLOGY_LIST':
        case 'GET_PATHOLOGY_LIST_SUCCESS':
        case 'GET_PATHOLOGY_LIST_ERROR':
            return ListReducer(state, action.action)
        default:
            return state
    }
}


export const pathologyReducer = (state = InitialStateDetail, action) => {
    switch (action.type) {
        
        case 'GET_PATHOLOGY_DETAIL':
        case 'GET_PATHOLOGY_DETAIL_SUCCESS':
        case 'GET_PATHOLOGY_DETAIL_ERROR':
            return DetailReducer(state, action.action)

        case 'ADD_PATHOLOGY':
        case 'ADD_PATHOLOGY_SUCCESS':
        case 'ADD_PATHOLOGY_ERROR':
            return AddReducer(state, action.action)

        default:
            return state
    }
}
