import { appColor } from '../../library/config/colors/colors'

export const styles = theme => ({ 
    root: {
        width: '100%',
    },  
    title: {
        width: theme.spacing(100),
        height: theme.spacing(3),
        fontSize: '20px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        textAlign: 'left',
        color: appColor.primary.main,        
    },    
    text: {
        color: appColor.primary.main,
        fontSize:"20px",
        fontWeight: "bold",
    }
})