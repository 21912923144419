import React from "react"
import { withRouter } from "react-router"
import { useAuthContext } from '../../../contexts/auth/Auth';

import DetailContainer from '../../detail/container/Detail.container'
import Request from '../api/Pathology.api'
import { selectPathology } from "../selector/Pathology.selector";


const PathologiesDetailContainer = ({
    documentId,match,
    
    children
}) => {
    
    const patientId = match.params.patient
    const { authToken } = useAuthContext()

    return (
        <DetailContainer
            params={{
                access_token: authToken.access_token,
                patientId: patientId,
                id: documentId
            }}
            request={Request.pathologyDetail}
            selector={selectPathology}
            node='PATHOLOGY'>
            {children}
        </DetailContainer>
    )
}

export default withRouter(PathologiesDetailContainer)