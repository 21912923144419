import React from "react"
import { withListContext } from "../../hoc"
import { BodyComponent } from "../../../../materialUi/table/body/Body.component"
import { RowComponent } from "../../../../materialUi/table/row/Row.component"
import { CellCheckboxComponent } from "../../../../materialUi/table/checkbox/CellCheckbox.component"
import { CellTextComponent } from "../../../../materialUi/table/text/CellText.component"
import { CellActionsComponent } from "../../../../materialUi/table/actions/CellActions.component"
import { CellBooleanComponent } from "../../../../materialUi/table/boolean/CellBoolean.component"
import { CellCalendarComponent } from "../../../../materialUi/table/calendar/CellCalendar.component"
import { CellImageComponent } from "../../../../materialUi/table/image/CellImage.component"

import _split from "lodash/split"

function getValue(key, data) {
    let value = ""
    if (!!data) {
        const split = _split(key, ".", 2)
        if (split.length > 1) {
            value = data[split[0]][split[1]]
        } else {
            value = data[split[0]]
        }
    }

    return value
}

const ListBody = ({
    listContext
}) => {
    const {
        data,
        selected,
        rowsPerPage,
        count,
        page,
        body: {
            cells
        },
        actions: {
            onEditAction,
            onDetailAction,
            onDeleteAction,
            onSelect,
        }
    } = listContext

    //const emptyRows = rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage)

    return (
        <BodyComponent>
            {data.map((item, index) => {
                return (
                    <RowComponent key={index} type='body' onClick={() => onDetailAction(item)}>
                        {cells.map(
                            ({ key, component, align, otherKey, edit, detail, remove }, index) => {
                                switch (component) {
                                    case "CellCheckbox":
                                        return (
                                            <CellCheckboxComponent
                                                key={index}
                                                onChange={() => onSelect(item.id)}
                                                checked={selected.indexOf(item.id) !== -1}
                                            />
                                        )
                                    case "CellText":
                                        const label = `${getValue(key, item)} ${otherKey ? getValue(otherKey, item) : ""}`
                                        return (
                                            <CellTextComponent
                                                type='body'
                                                key={index}
                                                label={label}
                                                align={align}
                                            />
                                        )
                                    case "CellActions":
                                        return (
                                            <CellActionsComponent
                                                key={index}
                                                onDetailAction={() => onDetailAction(item)}
                                                onEditAction={() => onEditAction(item)}
                                                onDeleteAction={() => onDeleteAction(item)}
                                                edit={edit}
                                                detail={detail}
                                                remove={remove}
                                            />
                                        )
                                    case "CellBoolean":
                                        return (
                                            <CellBooleanComponent
                                                key={index}
                                                label={item[key]}
                                            />
                                        )
                                    case "CellCalendar":
                                        return (
                                            <CellCalendarComponent
                                                key={index}
                                                label={item[key]}
                                            />
                                        )
                                    case "CellImage":
                                        return (
                                            <CellImageComponent
                                                key={index}
                                                src={item[key]}
                                            />
                                        )
                                    default:
                                        return null
                                }
                            }
                        )}
                    </RowComponent>
                )
            })}
            {/* {emptyRows > 0 && <CellEmptyComponent emptyRows={emptyRows} />} */}
        </BodyComponent>
    )
}

export default withListContext(ListBody)
