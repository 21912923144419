import React from "react"
import PageTemplate from '../../template/Page.template'
import TherapyContent from './TherapyContent.page'

const TherapyPage = () => {
    return (
        <PageTemplate>
            <TherapyContent/>
        </PageTemplate>
    )
}

export default TherapyPage