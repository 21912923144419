import React from "react"
import { withRouter } from "react-router"
import { useAuthContext } from '../../../contexts/auth/Auth';
import ListContainer from '../../list/container/List.container'
import Request from '../api/AtrialFibrillation.api'
import { selectPathologies } from "../selector/Pathology.selector";

const AtrialFibrillationListContainer = ({
    match,
    children,
    callback,
}) => {

    const patientId = match.params.patient
    const { authToken } = useAuthContext()

    return (
        <ListContainer
            request={Request.AtrialFibrillationList}
            params={{
                access_token: authToken.access_token,
                patientId: patientId,
            }}
            callback={callback}
            node='PATHOLOGY'
            selector={selectPathologies}>
            {children}
        </ListContainer>
    )
}

export default withRouter(AtrialFibrillationListContainer)