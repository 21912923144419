import React from "react"
import { withRouter } from "react-router"
import { useAuthContext } from '../../../contexts/auth/Auth';
import DetailContainer from '../../detail/container/Detail.container'
import Request from '../api/Patients.api'
import { selectPatient } from "../selector/Patients.selector";


const PatientsDetailContainer = ({
    patientId,
    children,
    callback
}) => {

    const { authToken } = useAuthContext()
    
    return (
        <DetailContainer
            params={{
                access_token: authToken.access_token,
                id: patientId
            }}
            request={Request.patientDetail}
            selector={selectPatient}
            node='PATIENT'
            callback={callback}>
            {children}
        </DetailContainer>
    )
}

export default withRouter(PatientsDetailContainer)