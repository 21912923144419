import React from "react"

import ProgressComponent from '../../library/materialUi/progress/Progress.component'
import OverviewChart from '../../components/overview/OverviewChart.component'
import { HeartRateChart } from '../../conf/heartRate/HeartRate.conf'

import HeartRateListContainers from "../../core/heartRate/containers/HeartRateList.containers";


const HeartRateChartPage = ({
    range
}) => {
    return (
        <HeartRateListContainers
            range={range}>
            {({ list }) => (
                <>
                    {list.loading ? <ProgressComponent /> : null}
                    <OverviewChart
                        component="BarChartComponent"
                        values={list.data ? list.data.sort((a, b) => new Date(a.created_at) < new Date(b.created_at) ? -1 : 1) : []}
                        dataKeys={HeartRateChart}
                        XDataKey='created_at' />
                </>
            )}
        </HeartRateListContainers>
    )
}

export default HeartRateChartPage