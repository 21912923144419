import React from "react"
import { withStyles } from "@material-ui/core/styles"
import { compose } from "recompose"
import DialogContent from "@material-ui/core/DialogContent"

import { styles } from "../../../styles/modal/content/ModalContent.component.style"

const ModalContent = ({ children, classes }) => {
    return (
        <DialogContent dividers className={classes.root}>
            {children}
        </DialogContent>
    )
}

const ModalContentWithHoc = compose(withStyles(styles))(ModalContent)

export default ModalContentWithHoc
