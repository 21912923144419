import React from "react"
import moment from 'moment'
import {
    ResponsiveContainer,
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend
} from "recharts"

const dateFormatter = (item) => moment(item).format('YY/MM/DD h:mm A')

const LineChartComponent = ({
    data,
    dataKeys,
    xAxis,
    yAxis = { orientation: "left" }
}) => {


    
    return (
        <ResponsiveContainer height={300}>
            <LineChart data={data}>
                <CartesianGrid
                    strokeDasharray="3 3" />
                <XAxis
                    dataKey={xAxis.dataKey}
                    tickFormatter={(unixTime) => moment(unixTime).format("YYYY-MM-DD")} />
                <YAxis
                    orientation={yAxis.orientation} />
                <Tooltip labelFormatter={dateFormatter} cursor={{stroke:'#8BE4DB', strokeWidth:3}}/>
                <Legend verticalAlign="top" height={60} iconType='rect'/>
                {dataKeys.map(({ dataKey, color, name }) => {
                    return (
                        <Line
                            isAnimationActive={false}
                            type="monotone"
                            dataKey={dataKey}
                            stroke={color}
                            strokeWidth={5}
                            activeDot={{r: 10}}
                            name={name}
                            key={dataKey}
                        />
                    )
                })}
            </LineChart>
        </ResponsiveContainer>
    )
}

export default LineChartComponent
