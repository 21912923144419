import React, { useState, useEffect } from "react"
import { withRouter } from "react-router"

import AddIcon from "@material-ui/icons/Add"

import ProgressComponent from "../../library/materialUi/progress/Progress.component"
import { ButtonComponent } from '../../library/materialUi/button/Button.component'

import String from "../../res/Strings_ITA.res"

import PageActionTemplate from '../../template/PageAction.template'
import useModal from '../../library/hooks/useModal';

import PatientsComponent from '../../components/patients/Patients.component'
import { NewPatientConf } from '../../conf/patients/Patient.conf';
import { Path } from '../../conf/paths/Paths.conf'

import ModalProvider from '../../library/components/modal/provider';
import Modal from '../../library/components/modal/ui';
import ModalToolbar from '../../library/components/modal/ui/toolbar';
import ModalContent from '../../library/components/modal/ui/contents';

import Form from '../../components/form/Form.component';

import PatientsListContainer from '../../core/patients/containers/PatientsList.container'
import PatientsAddContainer from '../../core/patients/containers/PatientsAdd.container'
import PatientsDetailContainer from '../../core/patients/containers/PatientsDetail.container'
import { usePatient } from '../../contexts/patient/Patient'

const HomePage = ({
    history
}) => {

    const { openModal, closeModal, isModalShowing } = useModal();
    const { patientId, setPatient, removePatient } = usePatient();
    const [currentPatient, setCurrentPatient] = useState(null)
    const { body, footer, columns } = NewPatientConf;

    useEffect(() => {
        removePatient()
    }, [])

    return (
        <PageActionTemplate
            button={
                <ButtonComponent
                    type='add'
                    title={String.home.button}
                    action={openModal}
                    icon={<AddIcon />} />
            }>

            {/* DETAIL */}
            {currentPatient ?
                <PatientsDetailContainer
                    patientId={currentPatient.patientId}
                    callback={({ success }) => {
                        if (success) {
                            history.push(`/detail/${currentPatient.patientId}`, { path: Path.detail })
                        }
                    }}>
                    {({ detail }) => (
                        detail.loading ? <ProgressComponent /> : null
                    )}
                </PatientsDetailContainer> : null
            }

            {/* LIST */}
            <PatientsListContainer>
                {({ list }) => (
                    <PatientsComponent
                        loading={list.loading}
                        patients={list.data}
                        deletePatientAction={() => { }}
                        detailPatientAction={(item) => {
                            setPatient(item.patientId)
                            setCurrentPatient(item)
                        }} />
                )}
            </PatientsListContainer>


            {/* ADD */}
            <PatientsAddContainer>
                {({ item, onSubmit }) => (
                    <ModalProvider
                        disableBackdropClick={true}
                        fullWidth={true}
                        maxWidth={'sm'}
                        open={isModalShowing}
                        onModalAction={() => { closeModal() }}
                        title={currentPatient ? String.personalData.update : String.home.button}>
                        <Modal>
                            <ModalToolbar />
                            <ModalContent>
                                <Form
                                    body={body}
                                    footer={footer}
                                    columns={columns}
                                    onSubmit={onSubmit}
                                    display='block'
                                    style={{ padding: '24px' }}
                                    showTitle={true}
                                    loading={item.loading} />
                            </ModalContent>
                        </Modal>
                    </ModalProvider>
                )}
            </PatientsAddContainer>
        </PageActionTemplate>
    )
}

export default withRouter(HomePage)