import React from 'react'
import { withRouter } from "react-router"
import { withStyles } from "@material-ui/core/styles"
import { styles } from "./Breadcrumbs.component.style"
import _last from 'lodash/last'
import _dropRight from 'lodash/dropRight'
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

const BreadcrumbsComponent = ({
    classes,
    paths,
    history
}) => {

    const othersPaths = _dropRight(paths)
    const lastPath = _last(paths)

    return (
        <Breadcrumbs
            aria-label="breadcrumb"
            separator={
                <Typography className={classes.separator}>/</Typography>
            }>
            {othersPaths.map((item, index) =>
                <Typography
                    key={item.path}
                    color="inherit"
                    href={item.path}
                    className={classes.root}
                    onClick={() => {                        
                        const pathIndex = - (othersPaths.length - index)                        
                        history.go(pathIndex, { path: item.path })
                    }}>
                    {item.title}
                </Typography>
            )}
            <Typography className={classes.last}>{lastPath.title}</Typography>
        </Breadcrumbs>
    )
}

export default withRouter(withStyles(styles)(BreadcrumbsComponent))