import React from "react"
import { Field } from "react-final-form"
import { EditAreaComponent } from "../../../../materialUi/form/editArea/EditArea.component"
import { Error } from "../error"

export const EditArea = ({ item, errors, disabled }) => {
    return (
        <>
            <Field
                name={item.name}
                label={item.label}
                required={item.required}
                disabled={disabled}
                type={item.type}
                error={errors ? errors[item.name] : null}
                rows={item.rows}
                rowsMax={item.rowsMax}
                component={EditAreaComponent}
            />
            <Error name={item.name} />
        </>
    )
}
