import Manager from '../../../api/Manager.api'
import Endpoints from '../../../api/Endpoints.api'


const RiskFactorUpsertRequest = ({ values, id }) => {
    var body = new FormData();

    // ID
    if (id) {
        body.set('id', id);
    }

    body.set('smoke_value', values.smoke_value);
    if (values.smoke_alert) {
        body.set('smoke_alert', values.smoke_alert);
    }

    body.set('hypertension_value', values.hypertension_value);
    if (values.hypertension_alert) {
        body.set('hypertension_alert', values.hypertension_alert);
    }

    body.set('dyslipidemia_value', values.dyslipidemia_value);
    if (values.dyslipidemia_alert) {
        body.set('dyslipidemia_alert', values.dyslipidemia_alert);
    }

    body.set('diabetes_value', values.diabetes_value);
    if (values.diabetes_alert) {
        body.set('diabetes_alert', values.diabetes_alert);
    }

    body.set('familiarity_value', values.familiarity_value);
    if (values.familiarity_alert) {
        body.set('familiarity_alert', values.familiarity_alert);
    }

    body.set('chemotherapy_value', values.chemotherapy_value);
    if (values.chemotherapy_alert) {
        body.set('chemotherapy_alert', values.chemotherapy_alert);
    }

    body.set('radiotherapy_value', values.radiotherapy_value);
    if (values.radiotherapy_alert) {
        body.set('radiotherapy_alert', values.radiotherapy_alert);
    }

    body.set('hiv_value', values.hiv_value);
    if (values.hiv_alert) {
        body.set('hiv_alert', values.hiv_alert);
    }

    body.set('renalimpairment_value', values.renalimpairment_value);
    if (values.renalimpairment_alert) {
        body.set('renalimpairment_alert', values.renalimpairment_alert);
    }

    if (values.note_message) {
        body.set('note_message', values.note_message);
    }

    return body
}


const riskFactorList = ({
    access_token,
    patientId,
}) => {
    return Manager.client(access_token).get(Endpoints.riskFactor(patientId))
}

const riskFactorDetail = ({
    access_token,
    patientId,
    id
}) => {
    return Manager.client(access_token).get(`${Endpoints.riskFactor(patientId)}${id}/`)
}

const riskFactorUpdate = ({
    access_token,
    patientId,
    values,
    id
}) => {
    return Manager.client(access_token).update(Endpoints.riskFactor(patientId), RiskFactorUpsertRequest({ id, values }))
}


const riskFactorAdd = ({
    access_token,
    patientId,
    values,    
}) => {
    return Manager.client(access_token).post(Endpoints.riskFactor(patientId), RiskFactorUpsertRequest({ values }))
}



const Request = {
    riskFactorList,
    riskFactorDetail,
    riskFactorAdd,
    riskFactorUpdate,   
}


export default Request