import React from "react"
import { withListContext } from "../../hoc"
import { HeadComponent } from "../../../../materialUi/table/head/Head.component"
import { RowComponent } from "../../../../materialUi/table/row/Row.component"
import { CellCheckboxComponent } from "../../../../materialUi/table/checkbox/CellCheckbox.component"
import { CellTextComponent } from "../../../../materialUi/table/text/CellText.component"

const ListHead = ({ listContext }) => {
    const {
        selected,
        head: { cells },
        actions: { onSelectAll }
    } = listContext

    return (
        <HeadComponent>
            <RowComponent type='header'>
                {cells.map(({ label, component, align }, index) => {
                    switch (component) {
                        case "CellCheckbox":
                            return (
                                <CellCheckboxComponent
                                    key={index}
                                    onChange={event => onSelectAll(event)}
                                    checked={selected.length > 0}
                                />
                            )
                        case "CellText":
                            return (
                                <CellTextComponent
                                    type='header'
                                    key={index}
                                    label={label}
                                    align={align}
                                />
                            )
                        default:
                            return null
                    }
                })}
            </RowComponent>
        </HeadComponent>
    )
}

export default withListContext(ListHead)
