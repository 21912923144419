import String from "../../res/Strings_ITA.res"

export const UpdateHistoryConf = {
    columns: [
        {
            size: 1,
            title: String.history.general,
            border: { top: 0 , right: 0, bottom: 0, left: 0 }            
        },
        {
            size: 1,
            title: String.history.cardiovascular,
            border: { top: 0 , right: 0, bottom: 0, left: 0 }            
        },
        {
            size: 1,
            title: String.history.recent,
            border: { top: 0 , right: 0, bottom: 0, left: 0 }            
        },
        {
            size: 1,
            title: String.history.conclusions,
            border: { top: 0 , right: 0, bottom: 0, left: 0 }            
        },
        {
            size: 1,
            title: String.history.therapy,
            border: { top: 0 , right: 0, bottom: 0, left: 0 }            
        }
    ],    
    body: {
        general_history: {
            component: 'EditArea',
            name: 'general_history',
            label: String.general.note,
            required: false,
            disabled: false,
            rows: 4,
            type: 'text',
            xs: 12,
            column: 0,
            validation: {
                message: String.general.alert
            },
        },
        cardiovascular_history: {
            component: 'EditArea',
            name: 'cardiovascular_history',
            label: String.general.note,
            required: false,
            disabled: false,
            rows: 4,
            type: 'text',
            xs: 12,
            column: 1,
            validation: {
                message: String.general.alert
            },
        },
        recent_history: {
            component: 'EditArea',
            name: 'recent_history',
            label: String.general.note,
            required: false,
            disabled: false,
            rows: 4,
            type: 'text',
            xs: 12,
            column: 2,
            validation: {
                message: String.general.alert
            },
        },
        conclusions: {
            component: 'EditArea',
            name: 'conclusions',
            label: String.general.note,
            required: false,
            disabled: false,
            rows: 4,
            type: 'text',
            xs: 12,
            column: 3,
            validation: {
                message: String.general.alert
            },
        },
        therapy: {
            component: 'EditArea',
            name: 'therapy',
            label: String.general.note,
            required: false,
            disabled: false,
            rows: 4,
            type: 'text',
            xs: 12,
            column: 4,
            validation: {
                message: String.general.alert
            },
        },
               
    },
    footer: {
        columns: [
            {
                component: 'ButtonSubmit',
                title: String.general.save,
                variant: 'contained',
                color: 'primary',
            },
        ]
    }
};