import React from "react"
import { withRouter } from "react-router"
import { useAuthContext } from '../../../contexts/auth/Auth';

import DetailContainer from '../../detail/container/Detail.container'
import { selectHistory } from "../selector/History.selector";
import Request from '../api/History.api'


const HistoryDetailContainer = ({
    documentId,match,
    
    children
}) => {
    
    const patientId = match.params.patient
    const { authToken } = useAuthContext()

    return (
        <DetailContainer
            params={{
                access_token: authToken.access_token,
                patientId: patientId,
                id: documentId
            }}
            request={Request.historyDetail}
            selector={selectHistory}
            node='HISTORY'>
            {children}
        </DetailContainer>
    )
}

export default withRouter(HistoryDetailContainer)