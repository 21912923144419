import React, { useState } from "react"
import _first from 'lodash/first'
import Box from '@material-ui/core/Box';
import Form from '../../components/form/Form.component';

import ProgressComponent from '../../library/materialUi/progress/Progress.component'
import FilterComponent from '../../components/filter/Filter.component'

import { AFibPathologyConf } from '../../conf/pathologies/Pathologies.conf'
import AtrialFibrillationAddContainer from '../../core/pathology/containers/AtrialFibrillationAdd.container'
import AtrialFibrillationListContainer from '../../core/pathology/containers/AtrialFibrillationList.container'
import AtrialFibrillationDetailContainer from '../../core/pathology/containers/AtrialFibrillationDetail.container'

const AtrialFibrillationContentPage = ({
    edit = false,    
}) => {

    const { body, footer, columns } = AFibPathologyConf
    const [documentId, setDocumentId] = useState(null)
    const [document, setDocument] = useState({})

    return (
        <Box width={1}>
            {/* LIST */}
            <AtrialFibrillationListContainer>
                {({ list }) => (
                    <>
                        {list.loading ? <ProgressComponent /> : null}
                        <FilterComponent
                            loading={list.loading}
                            documents={list.data ? list.data : []}
                            documentDefault={list.data && edit === false ? _first(list.data) : null}
                            onSelectDocument={(id) => setDocumentId(id)}
                            edit={edit} />
                    </>
                )}
            </AtrialFibrillationListContainer>

            {/* DETAIL */}
            <AtrialFibrillationDetailContainer
                documentId={documentId}>
                {({ detail }) => {
                    setDocument(detail)
                    return (
                        <Box mt={2}>
                            {detail.loading ? <ProgressComponent /> : null}
                        </Box>
                    )
                }}
            </AtrialFibrillationDetailContainer>         

            {/* ADD */}
            <AtrialFibrillationAddContainer>
                {({ AtrialFibrillationType, onSubmit }) => {

                    body.type.options = AtrialFibrillationType.map(item => ({
                        value: item.id,
                        label: item.name
                    }))

                    return (
                        <Form
                            body={body}
                            columns={columns}
                            footer={edit ? footer : null}
                            onSubmit={onSubmit}
                            display='block'
                            style={{ padding: '12px' }}
                            showTitle={true}
                            initialValues={document.data ? document.data : null}
                            disabled={!edit}
                        />
                    )
                    }}
            </AtrialFibrillationAddContainer>
        </Box>
    )
}

export default AtrialFibrillationContentPage