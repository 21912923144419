import { createAction } from 'redux-actions';

export const SET_ERROR = 'SET_ERROR';
export const REMOVE_ERROR = 'REMOVE_ERROR';
export const SET_SUCCESS = 'SET_SUCCESS';
export const REMOVE_SUCCESS = 'REMOVE_SUCCESS';

export const setError = createAction(SET_ERROR);
export const removeError = createAction(REMOVE_ERROR);
export const setSuccess = createAction(SET_SUCCESS);
export const removeSuccess = createAction(REMOVE_SUCCESS);


export default {
    SET_ERROR,
    REMOVE_ERROR,
    SET_SUCCESS,
    REMOVE_SUCCESS,

    setError,
    removeError,
    setSuccess,
    removeSuccess
}