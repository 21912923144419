import moment from 'moment'
import Manager from '../../../api/Manager.api'
import Endpoints from '../../../api/Endpoints.api'

const PatientUpsertRequest = ({ values, id }) => {
    var body = new FormData();

    // ID
    if (id) {
        body.set('id', id);
    }

    if (values.date_of_birth) {
        body.set('date_of_birth', moment(values.date_of_birth).format('YYYY-MM-DD'));
    }

    if (values.country_of_birth) {
        body.set('country_of_birth', values.country_of_birth);
    }

    if (values.place_of_birth) {
        body.set('place_of_birth', values.place_of_birth);
    }


    if (values.province_of_birth) {
        body.set('province_of_birth', values.province_of_birth);
    }


    if (values.gender) {
        body.set('gender', values.gender);
    }


    if (values.fiscal_code) {
        body.set('fiscal_code', values.fiscal_code);
    }


    if (values.country_residence) {
        body.set('country_residence', values.country_residence);
    }


    if (values.place_residence) {
        body.set('place_residence', values.place_residence);
    }


    if (values.province_residence) {
        body.set('province_residence', values.province_residence);
    }


    if (values.address_residence) {
        body.set('address_residence', values.address_residence);
    }

    if (values.zipcode_residence) {
        body.set('zipcode_residence', values.zipcode_residence);
    }

    if (values.email) {
        body.set('email', values.email);
    }

    if (values.telephone) {
        body.set('telephone', values.telephone);
    }

    if (values.first_name) {
        body.set('first_name', values.first_name);
    }

    if (values.last_name) {
        body.set('last_name', values.last_name);
    }


    return body
}


const patientList = ({
    access_token,
}) => {
    return Manager.client(access_token).get(Endpoints.patients)
}

const patientDetail = ({
    access_token,
    id
}) => {
    return Manager.client(access_token).get(`${Endpoints.patients}${id}/`)
}

const patientAdd = ({
    access_token,
    values
}) => {
    return Manager.client(access_token).post(Endpoints.patients, PatientUpsertRequest({ values }))
}

const patientUpdate = ({
    access_token,
    id,
    values
}) => {
    return Manager.client(access_token).put(`${Endpoints.patients}${id}/`, PatientUpsertRequest({id, values}))
}

const patientDelete = ({
    access_token,
    id,
}) => {
    return Manager.client(access_token).delete(`${Endpoints.patients}${id}/`)
}


const Request = {
    patientList,
    patientDetail,
    patientAdd,
    patientUpdate,
    patientDelete
}


export default Request