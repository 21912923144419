import { useEffect } from "react"
import { connect } from "react-redux"
import DetailActions from "../actions/Detail.actions";

const DetailContainer = ({
    children,
    params,
    request,
    getDetail,
    detail,
    node,
    callback
}) => {


    useEffect(() => {
        if (params.id) {            
            getDetail({
                params: params,
                request: request,
                node: node,
                callback: callback
            })
        }
    }, [params.id])    

    return children? children({ detail: detail }) : null        
}

const mapStateToProps = (state, ownProps) => ({
    detail: ownProps.selector(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    getDetail: (params) => {
        dispatch(DetailActions.getDetail(params))
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(DetailContainer)