import React from "react"
import BreadcrumbsContainer from '../core/breadcrumbs/containers/Breadcrumbs.container'
import Box from '@material-ui/core/Box';

const PageTemplate = ({    
    children,
    button
}) => {

    return (
        <>
            <Box display="flex" flexDirection="row">
                <BreadcrumbsContainer />
                <Box flexGrow={1} />
                <Box mb={4}>
                    {button}
                </Box>
            </Box>
            {children}
        </>
    )
}

export default PageTemplate