import Manager from '../../../api/Manager.api'
import Endpoints from '../../../api/Endpoints.api'
import moment from 'moment'
import { time1, time2, time3, time4 } from '../../../conf/therapy/Therapy.conf';


// Antiplatelet
const AntiplateletTherapyUpsertRequest = ({ values, id }) => {
    var body = new FormData();

    // ID
    if (id) {
        body.set('id', id);
    }

    if (values.start) {
        body.set('start', moment(values.start).format('YYYY-MM-DD'));
    }

    if (values.end) {
        body.set('end', moment(values.end).format('YYYY-MM-DD'));
    }

    if (values.drug) {
        body.set('drug', values.drug);
    }

    if (values.dosage) {
        body.set('dosage', values.dosage);
    }

    if (values.times) {
        body.set('times', values.times);
    }

    if (values.has_drug_taken_before_or_after_meal) {
        body.set('has_drug_taken_before_or_after_meal', values.has_drug_taken_before_or_after_meal);
    }

    let times = []
    if (values.time1 === true) {
        times.push(time1.label)
    }
    if (values.time2 === true) {
        times.push(time2.label)
    }
    if (values.time3 === true) {
        times.push(time3.label)
    }
    if (values.time4 === true) {
        times.push(time4.label)
    }
    body.set('times', times);


    body.set('monday', values.monday);
    body.set('tuesday', values.tuesday);
    body.set('wednesday', values.wednesday);
    body.set('thursday', values.thursday);
    body.set('friday', values.friday);
    body.set('saturday', values.saturday);
    body.set('sunday', values.sunday);

    return body
}

const antiplateletTherapyList = ({
    access_token,
    patientId,
}) => {
    return Manager.client(access_token).get(Endpoints.therapyPlan(patientId))
}

const antiplateletTherapyDetail = ({
    access_token,
    patientId,
    id
}) => {
    return Manager.client(access_token).get(`${Endpoints.therapyPlan(patientId)}${id}/`)
}

const antiplateletTherapyAdd = ({
    access_token,
    patientId,
    values,
}) => {
    return Manager.client(access_token).post(Endpoints.therapyAntiPlatelet(patientId), AntiplateletTherapyUpsertRequest({ values }))
}

const antiplateletTherapyUpdate = ({
    access_token,
    patientId,
    values,
    id
}) => {
    return Manager.client(access_token).update(Endpoints.therapyPlan(patientId), AntiplateletTherapyUpsertRequest({ id, values }))
}

const Request = {
    antiplateletTherapyList,
    antiplateletTherapyDetail,
    antiplateletTherapyAdd,
    antiplateletTherapyUpdate
}

export default Request


