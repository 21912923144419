import React from "react"
import { DateTimePicker, DatePicker , KeyboardDatePicker, TimePicker} from "@material-ui/pickers"
import FormControl from "@material-ui/core/FormControl"
import { withStyles } from "@material-ui/core/styles"

import { styles } from "../../../styles/form/calendar/Calendar.component.style"

const calendar_type = {
    DatePicker,
    DateTimePicker,
    KeyboardDatePicker,
    TimePicker
}

export const CalendarPickerTemplate = ({
    classes,
    title,
    calendarType,
    input: { name, onChange, value, ...restInput },
    meta,
    disabled = false,
    disableFuture = true,
    ...rest
}) => {
    const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched
    const TypeCalendar = calendar_type[calendarType]

    return (
        <FormControl className={classes.formControl} error={showError}>
            <TypeCalendar
                {...rest}                
                disabled={disabled}
                name={name}
                error={showError}
                inputProps={restInput}
                onChange={onChange}
                value={value === "" ? null : value}
                ampm={false}
                inputVariant={'outlined'}
                format={calendarType === 'TimePicker'?  "hh:mm" : "YYYY-MM-DD"}
                disableFuture={disableFuture}                
            />
        </FormControl>
    )
}

export const CalendarPickerComponent = withStyles(styles)(
    CalendarPickerTemplate
)
