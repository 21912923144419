import React from 'react';
import DashboardContainer from '../../core/dashboard/containers/Dashboard.container';

const DashboardPage = ({
	children
}) => {
	return (
			<DashboardContainer>
				{children}
			</DashboardContainer>
	);
};

export default DashboardPage;
