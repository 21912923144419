import React from "react"
import { Field } from "react-final-form"
import MomentUtils from "@date-io/moment"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"

import { CalendarPickerComponent } from "../../../../materialUi/form/calendar/Calendar.component"
import { Error } from "../error"
import "moment/locale/it"

export const CalendarPicker = ({
    item,
    disabled,
    errors,
    calendarType = "DateTimePicker",
    disableFuture = true
}) => {
    return (
        <MuiPickersUtilsProvider locale="it" utils={MomentUtils}>
            <Field
                name={item.name}
                label={item.label}
                required={item.required}
                // disabled={item.disabled}
                error={errors ? errors[item.name] : null}
                calendarType={ item.calendarType ? item.calendarType : calendarType}
                disabled={disabled}
                disableFuture={disableFuture}
                component={CalendarPickerComponent}
            />
            <Error name={item.name} />
        </MuiPickersUtilsProvider>
    )
}
