import React from 'react'
import Box from '@material-ui/core/Box';
import Divider from '../../library/materialUi/divider/Divider.component'

const PatientData = ({
    patient,
}) => {
    return (
        <Box p={2}>
            {patient ?
                <>
                    <Box textAlign='left' fontWeight="fontWeightBold" fontSize={20} mb={2}>{`ID #${patient.patientId}`}</Box>
                    <Divider />                    
                    <Box textAlign='left' fontWeight="fontWeightBold" mt={2} fontSize={18}>{`${patient.first_name} ${patient.last_name}`}</Box>
                    <Box textAlign='left' fontWeight="fontWeightRegular" mb={2} fontSize={14}>{`${patient.date_of_birth} - ${patient.gender}`}</Box>
                    <Box textAlign='left' fontWeight="fontWeightBold" mt={2} fontSize={18}>{`${patient.fiscal_code}`}</Box>
                    <Box textAlign='left' fontWeight="fontWeightBold" mt={2} fontSize={18}>{`${patient.address_residence}`}</Box>
                    <Box textAlign='left' fontWeight="fontWeightBold" mt={2} fontSize={18}>{`${patient.place_residence} (${patient.province_residence})`}</Box>                    
                    <Box textAlign='left' fontWeight="fontWeightBold" mt={2} >{`+39 ${patient.telephone}`}</Box>
                </>: null}
        </Box>
    )
}

export default PatientData
