import { MultipleListReducer, InitialState } from '../../../core/multiple/reducer/Multiple.reducer'

export const selectsReducer = (state = InitialState, action) => {
    switch (action.type) {
        case 'GET_SELECTS_MULTIPLE_LIST':
        case 'GET_SELECTS_MULTIPLE_LIST_SUCCESS':
        case 'GET_SELECTS_MULTIPLE_LIST_ERROR':
            return MultipleListReducer(state, action.action)
        default:
            return state
    }
}