import React from "react"
import Divider from "@material-ui/core/Divider"
import { withStyles } from "@material-ui/core/styles"

import { styles } from "../../styles/divider/Divider.component.style"

const DividerComponent = ({ classes }) => {
    return <Divider className={classes.root}/>
}

export default withStyles(styles)(DividerComponent)
