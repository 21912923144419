import React from "react"
import { withStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"

import { styles } from "../../styles/button/Button.component.style"

const ButtonTemplate = ({
    classes,
    color = "primary",
    title = "",
    action = () => {},
    variant = "contained",
    disabled = false,
    type = 'button',
    icon = null
}) => {
    return (
        <Button
            className={classes[type]}
            color={color}
            onClick={action}
            variant={variant}
            disabled={disabled}
            startIcon={icon}
        >
            {title}
        </Button>
    )
}

export const ButtonComponent = withStyles(styles)(ButtonTemplate)
