import React from "react"
import { useAuthContext } from '../../contexts/auth/Auth';
import { withRouter } from "react-router";

import Box from '@material-ui/core/Box'

import { UpdatePatientConf } from '../../conf/patients/Patient.conf';
import String from "../../res/Strings_ITA.res"
import PageTemplate from '../../template/Page.template'
import ContentColumnsTemplate from '../../template/ContentColumns.template'

import PatientData from '../../components/patients/PatientData.component';
import ProgressComponent from '../../library/materialUi/progress/Progress.component'
import Form from '../../components/form/Form.component';

import UpdateContainer from '../../core/update/container/Update.container'
import { selectPatient } from "../../core/patients/selector/Patients.selector";
import Request from '../../core/patients/api/Patients.api'


const PersonalDataPage = ({
    match,    
}) => {

    const { body, footer, columns } = UpdatePatientConf
    const patientId = match.params.patient
    const { authToken } = useAuthContext()
    
    return (
        <PageTemplate>
            <UpdateContainer
                params={{
                    access_token: authToken.access_token,
                    id: patientId
                }}
                request={Request.patientUpdate}
                selector={selectPatient}
                node='PATIENT'>
                {({ item, onSubmit }) => (
                    <Box display='flex' bgcolor='white' boxShadow={1}>

                        {/* LEFT SIDE */}
                        <ContentColumnsTemplate
                            titles={[{
                                title: String.personalData.resume,
                                size: 1,
                                border: { top: 0, right: 0, bottom: 1, left: 0 }
                            }]}
                            column={{
                                size: 1 / 5
                            }}
                            border={{ top: 0, right: 1, bottom: 0, left: 0 }} >
                            {item.loading ? <ProgressComponent /> : null}
                            <PatientData patient={item.data ? item.data : null} />
                        </ContentColumnsTemplate>

                        {/* RIGHT SIDE */}
                        <ContentColumnsTemplate
                            titles={[{
                                size: 1 / 2,
                                title: String.personalData.title,
                                border: { top: 0, right: 1, bottom: 1, left: 0 }
                            }, {
                                size: 1 / 2,
                                title: String.personalData.addressTitle,
                                border: { top: 0, right: 0, bottom: 1, left: 0 }
                            }]}
                            column={{
                                size: 4 / 5
                            }}
                            border={{ top: 0, right: 0, bottom: 0, left: 0 }} >

                            <Form
                                body={body}
                                footer={footer}
                                columns={columns}
                                onSubmit={onSubmit}
                                display='flex'
                                style={{ padding: '0px' }}
                                showTitle={false}
                                initialValues={item.data ? item.data : {}}
                                loading={item.loading}
                            />
                        </ContentColumnsTemplate>
                    </Box>
                )}
            </UpdateContainer>

        </PageTemplate>
    )
}

export default withRouter(PersonalDataPage)