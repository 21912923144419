import React from "react"
import { Route, Switch } from "react-router-dom"

import PrivateRoute from '../route/private.route';
import { Paths } from '../conf/paths/Paths.conf'
import LoginPage from "../pages/login/Login.page"
import DashboardPage from "../pages/dashboard/Dashboard.page"
import HomePage from "../pages/homepage/HomePage.page"
import DetailPage from "../pages/detail/Detail.page"
import OverviewPage from "../pages/overview/Overview.page"
import PersonalPage from "../pages/personal/PersonalData.page"
import RiskFactorPage from "../pages/risk/RiskFactor.page"
import NewVisitPage from "../pages/visit/NewVisit.page"
import HistoryPage from "../pages/history/History.page"
import StatusPage from "../pages/status/Status.page"
import TherapyPage from "../pages/therapy/Therapy.page"
const Router = props => {

    return (
        <Switch>
            <Route exact path="/" component={LoginPage} />
            <Route path="/login" component={LoginPage} />
            <DashboardPage>
                <PrivateRoute path={Paths.home} component={HomePage} />
                <PrivateRoute path={Paths.detail} component={DetailPage} />
                <PrivateRoute path={Paths.overview} component={OverviewPage} />
                <PrivateRoute path={Paths.personal} component={PersonalPage} />
                <PrivateRoute path={Paths.risk} component={RiskFactorPage} />
                <PrivateRoute path={Paths.visit} component={NewVisitPage} />
                <PrivateRoute path={Paths.history} component={HistoryPage} />
                <PrivateRoute path={Paths.status} component={StatusPage} />
                <PrivateRoute path={Paths.therapy} component={TherapyPage} />
            </DashboardPage>
        </Switch>
    )
}

export default Router