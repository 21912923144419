import React from "react"
import { withModalContext } from "../hoc"
import ModalComponent from "../../../materialUi/modal/Modal.component"

const Modal = ({
    children,
    modalContext: {
        disableBackdropClick,
        fullWidth,
        maxWidth,
        fullScreen,
        open,
        actions: { onModalAction }
    }
}) => {
    return (
        <ModalComponent
            disableBackdropClick={disableBackdropClick}
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            fullScreen={fullScreen}
            open={open}
            onModalAction={onModalAction}
        >
            {children}
        </ModalComponent>
    )
}

export default withModalContext(Modal)
