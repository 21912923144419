import { put, call, takeEvery } from 'redux-saga/effects';
import Actions from '../actions/Add.actions';
import ActionsList from '../../list/actions/List.actions';
import MessagesActions from '../../messages/actions/Messages.actions';
import Strings from '../../../res/Strings_ITA.res';

function* addItemSaga(action) {

    const { params, request, node, refresh, callback = () => { } } = action.payload;

    // Set Loading State
    yield put({
        type: `ADD_${node}`,
        action: action
    })

    try {
        let add = yield call(request, params);

        // Save Values
        yield put({
            type: `ADD_${node}_SUCCESS`,
            action: Actions.addItemSuccess(add.data.data)
        })
        yield put(MessagesActions.setSuccess(Strings.message.successAdd))
        callback({ success: true, item: add.data })


        if (refresh) {
            yield put(ActionsList.getList({
                params: refresh.params,
                request: refresh.request,
                node: refresh.node
            }))
        }

    } catch (e) {
        // Save Error
        yield put({
            type: `ADD_${node}_ERROR`,
            action: Actions.addItemError(e)
        })
        yield put(MessagesActions.setError(e))
        callback({ success: false, error: e })
    }
}



export function* addWatcherSaga() {
    yield takeEvery(Actions.addItem, addItemSaga);
}