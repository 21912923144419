import React, { useEffect } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Router from './router/root.router';
import MessagesActions from './core/messages/actions/Messages.actions';
import MultipleActions from './core/multiple/actions/Multiple.actions';
import DetailActions from './core/detail/actions/Detail.actions';

import { selectMessages } from './core/messages/selector/Messages.selector'
import Message from './components/message/Message.component'
import { usePatient } from './contexts/patient/Patient'
import { AuthContext, useAuth } from './contexts/auth/Auth';

import SelectsRequest from './core/selects/api/Selects.api'
import PatientRequest from './core/patients/api/Patients.api'


const App = ({
	message,
	removeError,
	removeSuccess,
	getSelects,
	getPatientDetail,
}) => {

	const { authToken, setToken, removeToken } = useAuth()
	const { getPatientId } = usePatient();
	const patientId = getPatientId()


	useEffect(() => {
		if (authToken) {
			getSelects({
				params: {
					access_token: authToken.access_token
				},
				requests: SelectsRequest.all,
				node: 'SELECTS',
				callback: () => { }
			})
		}
	}, [authToken])

	useEffect(() => {		
		if (authToken && patientId) {
			getPatientDetail({
				params: {
					access_token: authToken.access_token,
					id: patientId
				},
				request: PatientRequest.patientDetail,
				node: 'PATIENT'
			})
		}
	}, [])

	return (
		<AuthContext.Provider value={{ authToken, setToken, removeToken }}>
			{message.error !== null ? <Message onCloseAlert={() => removeError()} severity='error' message={message.error.message} /> : null}
			{message.success !== null ? <Message onCloseAlert={() => removeSuccess()} severity='success' message={message.success} /> : null}			
			<Router />
		</AuthContext.Provider>
	);
}

const mapStateToProps = state => ({
	message: selectMessages(state)
});

const mapDispatchToProps = dispatch => ({
	removeError: () => {
		dispatch(MessagesActions.removeError())
	},
	removeSuccess: () => {
		dispatch(MessagesActions.removeSuccess())
	},
	getSelects: (params) => {
		dispatch(MultipleActions.getMultipleList(params))
	},
	getPatientDetail: (params) => {
		dispatch(DetailActions.getDetail(params))
	}
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));