import React from "react"
import { Field } from "react-final-form"
import { EditTextComponent } from "../../../../materialUi/form/editText/EditText.component"
import { Error } from "../error"

export const EditText = ({ item, disabled, errors }) => {
    return (
        <>
            <Field
                required={item.required}
                name={item.name}
                type={item.type}
                label={item.label}
                disabled={disabled}
                error={errors ? errors[item.name] : null}
                component={EditTextComponent}
            />
            <Error name={item.name} />
        </>
    )
}
