import { all } from "redux-saga/effects"

import { listWatcherSaga } from '../core/list/saga/List.saga'
import { detailWatcherSaga } from '../core/detail/saga/Detail.saga'
import { updateWatcherSaga } from '../core/update/saga/Update.saga'
import { addWatcherSaga } from '../core/add/saga/Add.saga'
import { authenticationWatcherSaga } from '../core/authentication/saga/Authentication.saga'
import { multipleWatcherSaga } from '../core/multiple/saga/Multiple.saga'

export function* rootSaga() {
    yield all([
        listWatcherSaga(),
        detailWatcherSaga(),
        updateWatcherSaga(),
        addWatcherSaga(),
        authenticationWatcherSaga(),
        multipleWatcherSaga()
    ])
}
