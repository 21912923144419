import { createAction } from 'redux-actions';

const ACCOUNT_SIGNIN = 'ACCOUNT_SIGNIN';
const ACCOUNT_SIGNIN_SUCCESS = 'ACCOUNT_SIGNIN_SUCCESS';
const ACCOUNT_SIGNIN_FAILURE = 'ACCOUNT_SIGNIN_FAILURE';
const ACCOUNT_SIGNOUT = 'ACCOUNT_SIGNOUT';
const ACCOUNT_SIGNOUT_SUCCESS = 'ACCOUNT_SIGNOUT_SUCCESS';
const ACCOUNT_SIGNOUT_FAILURE = 'ACCOUNT_SIGNOUT_FAILURE';
const ACCOUNT_CHECK_TOKEN = 'ACCOUNT_CHECK_TOKEN';


const accountSignInAction = createAction(ACCOUNT_SIGNIN);
const accountSignInSuccessAction = createAction(ACCOUNT_SIGNIN_SUCCESS);
const accountSignInFailureAction = createAction(ACCOUNT_SIGNIN_FAILURE);
const accountSignOutAction = createAction(ACCOUNT_SIGNOUT);
const accountSignOutSuccessAction = createAction(ACCOUNT_SIGNOUT_SUCCESS);
const accountSignOutFailureAction = createAction(ACCOUNT_SIGNOUT_FAILURE);
const accountCheckTokenAction = createAction(ACCOUNT_CHECK_TOKEN);

export default {

    ACCOUNT_SIGNIN,
    ACCOUNT_SIGNIN_SUCCESS,
    ACCOUNT_SIGNIN_FAILURE,
    ACCOUNT_SIGNOUT,
    ACCOUNT_SIGNOUT_SUCCESS,
    ACCOUNT_SIGNOUT_FAILURE,
    ACCOUNT_CHECK_TOKEN,

    accountSignInAction,
    accountSignInSuccessAction,
    accountSignInFailureAction,
    accountSignOutAction,
    accountSignOutSuccessAction,
    accountSignOutFailureAction,
    accountCheckTokenAction
}