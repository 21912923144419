import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import GitInfo from 'react-git-info/macro';
import moment from 'moment'
import DashboardProvider from '../../library/components/dashboard/provider';
import DashboardToolbar from '../../library/components/dashboard/ui/toolbar';
import DashboardContent from '../../library/components/dashboard/ui/content';
import DashboardFooter from '../../library/components/dashboard/ui/footer';
import { withStyles } from "@material-ui/core/styles"
import { styles } from "./Dashboard.component.style"
import { AppContext } from '../../contexts/app/App'


const DashboardPage = ({
    classes,
    children,
    signOutAction,
    history
}) => {

    const mainDiv = useRef();
    const gitInfo = GitInfo();

    useEffect(() => {
        mainDiv.current && mainDiv.current.scrollTo(0, 0)
    }, [history.location])

    return (
        <AppContext.Provider value={{ mainDiv }}>
            <DashboardProvider
                title={'WebPlatform'}
                handleLogout={signOutAction}
                footer={`UPDATE: ${moment(gitInfo.commit.date).format('YYYY-MM-DD')} HASH: ${gitInfo.commit.hash.substring(0, 6)}`}>
                <DashboardToolbar />
                <main className={classes.content} ref={mainDiv} >
                    <div className={classes.appBarSpacer} />
                    <DashboardContent>
                        {children}
                        <div className={classes.footer}>
                            <DashboardFooter />
                        </div>
                    </DashboardContent>
                </main>
            </DashboardProvider>
        </AppContext.Provider>
    )
}

export default withStyles(styles)(withRouter(connect(null, null)(DashboardPage)))