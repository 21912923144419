import React from "react"
import { withStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import { styles } from "../../styles/container/Container.component.style"

const ContainerTemplate = ({
    classes,
    children
}) => {

    return (
        <Container maxWidth="lg" className={classes.container}>
            {children}
        </Container>
    )
}

export const ContainerComponent = withStyles(styles)(ContainerTemplate)
