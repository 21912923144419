import { createAction } from 'redux-actions';

export const UPDATE_ITEM = 'UPDATE_ITEM';
export const UPDATE_ITEM_SUCCESS = 'UPDATE_ITEM_SUCCESS';
export const UPDATE_ITEM_ERROR = 'UPDATE_ITEM_ERROR';

export const updateItem = createAction(UPDATE_ITEM)
export const updateItemSuccess = createAction(UPDATE_ITEM_SUCCESS)
export const updateItemError = createAction(UPDATE_ITEM_ERROR)


export default {
    UPDATE_ITEM,
    UPDATE_ITEM_SUCCESS,
    UPDATE_ITEM_ERROR,        
    
    updateItem,
    updateItemSuccess,
    updateItemError,        
}