import actions from '../actions/Authentication.actions'

const defaultState = {
	loading: false,
	error: null,	
};

const authenticationReducer = (state = defaultState, action) => {

	switch (action.type) {

		case actions.ACCOUNT_SIGNIN:
			return {
				...state,
				loading: true
			};

		case actions.ACCOUNT_SIGNIN_SUCCESS:
			return {
				...state,
				loading: false,
				error: null,				
			};

		case actions.ACCOUNT_SIGNIN_FAILURE:

			return {
				...state,
				loading: false,
				error: action.payload,				
			};

		case actions.ACCOUNT_SIGNOUT_SUCCESS:

			return {
				...state,
				loading: false,
				error: null,				
			};

		default:
			return state;
	}
};

export default authenticationReducer;