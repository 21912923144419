import React, { useEffect } from 'react'
import moment from 'moment'
import { withStyles } from "@material-ui/core/styles"
import { styles } from "./Filter.component.style"
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Box from '@material-ui/core/Box';
import Strings from '../../res/Strings_ITA.res'

const FilterComponent = ({
    classes,
    documents,
    documentDefault,
    onSelectDocument,
    loading,
    size = 4,
    edit= false
}) => {

    useEffect(() => {
        if (documents.length > 0 && !edit) {
            onSelectDocument(documents[0].documentId)
        }
    }, [documents])

    return (
        <ExpansionPanel elevation={0}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Box className={classes.title}> {Strings.filter.title} </Box>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Box className={classes.root}>
                    <Grid container spacing={3}>
                        <Grid item xs={size}>
                            <Box className={classes.documents}> {Strings.filter.documents} </Box>
                            {loading ? null :
                                <Autocomplete
                                    options={documents}
                                    getOptionLabel={(option) => moment(option.created_at).format('YYYY/MM/DD')}
                                    onChange={(event, option) => {
                                        if (option !== null) {
                                            onSelectDocument(option.documentId)
                                        } else {
                                            onSelectDocument(null)
                                        }
                                    }}
                                    defaultValue={documentDefault}
                                    renderInput={(params) => <TextField {...params} label="" variant="outlined" />}
                                />}
                        </Grid>
                    </Grid>
                </Box>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    )
}

export default withStyles(styles)(FilterComponent)