import React from "react"
import PageTemplate from '../../template/Page.template'
import StatusContent from './StatusContent.page'

const StatusPage = () => {

    return (
        <PageTemplate>
            <StatusContent />
        </PageTemplate>
    )
}

export default StatusPage