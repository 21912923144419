import { appColor } from '../library/config/colors/colors'

export const styles = theme => ({
    root: {
        borderColor: appColor.primary.main
    },
    content: {
        borderColor: appColor.primary.main
    },
    title:{
        borderColor: appColor.primary.main
    },   
    text: {
        fontSize: '22px',
        fontWeight: 'bold',
        textAlign: 'left',
        color: appColor.primary.main,
        borderColor: appColor.primary.main
    },     
})