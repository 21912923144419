import React from "react"
import { withRouter } from "react-router"
import { useAuthContext } from '../../../contexts/auth/Auth';
import AddContainer from '../../add/container/Add.container'
import Request from '../api/OtherTherapy.api'
import { selectDrugs } from "../selector/Therapy.selector";

const OtherTherapyAddContainer = ({
    match,
    children,
    onCreateDocument
}) => {

    const patientId = match.params.patient
    const { authToken } = useAuthContext()

    return (
        <AddContainer
            request={Request.otherTherapyAdd}
            params={{
                access_token: authToken.access_token,
                patientId: patientId
            }}
            node='DRUG'
            selector={selectDrugs}
            callback={(response) => {
                if(response.success){
                    onCreateDocument('other_drugs',response.item)
                }
            }}>
            {({ item, onSubmit }) => (
                children({ item, onSubmit})
            )}
        </AddContainer>
    )
}

export default withRouter(OtherTherapyAddContainer)