import React from "react"
import { withListContext } from "../../hoc"

import AddIcon from "@material-ui/icons/Add"
import DeleteIcon from "@material-ui/icons/Delete"

import BoxComponent from "../../../../materialUi/box/Box.component"
import { ButtonComponent } from "../../../../materialUi/button/Button.component"
import { appColor } from "../../../../config/colors/colors"

import String from "../../../../../res/Strings_ITA.res"

const ListHeader = ({ listContext }) => {
    const {
        toolbar,
        selected,
        actions: {
            onDeleteAction,
            onAddAction,
        }
    } = listContext

    

    return (
        <BoxComponent width="100%">
            <BoxComponent
                width="100%"
                display="flex">
                <BoxComponent
                    width="100%"
                    textAlign="left"
                    fontWeight="fontWeightBold"
                    p={2}
                    fontSize={18}
                    color={appColor.primary.main}>
                    {toolbar}
                </BoxComponent>
                {selected.length > 0 ?
                    <BoxComponent>
                        <ButtonComponent
                            type='delete'
                            title={String.general.delete}
                            action={onDeleteAction}
                            icon={<DeleteIcon />} />
                    </BoxComponent> :
                    <BoxComponent flexShrink={1}>
                        <ButtonComponent
                            type='add'
                            title={String.general.new}
                            action={onAddAction}
                            icon={<AddIcon />} />
                    </BoxComponent>}
            </BoxComponent>
        </BoxComponent>
    )
}

export default withListContext(ListHeader)
