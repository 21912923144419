import Strings from "../../res/Strings_ITA.res"
export const Steps = [
    {
        title: Strings.visit.riskFactors,        
    },
    {
        title: Strings.visit.bloodPressure,        
    },
    {
        title: Strings.visit.history,        
    },
    {
        title: Strings.visit.status,        
    },
    {
        title: Strings.visit.therapy,
    }
]
