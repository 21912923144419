import Actions from '../actions/Add.actions';

export const InitialState = {
    loading: false,
    error: null,
    data: null
}

export const AddReducer = (state = InitialState, action) => {
    switch (action.type) {

        case Actions.ADD_ITEM:
            
            return {
                ...state,
                loading: true,
            }

        case Actions.ADD_ITEM_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                data: action.payload
            };

        case Actions.ADD_ITEM_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default:
            return state
    }
}



