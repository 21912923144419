import React from "react"
import { withStyles } from "@material-ui/core/styles"
import TableCell from "@material-ui/core/TableCell"

import { styles } from "../../../styles/table/text/CellText.component.style"

const CellTextTemplate = ({
    classes,
    label,
    type = 'body',
    align
}) => {
    return (
        <TableCell
            align={align} 
            className={classes[type]}>
            {label}
        </TableCell>
    )
}

export const CellTextComponent = withStyles(styles)(CellTextTemplate)
