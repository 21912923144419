import { ListReducer, InitialState as InitialStateList } from '../../../core/list/reducer/List.reducer'
import { DetailReducer, InitialState as InitialStateDetail } from '../../../core/detail/reducer/Detail.reducer'
import { AddReducer } from '../../../core/add/reducer/Add.reducer'

export const weightsReducer = (state = InitialStateList, action) => {
    switch (action.type) {
        case 'GET_WEIGHT_LIST':
        case 'GET_WEIGHT_LIST_SUCCESS':
        case 'GET_WEIGHT_LIST_ERROR':
            return ListReducer(state, action.action)
        default:
            return state
    }
}

export const weightReducer = (state = InitialStateDetail, action) => {
    switch (action.type) {
        case 'GET_WEIGHT_DETAIL':
        case 'GET_WEIGHT_DETAIL_SUCCESS':
        case 'GET_WEIGHT_DETAIL_ERROR':
            return DetailReducer(state, action.action)

        case 'ADD_WEIGHT':
        case 'ADD_WEIGHT_SUCCESS':
        case 'ADD_WEIGHT_ERROR':
            return AddReducer(state, action.action)

        default:
            return state
    }
}

