import React, { useEffect } from 'react'
import { withStyles } from "@material-ui/core/styles";
import { styles } from "./NewVisit.page.style";
import Box from '@material-ui/core/Box'
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import { Steps } from '../../conf/visit/NewVisit.conf'
import NewVisitFooterComponent from '../../components/visit/NewVisitFooter.component'
import SaveVisitContentPage from './SaveVisitContent.page'
import RiskFactorContentPage from '../risk/RiskFactorContent.page'
import BloodPressureContentPage from '../bloodPressure/BloodPressureContent.page'
import HistoryContentPage from '../history/HistoryContent.page'
import StatusContentPage from '../status/StatusContent.page'
import TherapyContentPage from '../therapy/TherapyContent.page'
import PageActionTemplate from '../../template/PageAction.template'

import { useAppContext } from '../../contexts/app/App'

const renderStep = (step) => {

    switch (step) {
        case 0:            
            return <RiskFactorContentPage edit={true} />
        case 1:
            return <BloodPressureContentPage edit={true} />
        case 2:
            return <HistoryContentPage edit={true} />
        case 3:
            return <StatusContentPage edit={true} />
        case 4:
            return <TherapyContentPage edit={true} />
        case 5:
            return <SaveVisitContentPage />
        default:
            return null
    }
}

const NewVisitPage = ({
    classes
}) => {

    const [activeStep, setActiveStep] = React.useState(0);
    const { mainDiv } = useAppContext()
    
    useEffect(() => {
        
        mainDiv.current && mainDiv.current.scrollTo(0, 0)
    }, [activeStep])


    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleEnd = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    return (
        <PageActionTemplate button={null}>
            <Box mt={2}>
                <Stepper nonLinear activeStep={activeStep}>
                    {Steps.map((step, index) => {
                        return (
                            <Step key={index}>
                                <StepButton onClick={handleStep(index)}>
                                    {step.title}
                                </StepButton>
                            </Step>
                        );
                    })}
                </Stepper>
                <Box mt={2}>
                    {renderStep(activeStep)}
                </Box>
                <NewVisitFooterComponent
                    onNextAction={handleNext}
                    onBackAction={handleBack}
                    onEndAction={handleEnd}
                    stepperCount={Steps.length}
                    stepperCurrent={activeStep} />
            </Box>
        </PageActionTemplate>
    )
}

export default withStyles(styles)(NewVisitPage)