import React from "react"
import moment from 'moment'
import {
    ResponsiveContainer,
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend
} from "recharts"

const dateFormatter = (item) => moment(item).format('YY/MM/DD h:mm A')

const BarChartComponent = ({
    data,
    dataKeys,
    xAxis,
    yAxis = { orientation: "left" }
}) => {
    return (
        <ResponsiveContainer height={300}>
            <BarChart data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                    dataKey={xAxis.dataKey}
                    tickFormatter={(unixTime) => moment(unixTime).format("YYYY-MM-DD")} />
                <YAxis
                    orientation={yAxis.orientation}/>
                <Tooltip labelFormatter={dateFormatter} cursor={{fill: '#EAFAF9'}}/>
                <Legend align="center" />
                {dataKeys.map(({ dataKey, color, name }) => {
                    return (
                        <Bar
                            barSize= {20}
                            dataKey={dataKey}
                            fill={color}
                            name={name}
                            background={{ fill: '#EAFAF9' }}
                            key={dataKey}
                        />
                    )
                })}
            </BarChart>
        </ResponsiveContainer>
    )
}

export default BarChartComponent
