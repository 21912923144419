import React, { useState } from "react"

import _first from 'lodash/first'
import Box from '@material-ui/core/Box';


import FilterComponent from '../../components/filter/Filter.component'
import ContentTemplate from '../../template/Content.template'
import Form from '../../components/form/Form.component';
import ProgressComponent from '../../library/materialUi/progress/Progress.component'

import { UpdateHistoryConf } from '../../conf/history/History.conf';
import String from "../../res/Strings_ITA.res"

import HistoryListContainers from "../../core/history/containers/HistoryList.containers";
import HistoryDetailContainer from "../../core/history/containers/HistoryDetail.container";
import HistoryAddContainer from "../../core/history/containers/HistoryAdd.container";

const HistoryContentPage = ({
    edit = false
}) => {

    const { body, footer, columns } = UpdateHistoryConf
    const [documentId, setDocumentId] = useState(null)
    const [document, setDocument] = useState({})

    return (
        <>
            {/* LIST */}
            <HistoryListContainers>
                {({ list }) => (
                    <FilterComponent
                        loading={list.loading}
                        documents={list.data ? list.data : []}
                        documentDefault={list.data && edit === false ? _first(list.data) : null}
                        onSelectDocument={(id) => setDocumentId(id)}
                        edit={edit} />
                )}
            </HistoryListContainers>
            {/* DETAIL */}
            <HistoryDetailContainer documentId={documentId}>
                {({ detail }) => {
                    setDocument(detail)
                    return (
                        <Box mt={2}>
                            {detail.loading ? <ProgressComponent /> : null}
                        </Box>
                    )
                }}
            </HistoryDetailContainer>
            {/* ADD */}
            <Box mt={2}>
                <ContentTemplate title={String.history.title}>
                    <HistoryAddContainer>
                        {({ item, onSubmit }) => (
                            <Form
                                body={body}
                                footer={edit ? footer : null}
                                columns={columns}
                                onSubmit={onSubmit}
                                display='block'
                                style={{ padding: '24px' }}
                                showTitle={true}
                                initialValues={document.data ? document.data : null}
                                loading={item.loading}
                                disabled={!edit}
                            />
                        )}
                    </HistoryAddContainer>
                </ContentTemplate>
            </Box>
        </>
    )
}

export default HistoryContentPage