import React, { useState } from "react"
import { withStyles } from "@material-ui/core/styles"

import FormControl from "@material-ui/core/FormControl"
import TextField from "@material-ui/core/TextField"
import CircularProgress from "@material-ui/core/CircularProgress"
import Autocomplete from "@material-ui/lab/Autocomplete"

import { styles } from "../../../styles/form/select/Select.component.style"

const SelectTemplate = ({
    classes,
    values,
    label,
    required = false,
    disabled = false,
    input,
    meta,
    loading,
    reset
}) => {
    const showError =
        ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
        meta.touched

    const previousValue = values.find(obj => {
        return obj.value === meta.initial
    })

    const [value, setValue] = useState("")
    const [prevValue, setPrevValue] = useState(true)
    if (prevValue && previousValue !== undefined) {
        setPrevValue(false)
        setValue(previousValue.label)
    }

    return (
        <FormControl className={classes.formControl} error={showError}>
            <Autocomplete
                options={values ? values : []}
                getOptionLabel={option => option.label}
                style={{ width: "100%" }}
                disabled={disabled}
                loading={loading}
                //disableClearable
                onChange={(event, option) => {
                    if (option !== null) {
                        input.onChange(option.value)
                        setValue(option.label)
                    } else {
                        input.onChange("")
                        setValue("")
                    }
                }}
                renderInput={params => (
                    <TextField
                        {...params}
                        label={label}
                        fullWidth
                        required={required}
                        inputProps={{
                            ...params.inputProps,
                            value: value
                        }}
                        variant='outlined'
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? (
                                        <CircularProgress
                                            color="inherit"
                                            size={20}
                                        />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            )
                        }}
                    />
                )}
            />
        </FormControl>
    )
}

export const SelectComponent = withStyles(styles)(SelectTemplate)
