import React from "react"
import { withStyles } from "@material-ui/core/styles"
import TableCell from "@material-ui/core/TableCell"

import { styles } from "../../../styles/table/actions/CellActions.component.style"

import {
    IconDescriptionComponent,
    IconEditComponent,
    IconDeleteComponent,
} from "../../icon/Icon.component"

const CellActionsTemplate = ({
    classes,
    onDetailAction,
    onEditAction,
    onDeleteAction,
    detail,
    edit,
    remove    
}) => {

    return (
        <TableCell className={classes.action}>
            <div className={classes.content}>
                {detail? <IconDescriptionComponent action={onDetailAction} />: <div />}
                {edit ? <IconEditComponent action={onEditAction} /> : <div />}
                {remove ? <IconDeleteComponent action={onDeleteAction} /> : <div />}
            </div>
        </TableCell>
    )
}

export const CellActionsComponent = withStyles(styles)(CellActionsTemplate)
