import React, { useState } from "react"
import { withRouter } from "react-router"
import Box from '@material-ui/core/Box'
import { withStyles } from "@material-ui/core/styles"
import moment from 'moment'

import { styles } from "./Overview.page.style"

import { GridComponent } from '../../library/materialUi/grid/Grid.component'
import String from "../../res/Strings_ITA.res"

import ContentTemplate from '../../template/Content.template'

import BloodPressureOverviewPage from '../bloodPressure/BloodePressureOverview.page'

import HeartRateChartPage from '../heartRate/HeartRateChart.page'
import CigarettesChartPage from '../cigarettes/CigarettesChart.page'

import DateRangeFilterComponent from '../../components/filter/DateRangeFilter.component'

const OverviewPage = ({
    classes,
}) => {

    const [range, setRange] = useState({
        from: moment().subtract(1, 'months'),
        to: moment()
    })

    const onFilterChange = (values) => {
        switch (values.range) {
            case 'week':
                setRange({
                    from: moment().subtract(7, 'days'),
                    to: moment()
                })
                break
            case 'month':
                setRange({
                    from: moment().subtract(1, 'months'),
                    to: moment()
                })
                break
            case 'month_3':
                setRange({
                    from: moment().subtract(3, 'months'),
                    to: moment()
                })
                break
            case 'year':
                setRange({
                    from: moment().subtract(1, 'year'),
                    to: moment()
                })
                break
            default:
                break
        }
    }

    return (
        <>
            {/* FILTER */}
            <Box mb={2}>
                <ContentTemplate title={String.rangeDate.title}>
                    <DateRangeFilterComponent
                        onFilterChange={onFilterChange}
                        initialValues={{
                            range: 'month'
                        }} />
                </ContentTemplate>
            </Box>

            {/* BLOOD PRESSURE */}
            <Box mb={2}>
                <ContentTemplate title={String.bloodPressure.pressure}>
                    <BloodPressureOverviewPage
                        classes={classes}
                        range={range} />
                </ContentTemplate>
            </Box>



            {/* HEART RATE */}
            <Box mb={2}>
                <ContentTemplate title={String.heartRate.title}>
                    <GridComponent container>
                        <GridComponent item xs={12}>
                            <Box className={classes.content}>
                                <HeartRateChartPage
                                    range={range} />
                            </Box>
                        </GridComponent>
                    </GridComponent>
                </ContentTemplate>
            </Box>


            {/* CIGARETTES */}
            <Box mb={2}>
                <ContentTemplate title={String.cigarettes.title}>
                    <GridComponent container>
                        <GridComponent item xs={12}>
                            <Box className={classes.content}>
                                <CigarettesChartPage
                                    range={range} />
                            </Box>
                        </GridComponent>
                    </GridComponent>
                </ContentTemplate>
            </Box>

        </>
    )
}

export default withRouter(withStyles(styles)(OverviewPage))