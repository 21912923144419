import React from "react"
import { Field } from "react-final-form"
import { Error } from "../error"
import { CheckboxComponent } from "../../../../materialUi/form/checkbox/Checkbox.component"

export const Checkbox = ({
    item,
    disabled,
    errors
}) => {
    return (
        <>
            <Field
                required={item.required}
                name={item.name}
                type="checkbox"
                label={item.label}
                disabled={disabled}
                error={errors ? errors[item.name] : null}
                component={CheckboxComponent}
            />
            <Error name={item.name} />
        </>
    )
}
