import Manager from '../../../api/Manager.api'
import Endpoints from '../../../api/Endpoints.api'


const AllergiesUpsertRequest = ({ values, id }) => {
    var body = new FormData();

    // ID
    if (id) {
        body.set('id', id);
    }

    body.set('drugs', values.drugs);
    body.set('food', values.food);
    if (values.other) {
        body.set('other', values.other);
    }    

    return body
}


const allergiesList = ({
    access_token,
    patientId,
}) => {    
    return Manager.client(access_token).get(Endpoints.allergies(patientId))
}

const allergiesDetail = ({
    access_token,
    patientId,
    id
}) => {
    return Manager.client(access_token).get(`${Endpoints.allergies(patientId)}${id}/`)
}


const allergiesUpdate = ({
    access_token,
    patientId,
    values,
    id
}) => {
    return Manager.client(access_token).update(Endpoints.allergies(patientId), AllergiesUpsertRequest({ id, values }))
}


const allergiesAdd = ({
    access_token,
    patientId,
    values,
}) => {
    return Manager.client(access_token).post(Endpoints.allergies(patientId), AllergiesUpsertRequest({ values }))
}



const Request = {
    allergiesList,
    allergiesDetail,
    allergiesAdd,
    allergiesUpdate,
}


export default Request