const Strings = {
    login: {
        button: "Entra",
        username: "Username",
        userMessage: "Inserire un username valido",
        password: "Password"
    },
    home: {
        title: "Lista pazienti",
        fullname: "Nome e Cognome",
        birthDate: "Data di Nascita",
        gender: "Sesso",
        button: "Nuovo",
        rows: "Righe per pagina",
        doctor: "Registrazione medico",
        fiscal_code: "Codice fiscale"
    },
    general: {
        alert: "Campo obbligatorio",
        alertWrong: "Campo errato o assente",
        save: "Salva",
        exit: "Esci",
        note: "Note",
        date: "Data",
        delete: "Elimina",
        new: "Nuovo",
        back: "Torna in home"
    },
    common: {
        male: "Maschio",
        female: "Femmina"
    },
    treatments:{
        pciDes: "PCI-DES",
        pciBms: "PCI-BMS",
        pciDeb:"PCI-DEB",
        pciPoba:"PCI-POBA",
        cabgAmis:"CABG-AMIS",
        cabgAmid:"CABG-AMID",
        cabgSvg:"CABG-SVG",
        cabgRag:"CABG-RAG"
    },
    bloodPressure: {
        pressure: "Pressione sanguigna",
        newDoc: "Nuova misura",
        systolic: "Pressione sistolica",
        diastolic: "Pressione diastolica",
        meanPressure: "Pressione arteriosa media",
        pulsePressure: "Pressione di pulsazione",
        list: "Storico misurazioni di pressione sanguigna",
        position: "Posizione",
        systolicMeasure: "Pressione Sistolica mm[Hg]",
        diastolicMeasure: "Pressione Diastolica mm[Hg]",
        meanPressureMeasure: "Pressione Media mm[Hg]",
        pulsePressureMeasure: " Pressione di Pulsazione mm[Hg]",
        comment: "Commento",
        alertSystolic: "Inserire un valore compreso tra 60 e 270 mmHg",
        alertDiastolic:"Inserire un valore compreso tra 30 e 120 mmHg",
        alertMean:"Inserire un valore compreso tra 60 e 100 mmHg",
        alertPulse:"Inserire un valore compreso tra 40 e 60 mmHg"
    },
    riskFactors: {
        title: "Fattori di rischio",
        messages: "Messaggi",
        smoke: "Fumo",
        hypertension: "Ipertensione",
        dyslipidemia: "Dislipidemia",
        diabetes: "Diabete",
        familiarity: "Familiarità per patologie coronariche",
        chemotherapy: "Precedente chemioterapia",
        radiotherapy: "Precedente radioterapia",
        hiv: "Terapia per HIV",
        renalImpairment: "Insufficienza renale",
        note: "Note"
    },
    personalData: {
        title: "Dati Anagrafici",
        resume: "Riepilogo",
        addressTitle: "Indirizzo",
        firstName: "Nome",
        lastName: "Cognome",
        country: "Stato",
        city: "Città",
        province: "Provincia",
        fiscalCode: "Codice Fiscale",
        email: "Email",
        phone: "Telefono cellulare",
        address: "Indirizzo (Via)",
        zipCode: "CAP",
        update: "Aggiornamento dati del paziente",
        code: "Password Temporanea",
        password_forgot: "Password dimenticata?",
        signup: "Registrati qui"
    },
    detail:{
        overview: 'Overview',
        personalData: 'Dati Anagrafici',
        riskFactors: 'Fattori di rischio',
        status: 'Status',
        history: 'Anamnesi',
        therapy: 'Terapia',  
        newVisit: 'Nuova Visita',        
    },
    overview:{
        title: 'Overview'
    },
    visit: {
        title: 'Nuova visita',
        riskFactors: 'Fattori di rischio',
        status: 'Status',
        history: 'Anamnesi',
        bloodPressure: 'Pressione sanguigna',
        therapy: "Piano terapeutico",
        saveAndGoOn: 'Avanti',
        back: 'Indietro',
        end:"Fine Visita",
        endVisit: "Fine della visita"

    },
    history: {
        title: 'Anamnesi',
        general: 'Anamnesi Generale',
        cardiovascular: 'Anamnesi Cardiologica',
        recent: 'Anamnesi Recente',
        conclusions: 'Conclusioni',
        therapy: 'Terapia consigliata',
    },       
    statusData: {
        title: "Status",
        title_section: "Visita cardiologica",
        surgery_date: "Data intervento",
        surgery: "Interventi subiti",
        bmiTitle: "Indice di massa corporea (BMI)",
        allergies: "Allergie",
        dia: "Dispositivi impiantabili attivi (DIA)",
        previousEvents: "Pregressi eventi coronarici acuti",
        ejectionFractionTitle: "Riduzione Frazione d'Eiezione",
        oxigenSaturationTitle: "Saturazione d'Ossigeno Transcutanea (%)",
        height: "Altezza [cm]",
        weight: "Peso [kg]",
        bmi: "BMI (%)",
        oxigenSaturation: "Saturazione (%)",
        drugs: "Farmacologiche",
        food: "Alimentari",
        other: "Altro",
        describe: "Descrivere il tipo di allergia",
        PM: "Pacemaker (PM)",
        ICD: "Defibrillatore cardiaco impiantabile (ICD)",
        ILR: "Loop Recorder Impiantabile (ILR)",
        stemi: "Pregresso STEMI",
        nstemiac: "Pregresso NSTEMI-AC",
        pci: "Pregressa PCI",
        sick: "Non trattato/malato",
        treated: "Trattato/curato",
        treatment: "Tipo di intervento",
        newTreatment: "Nuovo"
    },
    message:{
        successAdd: 'Operazione completata con successo!',
        successUpdate: 'Operazione completata con successo!',
        readOnly: 'Nota: i campi sono in sola lettura, per la modifica passare da Nuova Visita'              
    },
    filter: {
        title: "Ricerca",
        documents: 'Seleziona un documento precedente'        
    },
    rangeDate: {
        title:"Filtro di Ricerca",
        subtitle: "Filtra per data",
        startDate:"Dati dal:",
        endDate: "al:",
        search: "Filtra",
        pickDate: "Seleziona date",
        week: 'Settimana',
        month: 'Mese',
        month_3: 'Trimestre',
        year: 'Anno',
        custom: 'Seleziona il periodo'
    },
    therapy: {
        title: "Terapia",
        general: "Piano terapeutico",
        anticoagulant: "Terapia anticoagulante",
        antiplatelet: "Terapia antiaggregante",
        warfarin: "Terapia con Warfarin",
        other_drugs: "Altri farmaci",
        atrial_fibrillation: "Fibrillazione Atriale (FA)",
        non_valvular_af: "FA Non Valvolare",
        valvular_af: "FA Valvolare",
        mechanical_valve: "Impianto di valvola cardiaca meccanica",
        days_of_therapy: "Giorni",
        everyday: "Ogni giorno",
        monday: "Lunedì",
        tuesday: "Martedì",
        wednesday: "Mercoledì",
        thursday: "Giovedì",
        friday: "Venerdì",
        saturday: "Sabato",
        sunday: "Domenica",
        time: "Orario di assunzione",
        assumption: 'Assunzione',
        after_meals: "Dopo i pasti",
        empty_stomach: "A stomaco vuoto",
        indifferent: 'Indifferente',
        button: "Aggiungi",
        dosage: "Dosaggio",
        select:'Inserire farmaco',
        time1: '7:00 - 10:00',
        time2: '11:00 - 14:00',
        time3: '15:00 - 18:00',
        time4: '19:00 - 22:00',
        end: 'Fine',
        start: 'Inizio',
        whole: "Intera",
        half: "Metà",
        quarter: "Un quarto",
        none: "No",
        list: 'Lista di Farmaci selezionati'
    },
    anticoagulant_therapy:{
        doac: "Farmaci DOAC (Direct-acting Oral Anticoagulants)",
        creatinine: "Creatinina [mg/dl]",
        verapamil: "Terapia con Verapamil",
        nao_select: "Farmaci NAO",
        warfarin: "Step 2: Terapia con Warfarin",
        start: "Inizio assunzione",
        end: "Fine assunzione",
        next: "Prossimo controllo",
        target_inr: "INR di riferimento",
        current_inr: "INR",
        weekly_dose: "Dose settimanale",
        whole: "Intera",
        half: "Metà",
        quarter: "Un quarto",
        none: "No",
        time: "Ora",
        coumadin:"Coumadin",
        info: "Informazioni sulla terapia"
    },
    antiplatelet_therapy: {
        drugs: 'Farmaci antiaggreganti'
    },
    heartRate: {
        title: 'Frequenza Cardiaca'
    },
    cigarettes: {
        title: 'Sigarette'
    },
    pathology: {
        title: 'Patologie cardiache',
        ischemic: 'Scompenso cardiaco',
        failure: 'Insufficienza cardiaca',
        valve: 'Impianto di valvola meccanica',
        fibrillation: 'Fibrillazione atriale',
        flutter: 'Flutter atriale',
        button: 'Patologie',
        typeAFib: 'Tipo di Fibrillazione Atriale',
        typeAFlutter: 'Tipo di Flutter Atriale',
        add: 'Aggiungi'
    },
}

export default Strings