import Manager from '../../../api/Manager.api'
import Endpoints from '../../../api/Endpoints.api'

const PathologyUpsertRequest = ({ values, id }) => {
    var body = new FormData();

    // ID
    if (id) {
        body.set('id', id);
    }

    body.set('ischemic_heart_disease', values.ischemic_heart_disease);
    body.set('heart_failure', values.heart_failure);

    return body
}

const pathologyList = ({
    access_token,
    patientId,
}) => {
    return Manager.client(access_token).get(Endpoints.pathology(patientId))
}

const pathologyDetail = ({
    access_token,
    patientId,
    id
}) => {
    return Manager.client(access_token).get(`${Endpoints.pathology(patientId)}${id}/`)
}

const pathologyUpdate = ({
    access_token,
    patientId,
    values,
    id
}) => {
    return Manager.client(access_token).update(Endpoints.pathology(patientId), PathologyUpsertRequest({ id, values }))
}

const pathologyAdd = ({
    access_token,
    patientId,
    values,    
}) => {
    return Manager.client(access_token).post(Endpoints.pathology(patientId), PathologyUpsertRequest({ values }))
}

const Request = {
    pathologyList,
    pathologyDetail,
    pathologyAdd,
    pathologyUpdate
}


export default Request