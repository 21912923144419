import Actions from '../actions/Multiple.actions';

export const InitialState = {
    loading: false,
    error: null,
    data: {}
}

export const MultipleListReducer = (state = InitialState, action) => {
    switch (action.type) {

        case Actions.GET_MULTIPLE_LIST:
            
            return {
                ...state,
                loading: true,
            }

        case Actions.GET_MULTIPLE_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                data: action.payload
            };

        case Actions.GET_MULTIPLE_LIST_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default:
            return state
    }
}



