import React from "react"
import Box from '@material-ui/core/Box';
import TherapyListItemComponent from "../../components/therapy/TherapyListItem.component";
import { Panels } from '../../conf/therapy/Therapy.conf'
import { ButtonComponent } from "../../library/materialUi/button/Button.component";

const TherapyDrugsListPage = ({
    document = {},
    edit = false,
    onDeleteDrug = () => { },
    onDetailDrug = () => { },
    onSaveTherapy = () => { },
}) => {

    return (
        <Box>
            {document.data ?
                <>
                    {document.data.nao.map(item => (
                        <TherapyListItemComponent
                            key={item.documentId}
                            drug={
                                {
                                    id: item.drug.id,
                                    name: item.drug.name
                                }
                            }
                            onDetailAction={(drug) => onDetailDrug(drug.id, Panels[0].id)}
                            onDeleteAction={onDeleteDrug} />
                    ))}                            
                    {document.data.warfarin.map(item => (
                        <TherapyListItemComponent
                            key={item.documentId}
                            drug={
                                {name: item.drug}
                            }
                            onDetailAction={(drug) => onDetailDrug(drug, Panels[1].id)}
                            onDeleteAction={onDeleteDrug} />
                    ))}
                    {document.data.anti_platelet.map(item => (
                        <TherapyListItemComponent
                            key={item.documentId}
                            drug={
                                {
                                    id: item.drug.id,
                                    name: item.drug.name
                                }
                            }
                            onDetailAction={(drug) => onDetailDrug(drug.id, Panels[2].id)}
                            onDeleteAction={onDeleteDrug} />
                    ))}
                    {document.data.other_drugs.map(item => (
                        <TherapyListItemComponent
                            key={item.documentId}
                            drug={
                                {name: item.drug}
                            }
                            onDetailAction={(drug) => onDetailDrug(drug.id, Panels[3].id)}
                            onDeleteAction={onDeleteDrug} />
                    ))}
                </> : []}
            <Box display="flex" width='100%'>
                <Box flexGrow={1}/>
                <ButtonComponent
                    title='Salva'
                    variant='contained'
                    color='primary'
                    type='submit'
                    onClick={onSaveTherapy} />
            </Box>
        </Box>
    )
}

export default TherapyDrugsListPage