import React from "react"
import { withStyles } from "@material-ui/core/styles"
import TableHead from "@material-ui/core/TableHead"

import { styles } from "../../../styles/table/head/Head.component.style"

const HeadTemplate = ({ children, classes }) => {
    return <TableHead className={classes.root}>{children}</TableHead>
}
export const HeadComponent = withStyles(styles)(HeadTemplate)
