import React from "react"
import { withRouter } from "react-router"
import { connect } from "react-redux"
import _isEmpty from 'lodash/isEmpty'
import { useAuthContext } from '../../../contexts/auth/Auth';

import AddContainer from '../../add/container/Add.container'
import Request from '../api/Treatment.api'
import { selectTreatment } from "../selector/Treatment.selector";

import { selects } from '../../selects/selector/Selects.selector'

const TreatmentAddContainer = ({
    children,
    match,
    selects,
    callback,
}) => {

    const patientId = match.params.patient
    const { authToken } = useAuthContext()

    return (
        _isEmpty(selects.data) ? null :
            <AddContainer
                request={Request.treatmentsAdd}
                params={{
                    access_token: authToken.access_token,
                    patientId: patientId
                }}
                node='TREATMENTS'
                selector={selectTreatment}
                refresh={{
                    request: Request.treatmentsList,
                    params: {
                        access_token: authToken.access_token,
                        patientId: patientId
                    },
                    node: 'TREATMENTS'
                }}
                callback={callback}>
                {({ item, onSubmit }) => (
                    children({ item, onSubmit, treatmentsTypes: selects.data.treatmentsTypes })
                )}
            </AddContainer>
    )
}

const mapStateToProps = (state, ownProps) => ({
    selects: selects(state)
});

export default withRouter(connect(mapStateToProps, null)(TreatmentAddContainer))