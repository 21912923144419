import Manager from '../../../api/Manager.api'
import Endpoints from '../../../api/Endpoints.api'


const WeightUpsertRequest = ({ values, id }) => {
    var body = new FormData();

    // ID
    if (id) {
        body.set('id', id);
    }

    if (values.height) {
        body.set('height', values.height);
    }

    if (values.weight) {
        body.set('weight', values.weight);
    }

    if (values.oxigen_saturation) {
        body.set('oxigen_saturation', values.oxigen_saturation);
    }

    if (values.other) {
        body.set('other', values.other);
    }


    body.set('drugs', values.drugs);
    body.set('food', values.food);
    body.set('pacemaker', values.pacemaker);
    body.set('defibrillator', values.defibrillator);
    body.set('loop_recorder', values.loop_recorder);
    body.set('previous_stemi', values.previous_stemi);
    body.set('previous_nstemiAC', values.previous_nstemiAC);
    body.set('previous_pci', values.previous_pci);
    body.set('select_ejection', values.select_ejection);
    body.set('treated_weight', values.treated_weight);

    return body
}


const weightList = ({
    access_token,
    patientId,
}) => {
    return Manager.client(access_token).get(Endpoints.weight(patientId))
}

const weightDetail = ({
    access_token,
    patientId,
    id
}) => {
    return Manager.client(access_token).get(`${Endpoints.weight(patientId)}${id}/`)
}


const weightUpdate = ({
    access_token,
    patientId,
    values,
    id
}) => {
    return Manager.client(access_token).update(Endpoints.weight(patientId), WeightUpsertRequest({ id, values }))
}


const weightAdd = ({
    access_token,
    patientId,
    values,
}) => {
    return Manager.client(access_token).post(Endpoints.weight(patientId), WeightUpsertRequest({ values }))
}



const Request = {
    weightList,
    weightDetail,
    weightAdd,
    weightUpdate,
}


export default Request