import React from "react"
import { withRouter } from "react-router"
import { connect } from "react-redux"
import _isEmpty from 'lodash/isEmpty'
import { useAuthContext } from '../../../contexts/auth/Auth';

import AddContainer from '../../add/container/Add.container'
import { selectStatus } from "../selector/Status.selector";
import Request from '../api/Status.api'

import { selects } from '../../selects/selector/Selects.selector'

const StatusAddContainer = ({
    children,
    match,
    selects,
}) => {

    const patientId = match.params.patient
    const { authToken } = useAuthContext()
    

    return (
        _isEmpty(selects.data) ? null :
            <AddContainer
                request={Request.statusAdd}
                params={{
                    access_token: authToken.access_token,
                    patientId: patientId
                }}
                node='STATUS'
                selector={selectStatus}>
                {({ item, onSubmit }) => (
                    children({ item, onSubmit, ejectionFractions: selects.data.ejectionFractions })
                )}
            </AddContainer>
    )
}

const mapStateToProps = (state, ownProps) => ({
    selects: selects(state)
});

export default withRouter(connect(mapStateToProps, null)(StatusAddContainer))