import { createAction } from 'redux-actions';

export const GET_LIST = 'GET_LIST';
export const GET_LIST_SUCCESS = 'GET_LIST_SUCCESS';
export const GET_LIST_ERROR = 'GET_LIST_ERROR';

export const getList = createAction(GET_LIST)
export const getListSuccess = createAction(GET_LIST_SUCCESS)
export const getListError = createAction(GET_LIST_ERROR)


export default {
    GET_LIST,
    GET_LIST_SUCCESS,
    GET_LIST_ERROR,        
    
    getList,
    getListSuccess,
    getListError,        
}