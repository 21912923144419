import React from "react"
import { withStyles } from "@material-ui/core/styles"
import TableCell from "@material-ui/core/TableCell"
import Avatar from "@material-ui/core/Avatar"

import { styles } from "../../../styles/table/image/CellImage.component.style"

const CellImageTemplate = ({ classes, src }) => {
    return (
        <TableCell padding="none" className={classes.cell}>
            <Avatar src={src} className={classes.avatar} />
        </TableCell>
    )
}

export const CellImageComponent = withStyles(styles)(CellImageTemplate)
