import React from "react"
import { withStyles } from "@material-ui/core/styles"
import { compose } from "recompose"
import Dialog from "@material-ui/core/Dialog"
import withMobileDialog from "@material-ui/core/withMobileDialog"

import { styles } from "../../styles/modal/Modal.component.style"

const ModalTemplate = ({
    children,
    fullWidth,
    maxWidth,
    fullScreen,
    open,
    onModalAction
}) => {
    return (
        <Dialog
            disableBackdropClick
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            fullScreen={fullScreen}
            open={open}
            onClose={onModalAction}
        >
            {children}
        </Dialog>
    )
}

const ModalTemplateWithHoc = compose(
    withMobileDialog(),
    withStyles(styles)
)(ModalTemplate)

export default ModalTemplateWithHoc
