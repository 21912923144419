import React from "react";
import { Route, Redirect } from 'react-router-dom';
import { useAuthContext } from '../contexts/auth/Auth'

const PrivateRoute = ({
	path,
	component
}) => {

	const { authToken } = useAuthContext();
	
	return (
		<React.Fragment>
			{authToken ? <Route exact path={path} component={component} /> : <Redirect to="/" />}
		</React.Fragment>
	);
};

export default PrivateRoute;