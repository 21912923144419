import React from "react"
import { withStyles } from "@material-ui/core/styles"
import IconButton from "@material-ui/core/IconButton"

import AddIcon from "@material-ui/icons/Add"
import DeleteIcon from "@material-ui/icons/Delete"
import DescriptionIcon from "@material-ui/icons/Description"
import EditIcon from "@material-ui/icons/Edit"
import CloseIcon from "@material-ui/icons/Close"
import NextIcon from "@material-ui/icons/NavigateNext"
import PrevIcon from "@material-ui/icons/NavigateBefore"

import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"
import ListAltIcon from "@material-ui/icons/ListAlt"
import TodayIcon from "@material-ui/icons/Today"
import FilePdfIcon from "@material-ui/icons/PictureAsPdf"
import FileIcon from "@material-ui/icons/FileCopy"

import { styles } from "../../styles/icon/Icon.component.style"
import { appColor } from "../../config/colors/colors"

const Icon = ({ children, action, component = "button", disabled = false }) => {
    return (
        <IconButton
            color="inherit"
            onClick={action}
            component={component}
            disabled={disabled}
        >
            {children}
        </IconButton>
    )
}

const IconGeneric = ({
    action,
    component = "button",
    disabled = false,
    children
}) => {
    return (
        <Icon action={action} component={component} disabled={disabled}>
            {children}
        </Icon>
    )
}
export const IconGenericComponent = withStyles(styles)(IconGeneric)

const IconClose = ({ action, selected = false }) => {
    return (
        <Icon action={action}>
            <CloseIcon style={{ color: appColor.darkBlue }} />
        </Icon>
    )
}
export const IconCloseComponent = withStyles(styles)(IconClose)

const IconDelete = ({ action, selected = false, color = "primary" }) => {
    return (
        <Icon action={action}>
            <DeleteIcon color={color} />
        </Icon>
    )
}
export const IconDeleteComponent = withStyles(styles)(IconDelete)

const IconAdd = ({ action, selected = false, component = "button" }) => {
    return (
        <Icon action={action} component={component}>
            <AddIcon color="secondary" />
        </Icon>
    )
}
export const IconAddComponent = withStyles(styles)(IconAdd)

const IconDescription = ({ action, selected = false }) => {
    return (
        <Icon action={action}>
            <DescriptionIcon color="primary"/>
        </Icon>
    )
}
export const IconDescriptionComponent = withStyles(styles)(IconDescription)

const IconEdit = ({
    action,
    selected = false,
    component = "button",
    color = "primary"
}) => {
    return (
        <Icon action={action} component={component}>
            <EditIcon color={color} />
        </Icon>
    )
}
export const IconEditComponent = withStyles(styles)(IconEdit)

const IconNext = ({ action, selected = false, disabled = false }) => {
    return (
        <Icon action={action} disabled={disabled}>
            <NextIcon />
        </Icon>
    )
}

export const IconNextComponent = withStyles(styles)(IconNext)

const IconPrev = ({ action, selected = false, disabled = false }) => {
    return (
        <Icon action={action} disabled={disabled}>
            <PrevIcon />
        </Icon>
    )
}

export const IconPrevComponent = withStyles(styles)(IconPrev)

const IconExpandLess = ({ action, selected = false }) => {
    const color = selected ? appColor.other.main : appColor.secondary.main

    return (
        <Icon action={action}>
            <ExpandLess style={{ color: color }} />
        </Icon>
    )
}

export const IconExpandLessComponent = withStyles(styles)(IconExpandLess)

const IconExpandMore = ({ action, selected = false }) => {
    const color = selected ? appColor.other.main : appColor.secondary.main

    return (
        <Icon action={action}>
            <ExpandMore style={{ color: color }} />
        </Icon>
    )
}

export const IconExpandMoreComponent = withStyles(styles)(IconExpandMore)

const IconList = ({ action, selected = false }) => {
    const color = selected ? appColor.other.main : appColor.secondary.main

    return (
        <Icon action={action}>
            <ListAltIcon style={{ color: color }} />
        </Icon>
    )
}

export const IconListComponent = withStyles(styles)(IconList)

const IconCalendar = ({ action, selected = false }) => {
    const color = selected ? appColor.other.main : appColor.secondary.main

    return (
        <Icon action={action}>
            <TodayIcon style={{ color: color }} />
        </Icon>
    )
}

export const IconCalendarComponent = withStyles(styles)(IconCalendar)

// const IconSettings = ({ action, selected = false }) => {
//     const color = selected ? appColor.other.main : appColor.secondary.main

//     return (
//         <Icon action={action}>
//             <SettingsIcon style={{ color: color }} />
//         </Icon>
//     )
// }

// export const IconSettingsComponent = withStyles(styles)(IconSettings)

const IconFilePdf = ({ action, selected = false }) => {
    return (
        <Icon action={action}>
            <FilePdfIcon color="primary" />
        </Icon>
    )
}

export const IconFilePdfComponent = withStyles(styles)(IconFilePdf)

const IconFile = ({ action, selected = false }) => {
    return (
        <Icon action={action}>
            <FileIcon color="primary" />
        </Icon>
    )
}

export const IconFileComponent = withStyles(styles)(IconFile)
