
import Manager from '../../../api/Manager.api'
import Endpoints from '../../../api/Endpoints.api'


// Therapy
const therapyList = ({
    access_token,
    patientId,
}) => {
    return Manager.client(access_token).get(Endpoints.therapyPlan(patientId))
}

const therapyDetail = ({
    access_token,
    patientId,
    id
}) => {
    return Manager.client(access_token).get(`${Endpoints.therapyPlan(patientId)}${id}/`)
}

const Request = {
    therapyList,
    therapyDetail,
    //therapyAdd,
}


export default Request