import React from "react"

import _first from 'lodash/first'
import Box from '@material-ui/core/Box';


import { TreatmentStatusConf, TreatmentStatusList } from '../../conf/status/Status.conf';

import String from "../../res/Strings_ITA.res"

import useModal from '../../library/hooks/useModal';
import ModalProvider from '../../library/components/modal/provider';
import Modal from '../../library/components/modal/ui';
import ModalToolbar from '../../library/components/modal/ui/toolbar';
import ModalContent from '../../library/components/modal/ui/contents';

import ProgressComponent from '../../library/materialUi/progress/Progress.component'

import Form from '../../components/form/Form.component';
import StatusList from '../../components/status/StatusList.component'

import TreatmentListContainers from "../../core/treatment/containers/TreatmentList.containers";
import TreatmentAddContainer from "../../core/treatment/containers/TreatmentAdd.container";


const StatusTreatmentContentPage = ({
    edit = false,
}) => {

    const { openModal, closeModal, isModalShowing } = useModal()
    const { body, footer, columns } = TreatmentStatusConf


    return (
        <>
            {/* LIST */}
            <TreatmentListContainers>
                {({ list }) => (
                    <>
                        {list.loading ? <ProgressComponent /> : null}
                        <Box p={2}>
                            <StatusList
                                values={list.data ? list.data.map(item => (
                                    {
                                        created_at: item.date,
                                        treatment: item.type.map(type => type.name)
                                    }
                                )) : []}
                                add={edit}
                                conf={TreatmentStatusList}
                                addDocumentAction={openModal} />
                        </Box>
                    </>
                )}
            </TreatmentListContainers>

            {/* ADD */}
            <TreatmentAddContainer
                callback={({ success }) => {
                    if (success) {
                        closeModal()
                    }
                }}>
                {({ onSubmit, treatmentsTypes }) => {

                    body.type.options = treatmentsTypes.map(item => ({
                        value: item.id,
                        label: item.name
                    }))

                    return (
                        <ModalProvider
                            disableBackdropClick={true}
                            fullWidth={true}
                            maxWidth={'sm'}
                            open={isModalShowing}
                            onModalAction={() => { closeModal() }}
                            title={String.statusData.treatment}>
                            <Modal>
                                <ModalToolbar />
                                <ModalContent>
                                    <Form
                                        body={body}
                                        footer={footer}
                                        columns={columns}
                                        onSubmit={onSubmit}
                                        display='flex'
                                        style={{ padding: '0px' }}
                                        showTitle={false}
                                        disabled={false}
                                        initialValues={{ type: [] }} />
                                </ModalContent>
                            </Modal>
                        </ModalProvider>
                    )
                }}
            </TreatmentAddContainer>
        </>
    )
}

export default StatusTreatmentContentPage