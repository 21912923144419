import React from "react"
import { withRouter } from "react-router"
import { useAuthContext } from '../../../contexts/auth/Auth';
import ListContainer from '../../list/container/List.container'
import Request from '../api/Therapy.api'
import { selectTherapies } from "../selector/Therapy.selector";

const TherapyListContainer = ({
    match,
    children,
    callback,
}) => {

    const patientId = match.params.patient
    const { authToken } = useAuthContext()

    return (
        <ListContainer
            request={Request.therapyList}
            params={{
                access_token: authToken.access_token,
                patientId: patientId,
            }}
            callback={callback}
            node='THERAPIES'
            selector={selectTherapies}>
            {children}
        </ListContainer>
    )
}

export default withRouter(TherapyListContainer)