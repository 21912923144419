import React from 'react'
import _slice from "lodash/slice"
import { withStyles } from "@material-ui/core/styles";
import { styles } from "./OverviewList.components.style";

import ListProvider from '../../library/components/list/provider';
import List from '../../library/components/list/ui';
import ListPagination from '../../library/components/list/ui/pagination';
import ListHead from '../../library/components/list/ui/head';
import ListBody from '../../library/components/list/ui/body';
import useListPagination from "../../library/hooks/useListPagination"

const OverviewList = ({
    classes,
    values,
    detailDocumentAction = () => { },    
    conf
}) => {

    const { toolbar, head, body, pagination } = conf
    const { page, setPage, rowsPerPage, setRowsPerPage } = useListPagination(pagination.page, pagination.rowsPerPage)


    return (
        <ListProvider
            toolbar={toolbar.title}
            count={values.length}
            data={_slice(values, rowsPerPage * page, rowsPerPage * (page + 1))}
            page={page}
            rowsPerPageOptions={pagination.rowsPerPageOptions}
            rowsPerPage={rowsPerPage}
            head={head}
            body={body}
            onChangePage={(event, page) => setPage(page)}
            onChangeRowsPerPage={event => setRowsPerPage(event.target.value)}
            onDetailAction={(id) => detailDocumentAction(id)}>            
            <List>
                <ListHead />
                <ListBody />
            </List>            
            <ListPagination />
        </ListProvider>
    )
}

export default withStyles(styles)(OverviewList)