import React from "react"
import { withStyles } from "@material-ui/core/styles"
import { styles } from "./ContentColumns.template.style"
import Box from '@material-ui/core/Box';

const renderTitle = (style, index, width, title, border) => {
    return (
        <Box
            key={index}
            width={width}
            p={2}
            className={style}
            borderTop={border.top}
            borderRight={border.right}
            borderBottom={border.bottom}
            borderLeft={border.left}>
            {title}
        </Box>
    )
}

const ContentColumnsTemplate = ({
    classes,
    children,
    titles,
    column,
    border,
}) => {

    return (
        <Box
            className={classes.root}
            width={column.size}
            borderTop={border.top}
            borderRight={border.right}
            borderBottom={border.bottom}
            borderLeft={border.left}>                
            <Box display="flex" className={classes.title}>
                {titles.map((element, index) => {
                    return renderTitle(classes.text, index, element.size, element.title, element.border)
                })}
            </Box>
            <Box className={classes.content}>
                {children}
            </Box>
        </Box>
    )
}

export default withStyles(styles)(ContentColumnsTemplate)