import React from "react"
import { withRouter } from "react-router"
import { withStyles } from "@material-ui/core/styles"
import { ButtonComponent } from '../../library/materialUi/button/Button.component'
import Box from '@material-ui/core/Box';
import { styles } from "./SaveVisitContent.page.style"

import String from "../../res/Strings_ITA.res"
import { Path } from '../../conf/paths/Paths.conf'

const SaveVisitContentPage = ({
    classes, match,
    history
}) => {

    const patientId = match.params.patient

    const BackHomeAction = () => {
        history.push(`/detail/${patientId}`, { Path: Path.detail })
    }
    return (
        <Box className={classes.root}>
            <Box className={classes.text}>
                Hai concluso la visita!
                Torna in homepage per visualizzare la pagina personale del tuo paziente!
                <Box className={classes.button}>
                    <ButtonComponent
                        type='add'
                        action={BackHomeAction}
                        title={String.general.back} />
                </Box>
            </Box>
        </Box>
    )
}

export default withRouter(withStyles(styles)(SaveVisitContentPage))