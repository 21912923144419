import React, { useState } from "react"

import _first from 'lodash/first'
import Box from '@material-ui/core/Box';

import { AllergiesStatusConf } from '../../conf/status/Status.conf';
import String from "../../res/Strings_ITA.res"
import ContentTemplate from '../../template/Content.template'

import ProgressComponent from '../../library/materialUi/progress/Progress.component'
import Form from '../../components/form/Form.component';
import FilterComponent from '../../components/filter/Filter.component'

import AllergiesListContainer from '../../core/allergies/containers/AllergiesList.containers'
import AllergiesDetailContainer from '../../core/allergies/containers/AllergiesDetail.container'
import AllergiesAddContainer from '../../core/allergies/containers/AllergiesAdd.container'

const StatusAllergiesContentPage = ({
    edit = false
}) => {

    const { body, footer, columns } = AllergiesStatusConf
    const [documentId, setDocumentId] = useState(null)
    const [document, setDocument] = useState({})

    return (
        <ContentTemplate title={String.statusData.allergies}>

            {/* LIST */}
            <Box width={1}>
                <AllergiesListContainer>
                    {({ list }) => (
                        <>
                            {list.loading ? <ProgressComponent /> : null}
                            <FilterComponent
                                loading={list.loading}
                                size={12}
                                documents={list.data ? list.data : []}
                                documentDefault={list.data && edit === false ? _first(list.data) : null}
                                onSelectDocument={(id) => setDocumentId(id)}
                                edit={edit} />
                        </>
                    )}
                </AllergiesListContainer>
            </Box>

            {/* DETAIL */}
            <AllergiesDetailContainer
                documentId={documentId}>
                {({ detail }) => {
                    setDocument(detail)
                    return (
                        <Box mt={2}>
                            {detail.loading ? <ProgressComponent /> : null}
                        </Box>
                    )
                }}
            </AllergiesDetailContainer>

            {/* ADD */}
            <Box width={1}>
                <AllergiesAddContainer>
                    {({ item, onSubmit }) => (
                        <Form
                            body={body}
                            footer={edit ? footer : null}
                            columns={columns}
                            onSubmit={onSubmit}
                            style={{ padding: '0px' }}
                            showTitle={false}
                            initialValues={document.data ? document.data : null}
                            disabled={!edit}
                        />
                    )}
                </AllergiesAddContainer>
            </Box>
        </ContentTemplate>
    )
}

export default StatusAllergiesContentPage