import { connect } from "react-redux"
import AuthenticationActions from "../actions/Authentication.actions";
import { selectAuthentication } from '../selector/Authentication.selector'

import { useAuthContext } from '../../../contexts/auth/Auth'

const AuthenticationContainer = ({
    children,
    params,
    request,
    refresh,
    signIn,
    signOut,
    item,
    node,
    callback    
}) => {

    const { setToken , removeToken } = useAuthContext()    

    const onSignIn = (values) => {
        signIn({
            params: {
                ...params,
                values
            },
            request: request,
            node: node,
            refresh: refresh ? refresh : null,
            callback: ({ token , success }) => {
                if(success){
                    setToken(token)
                }                
                callback({ success })
            }
        })
    }

    const onSignOut = (values) => {
        signOut({
            params: {
                ...params,
                values
            },
            request: request,
            node: node,
            refresh: refresh ? refresh : null,
            callback: ({ success }) => {
                if(success){
                    removeToken()
                }                
                callback({ success })
            }
        })
    }


    return children? children({ item, onSignIn, onSignOut }) : null
}

const mapStateToProps = (state) => ({
    item: selectAuthentication(state)
});

const mapDispatchToProps = (dispatch) => ({
    signIn: (params) => {
        dispatch(AuthenticationActions.accountSignInAction(params))
    },
    signOut: (params) => {
        dispatch(AuthenticationActions.accountSignOutAction(params))
    },    
})

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticationContainer)