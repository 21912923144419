import React from "react"
import { withStyles } from "@material-ui/core/styles"
import TableCell from "@material-ui/core/TableCell"

import { styles } from "../../../styles/table/boolean/CellBoolean.component.style"

const CellBooleanTemplate = ({ label }) => {
    const value = label ? "True" : "False"

    return <TableCell>{value}</TableCell>
}

export const CellBooleanComponent = withStyles(styles)(CellBooleanTemplate)
