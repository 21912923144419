import React from "react"
import { withStyles } from "@material-ui/core/styles"
import LinearProgress from "@material-ui/core/LinearProgress"

import { styles } from "../../styles/progress/Progress.component.style"

const Progress = ({ classes }) => {
    return <LinearProgress color="primary" className={classes.root} />
}

export default withStyles(styles)(Progress)
