import { GenderConf } from '../../conf/common/Common.conf'
import String from "../../res/Strings_ITA.res"

export const NewPatientConf = {
    columns: [
        {
            size: 1,
            title: String.personalData.title,
            border: { top: 0 , right: 0, bottom: 0, left: 0 }
        },
        {
            size: 1,
            title: String.personalData.addressTitle,
            border: { top: 0 , right: 0, bottom: 0, left: 0 }
        }
    ],
    body: {
        first_name: {
            component: 'EditText',
            name: 'first_name',
            label: String.personalData.firstName,
            required: true,
            disabled: false,
            xs: 6,
            column: 0,
            validation: {
                message: String.general.alertWrong
            },
        },
        last_name: {
            component: 'EditText',
            name: 'last_name',
            label: String.personalData.lastName,
            required: true,
            disabled: false,
            xs: 6,
            column: 0,
            validation: {
                message: String.general.alertWrong
            },
        },
        date_of_birth: {
            component: 'CalendarPicker',
            name: 'date_of_birth',
            label: String.home.birthDate,
            calendarType: 'KeyboardDatePicker',
            required: true,
            disabled: false,
            xs: 12,
            column: 0,
            validation: {
                message: String.general.alertWrong
            },
        },
        country_of_birth: {
            component: 'EditText',
            name: 'country_of_birth',
            label: String.personalData.country,
            required: true,
            disabled: false,
            xs: 6,
            column: 0,
            validation: {
                message: String.general.alertWrong
            },
        },
        place_of_birth: {
            component: 'EditText',
            name: 'place_of_birth',
            label: String.personalData.city,
            required: true,
            disabled: false,
            xs: 6,
            column: 0,
            validation: {
                message: String.general.alertWrong
            },
        },
        province_of_birth: {
            component: 'EditText',
            name: 'province_of_birth',
            label: String.personalData.province,
            required: true,
            disabled: false,
            xs: 6,
            column: 0,
            validation: {
                message: String.general.alertWrong
            },
        },
        gender: {
            component: 'Select',
            name: 'gender',
            label: String.home.gender,
            required: true,
            disabled: false,
            xs: 6,
            column: 0,
            options: GenderConf,
            validation: {
                message: String.general.alertWrong
            },
        },
        fiscal_code: {
            component: 'EditText',
            name: 'fiscal_code',
            label: String.personalData.fiscalCode,
            required: true,
            disabled: false,
            xs: 12,
            column: 0,
            validation: {
                regex: /^[a-zA-Z]{6}[0-9]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9]{2}([a-zA-Z]{1}[0-9]{3})[a-zA-Z]{1}$/,
                message: String.general.alertWrong
            },
        },
        country_residence: {
            component: 'EditText',
            name: 'country_residence',
            label: String.personalData.country,
            required: true,
            disabled: false,
            xs: 6,
            column: 1,
            validation: {
                message: String.general.alertWrong
            },
        },
        place_residence: {
            component: 'EditText',
            name: 'place_residence',
            label: String.personalData.city,
            required: true,
            disabled: false,
            xs: 6,
            column: 1,
            validation: {
                message: String.general.alertWrong
            },
        },
        province_residence: {
            component: 'EditText',
            name: 'province_residence',
            label: String.personalData.province,
            required: true,
            disabled: false,
            xs: 6,
            column: 1,
            validation: {
                message: String.general.alertWrong
            },
        },
        address_residence: {
            component: 'EditText',
            name: 'address_residence',
            label: String.personalData.address,
            required: true,
            disabled: false,
            xs: 6,
            column: 1,
            validation: {
                message: String.general.alertWrong
            },
        },
        zipcode_residence: {
            component: 'EditText',
            name: 'zipcode_residence',
            label: String.personalData.zipCode,
            required: true,
            disabled: false,
            xs: 6,
            column: 1,
            validation: {
                regex: /^\d{5}$/,
                message: String.general.alertWrong
            },
        },
        email: {
            component: 'EditText',
            name: 'email',
            label: String.personalData.email,
            required: true,
            disabled: false,
            xs: 6,
            column: 1,
            validation: {
                regex: /^[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                message: String.general.alertWrong
            },
        },
        telephone: {
            component: 'EditText',
            name: 'telephone',
            label: String.personalData.phone,
            required: true,
            disabled: false,
            xs: 6,
            column: 1,
            validation: {
                regex: /^((00|\+)39[\. ]??)??3\d{2}[\. ]??\d{6,7}$/,
                message: String.general.alertWrong
            },
        },
    },
    footer: {
        columns: [
            {
                component: 'ButtonSubmit',
                title: String.general.save,
                variant: 'contained',
                color: 'primary',
            },
        ]
    }
};


export const UpdatePatientConf = {
    columns: [
        {
            size: 1/2,
            title: String.personalData.title,
            border: { top: 0 , right: 1, bottom: 0, left: 0 }
        },
        {
            size: 1/2,
            title: String.personalData.addressTitle,
            border: { top: 0 , right: 0, bottom: 0, left: 0 }
        }
    ],
    body: {
        first_name: {
            component: 'EditText',
            name: 'first_name',
            label: String.personalData.firstName,
            required: true,
            disabled: false,
            xs: 6,
            column: 0,
            validation: {
                message: String.general.alertWrong
            },
        },
        last_name: {
            component: 'EditText',
            name: 'last_name',
            label: String.personalData.lastName,
            required: true,
            disabled: false,
            xs: 6,
            column: 0,
            validation: {
                message: String.general.alertWrong
            },
        },
        date_of_birth: {
            component: 'CalendarPicker',
            name: 'date_of_birth',
            label: String.home.birthDate,
            calendarType: 'KeyboardDatePicker',
            required: true,
            disabled: false,
            xs: 12,
            column: 0,
            validation: {
                message: String.general.alertWrong
            },
        },
        country_of_birth: {
            component: 'EditText',
            name: 'country_of_birth',
            label: String.personalData.country,
            required: true,
            disabled: false,
            xs: 4,
            column: 0,
            validation: {
                message: String.general.alertWrong
            },
        },
        place_of_birth: {
            component: 'EditText',
            name: 'place_of_birth',
            label: String.personalData.city,
            required: true,
            disabled: false,
            xs: 4,
            column: 0,
            validation: {
                message: String.general.alertWrong
            },
        },
        province_of_birth: {
            component: 'EditText',
            name: 'province_of_birth',
            label: String.personalData.province,
            required: true,
            disabled: false,
            xs: 4,
            column: 0,
            validation: {
                message: String.general.alertWrong
            },
        },
        gender: {
            component: 'Select',
            name: 'gender',
            label: String.home.gender,
            required: true,
            disabled: false,
            options: GenderConf,
            xs: 8,
            column: 0,
            validation: {
                message: String.general.alertWrong
            },
        },
        fiscal_code: {
            component: 'EditText',
            name: 'fiscal_code',
            label: String.personalData.fiscalCode,
            required: true,
            disabled: false,
            xs: 12,
            column: 0,
            validation: {
                regex: /^[a-zA-Z]{6}[0-9]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9]{2}([a-zA-Z]{1}[0-9]{3})[a-zA-Z]{1}$/,
                message: String.general.alertWrong
            },
        },
        email: {
            component: 'EditText',
            name: 'email',
            label: String.personalData.email,
            required: true,
            disabled: false,
            xs: 6,
            column: 0,
            validation: {
                regex: /^[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                message: String.general.alertWrong
            },
        },
        telephone: {
            component: 'EditText',
            name: 'telephone',
            label: String.personalData.phone,
            required: true,
            disabled: false,
            type: 'numeric',
            xs: 6,
            column: 0,
            validation: {
                regex: /^((00|\+)39[\. ]??)??3\d{2}[\. ]??\d{6,7}$/,
                message: String.general.alertWrong
            },
        },
        country_residence: {
            component: 'EditText',
            name: 'country_residence',
            label: String.personalData.country,
            required: true,
            disabled: false,
            xs: 6,
            column: 1,
            validation: {
                message: String.general.alertWrong
            },
        },
        place_residence: {
            component: 'EditText',
            name: 'place_residence',
            label: String.personalData.city,
            required: true,
            disabled: false,
            xs: 6,
            column: 1,
            validation: {
                message: String.general.alertWrong
            },
        },
        province_residence: {
            component: 'EditText',
            name: 'province_residence',
            label: String.personalData.province,
            required: true,
            disabled: false,
            xs: 6,
            column: 1,
            validation: {
                message: String.general.alertWrong
            },
        },
        address_residence: {
            component: 'EditText',
            name: 'address_residence',
            label: String.personalData.address,
            required: true,
            disabled: false,
            xs: 6,
            column: 1,
            validation: {
                message: String.general.alertWrong
            },
        },
        zipcode_residence: {
            component: 'EditText',
            name: 'zipcode_residence',
            label: String.personalData.zipCode,
            required: true,
            disabled: false,
            xs: 6,
            column: 1,
            validation: {
                regex: /^\d{5}$/,
                message: String.general.alertWrong
            },
        },        
    },
    footer: {
        columns: [
            {
                component: 'ButtonSubmit',
                title: String.general.save,
                variant: 'contained',
                color: 'primary',
            },
        ]
    }
};