import Manager from '../../../api/Manager.api'
import Endpoints from '../../../api/Endpoints.api'

const positionValues = ({
    access_token,
}) => {
    return Manager.client(access_token).get(Endpoints.positionValues)
}

const treatmentsTypes = ({
    access_token,
}) => {
    return Manager.client(access_token).get(Endpoints.treatmentsType)
}


const ejectionFractions = ({
    access_token,
}) => {
    return Manager.client(access_token).get(Endpoints.ejectionFractions)
}

const NAODrugs = ({
    access_token,
}) => {
    return Manager.client(access_token).get(Endpoints.NAODrugs)
}

const ANTIPLATELETDrugs = ({
    access_token,
}) => {
    return Manager.client(access_token).get(Endpoints.ANTIPLATELETDrugs)
}

const AtrialFibrillationType = ({
    access_token,
}) => {
    return Manager.client(access_token).get(Endpoints.AtrialFibrillationType)
}

const AtrialFlutterType = ({
    access_token,
}) => {
    return Manager.client(access_token).get(Endpoints.AtrialFlutterType)
}

const all = [
    {
        api: positionValues,
        node: 'positionValues'
    },
    {
        api: treatmentsTypes,
        node: 'treatmentsTypes'
    },
    {
        api: ejectionFractions,
        node: 'ejectionFractions'
    },
    {
        api: NAODrugs,
        node: 'NAODrugs'
    },
    {
        api: ANTIPLATELETDrugs,
        node: 'ANTIPLATELETDrugs'
    },
    {
        api: AtrialFibrillationType,
        node: 'AtrialFibrillationType'
    },
    {
        api: AtrialFlutterType,
        node: 'AtrialFlutterType'
    }
]

const Request = {
    positionValues,
    treatmentsTypes,
    ejectionFractions,
    NAODrugs,
    ANTIPLATELETDrugs,
    AtrialFibrillationType,
    AtrialFlutterType,
    all
}


export default Request