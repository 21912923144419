import React, {useState} from "react"
import _first from 'lodash/first'
import Box from '@material-ui/core/Box';
import Form from '../../components/form/Form.component';

import ProgressComponent from '../../library/materialUi/progress/Progress.component'
import FilterComponent from '../../components/filter/Filter.component'

import { PathologyConf } from '../../conf/pathologies/Pathologies.conf'
import PathologiesAddContainer from '../../core/pathology/containers/PathologiesAdd.container'
import PathologiesListContainer from '../../core/pathology/containers/PathologiesList.container'
import PathologiesDetailContainer from '../../core/pathology/containers/PathologiesDetail.container'

const PathologiesContentPage = ({
    edit = false,    
}) => {

    const { body, footer, columns } = PathologyConf
    const [documentId, setDocumentId] = useState(null)
    const [document, setDocument] = useState({})

    return (
        <Box width={1}>

            {/* LIST */}
            <PathologiesListContainer>
                {({ list }) => (
                    <>
                        {list.loading ? <ProgressComponent /> : null}
                        <FilterComponent
                            loading={list.loading}
                            documents={list.data ? list.data : []}
                            documentDefault={list.data && edit === false ? _first(list.data) : null}
                            onSelectDocument={(id) => setDocumentId(id)}
                            edit={edit} />
                    </>
                )}
            </PathologiesListContainer>

            {/* DETAIL */}
            <PathologiesDetailContainer
                documentId={documentId}>
                {({ detail }) => {
                    setDocument(detail)
                    return (
                        <Box mt={2}>
                            {detail.loading ? <ProgressComponent /> : null}
                        </Box>
                    )
                }}
            </PathologiesDetailContainer> 

            {/* ADD */}
            <PathologiesAddContainer>
            {({ item, onSubmit }) => (
                        <Form
                            body={body}
                            columns={columns}
                            footer={edit ? footer : null}
                            onSubmit={onSubmit}
                            display='block'
                            style={{ padding: '12px' }}
                            showTitle={true}
                            initialValues={document.data ? document.data : null}
                            disabled={!edit}
                        />
                        )}
            </PathologiesAddContainer>
        </Box>
    )
}

export default PathologiesContentPage