import React from 'react'
import { withStyles } from "@material-ui/core/styles";
import { styles } from "./OverviewChart.component.style";
import LineChartComponent from "../../library/components/graph/ui/line"
import BarChartComponent from "../../library/components/graph/ui/bar"

const graph_types = {
    LineChartComponent,
    BarChartComponent,
};



const OverviewChart = ({
    values,
    dataKeys,
    XDataKey,
    component
}) => {

    const GraphElement = graph_types[component]

    return (
        <GraphElement
            data={values}
            dataKeys={dataKeys}
            xAxis={{
                dataKey: XDataKey
            }}/>
    )
}

export default withStyles(styles)(OverviewChart)