import React from "react"
import { Field } from "react-final-form"
import { Error } from "../error"
import { RadioComponent } from "../../../../materialUi/form/radio/Radio.component"
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';

export const Radio = ({
    item,
    disabled,
    errors
}) => {
    return (
        <FormControl component="div" required={item.required}>
            <RadioGroup row>
                {item.options.map(option =>
                    <Field
                        key={option.value}
                        name={item.name}
                        label={option.label}
                        value={option.value}
                        type="radio"
                        disabled={disabled}
                        error={errors ? errors[option.name] : null}
                        component={RadioComponent}
                    />
                )}
            </RadioGroup>
            <Error name={item.name} />
        </FormControl>
    )
}
