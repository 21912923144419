import React from 'react'
import { withRouter } from "react-router"
import { withStyles } from "@material-ui/core/styles";
import { styles } from "./Detail.page.style";

import Box from '@material-ui/core/Box'
import AddIcon from "@material-ui/icons/Add"
import Strings from "../../res/Strings_ITA.res"
import { ButtonComponent } from '../../library/materialUi/button/Button.component'

import MenuDetail from '../../components/detail/MenuDetail.component'
import ReadOnlyMessageComponent from '../../components/message/ReadOnlyMessage'
import PageActionTemplate from '../../template/PageAction.template'

import { Path } from '../../conf/paths/Paths.conf'

const DetailPage = ({
    classes,
    match,
    history,
}) => {

    const patientId = match.params.patient

    const newVisitAction = () => {
        history.push(`/detail/${patientId}/visit`, { path:  Path.visit })
    }

    const onOverviewAction = () => {
        history.push(`/detail/${patientId}/overview`, { path: Path.overview })
    }

    const onPersonalDataAction = () => {
        history.push(`/detail/${patientId}/personal`, { path: Path.personal })
    }

    const onRiskFactorsAction = () => {
        history.push(`/detail/${patientId}/risk`, { path: Path.risk })
    }

    const onStatusAction = () => {
        history.push(`/detail/${patientId}/status`, { path: Path.status })
    }

    const onHistoryAction = () => {
        history.push(`/detail/${patientId}/history`, { path: Path.history })
    }

    const onTherapyAction = () => {
        history.push(`/detail/${patientId}/therapy`, { path: Path.therapy })
    }

    const onRecorderEventsAction = () => {

    }

    return (
        <PageActionTemplate
            button={
                <ButtonComponent
                    type='add'
                    title={Strings.detail.newVisit}
                    action={newVisitAction}
                    icon={<AddIcon />} />
            }>
            <Box className={classes.root}>
                <ReadOnlyMessageComponent />
                <Box>
                    <MenuDetail
                        onOverviewAction={onOverviewAction}
                        onPersonalDataAction={onPersonalDataAction}
                        onRiskFactorsAction={onRiskFactorsAction}
                        onStatusAction={onStatusAction}
                        onHistoryAction={onHistoryAction}
                        onTherapyAction={onTherapyAction}
                        onRecorderEventsAction={onRecorderEventsAction} />
                </Box>
            </Box>
        </PageActionTemplate>
    )
}


export default withRouter(withStyles(styles)(DetailPage))