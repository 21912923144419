import { appColor } from '../../library/config/colors/colors'

export const styles = theme => ({
    content: {
        borderColor: appColor.primary.main,
        padding: theme.spacing(2)
    },
    title: {
        fontSize: '16px',
        fontWeight: 'bold',
        textAlign: 'left',
        color: appColor.darkGrey,
        borderColor: 'transparent',
        marginBottom: theme.spacing(2)
    },    
})