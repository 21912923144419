import Background from "../../assets/img/login/background@3x.png"
import { appColor } from '../../library/config/colors/colors'

export const styles = theme => ({
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: "auto",
        backgroundImage: `url(${Background})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',        
    },
    footer:{
        color: appColor.darkGrey
    }
    
});