import React, { useState } from "react"

import Box from '@material-ui/core/Box';
import _first from 'lodash/first';

import { UpdateRiskFactorConf } from '../../conf/risk/RiskFactor.conf';
import String from '../../res/Strings_ITA.res'

import ContentColumnsTemplate from '../../template/ContentColumns.template'
import ContentTemplate from '../../template/Content.template'
import FilterComponent from '../../components/filter/Filter.component'
import ProgressComponent from '../../library/materialUi/progress/Progress.component'
import Form from '../../components/form/Form.component';

import RiskFactorsListContainer from '../../core/risk/containers/RiskFactorsList.container'
import RiskFactorsAddContainer from '../../core/risk/containers/RiskFactorsAdd.container'
import RiskFactorsDetailContainer from '../../core/risk/containers/RickFactorsDetail.container'
import PathologyContent from '../pathology/PathologyContent.page'

const RiskFactorPage = ({
    edit = false,
}) => {

    const { body, footer, columns } = UpdateRiskFactorConf
    const [documentId, setDocumentId] = useState(null)
    const [document, setDocument] = useState({})

    return (
        <>
            {/* LIST */}
            <RiskFactorsListContainer>
                {({ list }) => (
                    <>
                        {list.loading ? <ProgressComponent /> : null}
                        <FilterComponent
                            loading={list.loading}
                            documents={list.data ? list.data : []}
                            documentDefault={list.data && edit === false ? _first(list.data) : null}
                            onSelectDocument={(id) => setDocumentId(id)}
                            edit={edit} />
                    </>
                )}
            </RiskFactorsListContainer>

            {/* DETAIL */}
            <RiskFactorsDetailContainer
                documentId={documentId}>
                {({ detail }) => {
                    setDocument(detail)
                    return (
                        <Box mt={2}>
                            {detail.loading ? <ProgressComponent /> : null}
                        </Box>
                    )
                }}
            </RiskFactorsDetailContainer>

            {/* ADD */}
            <RiskFactorsAddContainer>
                {({ item, onSubmit }) => (
                    <ContentTemplate>
                        <ContentColumnsTemplate
                            titles={[{
                                size: 1 / 3,
                                title: String.riskFactors.title,
                                border: { top: 0, right: 0, bottom: 1, left: 0 }
                            },
                            {
                                size: 1 / 3,
                                title: String.riskFactors.messages,
                                border: { top: 0, right: 1, bottom: 1, left: 1 }
                            },
                            {
                                size: 1 / 3,
                                title: String.riskFactors.note,
                                border: { top: 0, right: 0, bottom: 1, left: 0 }
                            }]}
                            column={{
                                size: 1
                            }}
                            border={{ top: 0, right: 0, bottom: 0, left: 0 }} >
                            <Form
                                body={body}
                                footer={edit ? footer : null}
                                columns={columns}
                                onSubmit={onSubmit}
                                style={{ padding: '0px' }}
                                showTitle={false}
                                initialValues={document.data ? document.data : null}
                                disabled={!edit}
                            />
                        </ContentColumnsTemplate>
                    </ContentTemplate>
                )}
            </RiskFactorsAddContainer>

            <Box mt={2}>
                <ContentTemplate>
                        <PathologyContent edit={edit}/>
                </ContentTemplate>
            </Box>
        </>
    )
}

export default RiskFactorPage