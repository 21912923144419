import React from "react"
import { withDashboardContext } from "../../hoc"
import { AppBarComponent } from "../../../../materialUi/appBar/AppBar.component"

const DashboardToolbar = ({ dashboardContext }) => {
    const {
        openDrawer,
        title,
        badge,
        actions: { handleDrawerOpen, handleOpenNotification, handleLogout }
    } = dashboardContext

    return (
        <AppBarComponent
            openDrawer={openDrawer}
            onOpenDrawer={handleDrawerOpen}
            onOpenNotification={handleOpenNotification}
            onClickLogout={handleLogout}
            title={title}
            badge={badge}
        />
    )
}

export default withDashboardContext(DashboardToolbar)
