import React from "react"
import { withStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"

import { styles } from "../../styles/table/Table.component.style"

const TableTemplate = ({ children }) => {
    return <Table>{children}</Table>
}

export const TableComponent = withStyles(styles)(TableTemplate)
