import React from "react";
import { withRouter } from "react-router"
import { withStyles } from "@material-ui/core/styles"

import { DateRangeConf } from '../../conf/dateFilter/DateFilter.conf'

import { styles } from "./DateRangeFilter.component.style"

import Form from '../../components/form/Form.component';

const DateRangeFilterComponent = ({
    onFilterChange = () => { },
    initialValues = {}
}) => {

    const {
        body,
        footer,
        columns,
    } = DateRangeConf;

    return (
        <Form
            body={body}
            footer={footer}
            columns={columns}
            onSubmit={(values) => onFilterChange(values)}
            style={{ padding: '0px' }}
            showTitle={true}
            initialValues={initialValues}
        />
    )
}
export default withRouter(withStyles(styles)(DateRangeFilterComponent))