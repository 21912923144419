export const Paths = {
    home: "/home",
    detail: "/detail/:patient",
    overview: "/detail/:patient/overview",
    personal: "/detail/:patient/personal",
    risk: "/detail/:patient/risk",
    visit: "/detail/:patient/visit",
    history: "/detail/:patient/history",
    status: "/detail/:patient/status",
    therapy: "/detail/:patient/therapy",
    pathology: "/detail/:patient"
};


export const Path = {
    home: "home",
    detail: "detail",
    overview: "overview",
    personal: "personal",
    risk: "risk",
    visit: "visit",
    history: "history",
    status: "status",
    therapy: "therapy",
    pathology: "pathology"
};