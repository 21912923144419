import React from 'react'
import _slice from "lodash/slice"

import ProgressComponent from "../../library/materialUi/progress/Progress.component"

import ListProvider from '../../library/components/list/provider';
import List from '../../library/components/list/ui';
import ListPagination from '../../library/components/list/ui/pagination';
import ListHead from '../../library/components/list/ui/head';
import ListBody from '../../library/components/list/ui/body';

import { PatientsConf } from '../../conf/patients/Patients.conf'
import useListPagination from "../../library/hooks/useListPagination"
import useListSelection from "../../library/hooks/useListSelection"

const PatientsComponent = ({
    updatePatientAction,
    addPatientAction,
    deletePatientAction,
    detailPatientAction,
    patients,
    loading,
}) => {

    const { toolbar, head, body, pagination } = PatientsConf
    const { page, setPage, rowsPerPage, setRowsPerPage } = useListPagination(pagination.page, pagination.rowsPerPage)
    const { selected, setSelectedItem, setSelectedAll } = useListSelection([])
    patients.sort((a, b) => a.first_name.localeCompare(b.first_name))


    return (
        <ListProvider
            toolbar={toolbar.title}
            count={patients.length}
            data={_slice(patients, rowsPerPage * page, rowsPerPage * (page + 1))}
            page={page}
            rowsPerPageOptions={pagination.rowsPerPageOptions}
            rowsPerPage={rowsPerPage}
            selected={selected}
            head={head}
            body={body}
            onSelect={id => setSelectedItem(id)}
            onSelectAll={event => setSelectedAll(event.target.checked ? patients.map(item => item.id) : [])}
            onChangePage={(event, page) => setPage(page)}
            onChangeRowsPerPage={event => setRowsPerPage(event.target.value)}
            onDeleteAction={() => deletePatientAction(selected)}
            onEditAction={(id) => updatePatientAction(id)}
            onAddAction={() => addPatientAction()}
            onDetailAction={(item) => detailPatientAction(item)}>
            {loading ? <ProgressComponent /> : null}
            <List>
                <ListHead />
                <ListBody />
            </List>
            <ListPagination />
        </ListProvider>
    )
}

export default PatientsComponent