import { appColor } from "../../../config/colors/colors"
export const styles = theme => ({
    body: {
        background: appColor.white,
        '&:hover': {
            cursor: 'pointer',
            transform: 'scale(1.01)',
            boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.75)'
        },
        '&:hover td:last-child div:first-child': {
            visibility: 'visible'
        }
    },
    header: {
        background: 'transparent',
    }
});