import moment from 'moment'
import Manager from '../../../api/Manager.api'
import Endpoints from '../../../api/Endpoints.api'

const BloodPressureUpsertRequest = ({ values, id }) => {
    var body = new FormData();

    // ID
    if (id) {
        body.set('id', id);
    }

    if (values.blood_pressure_time) {
        body.set('blood_pressure_time', moment(values.blood_pressure_time).format('YYYY-MM-DD HH:mm:ss'));
    }

    body.set('systolic_units', 'mm[Hg]');
    if (values.systolic_value) {
        body.set('systolic_value', values.systolic_value);
    }

    body.set('diastolic_units', 'mm[Hg]');
    if (values.diastolic_value) {
        body.set('diastolic_value', values.diastolic_value);
    }

    body.set('mean_arterial_pressure_units', 'mm[Hg]');
    if (values.mean_arterial_pressure_value) {
        body.set('mean_arterial_pressure_value', values.mean_arterial_pressure_value);
    }

    body.set('pulse_pressure_units', 'mm[Hg]');
    if (values.pulse_pressure_value) {
        body.set('pulse_pressure_value', values.pulse_pressure_value);
    }

    if (values.position_value) {
        body.set('position_value', values.position_value);
    }

    if (values.comment_value) {
        body.set('comment_value', values.comment_value);
    }

    return body
}


const bloodPressureList = ({
    access_token,
    patientId,
    range
}) => {    

    var params = new URLSearchParams();
    if (range) {
        params.append("from", moment(range.from).format('YYYY-MM-DD'));
        params.append("to", moment(range.to).format('YYYY-MM-DD'));
    }

    return Manager.client(access_token).get(Endpoints.bloodPressure(patientId), { params } )
}

const bloodPressureDetail = ({
    access_token,
    patientId,
    id
}) => {
    return Manager.client(access_token).get(`${Endpoints.bloodPressure(patientId)}${id}/`)
}

const bloodPressureAdd = ({
    access_token,
    patientId,
    values
}) => {
    return Manager.client(access_token).post(Endpoints.bloodPressure(patientId), BloodPressureUpsertRequest({ values }))
}


const Request = {
    bloodPressureList,
    bloodPressureDetail,
    bloodPressureAdd,
}


export default Request