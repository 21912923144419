import React from "react"

import ProgressComponent from '../../library/materialUi/progress/Progress.component'
import OverviewChart from '../../components/overview/OverviewChart.component'
import { CigarettesChart } from '../../conf/cigarettes/Cigarettes.conf'

import CigarettesListContainers from "../../core/cigarettes/containers/CigarettesList.containers";



const CigarettesChartPage = ({
    range
}) => {

    return (
        <CigarettesListContainers
            range={range}>
            {({ list }) => (
                <>
                    {list.loading ? <ProgressComponent /> : null}
                    <OverviewChart
                        component="BarChartComponent"
                        values={list.data ? list.data.sort((a, b) => new Date(a.created_at) < new Date(b.created_at) ? -1 : 1) : []}
                        dataKeys={CigarettesChart}
                        XDataKey='created_at' />
                </>
            )}
        </CigarettesListContainers>
    )
}

export default CigarettesChartPage