import { appColor } from '../../library/config/colors/colors'

export const styles = theme => ({
    root: {
        fontSize: '22px',
        fontWeight: '500',
        color: appColor.primary.main,
        cursor: 'pointer'
    },
    last: {
        fontSize: '22px',
        fontWeight: '600',
        color: appColor.primary.main,
    },
    separator:{
        fontSize: '22px',
        fontWeight: '600',
        color: appColor.primary.main,
    }
})