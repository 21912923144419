import React from "react"
import { withModalContext } from "../../hoc"
import ModalTitleComponent from "../../../../materialUi/modal/title/ModalTitle.component"
import ToolbarComponent from "../../../../materialUi/toolbar/Toolbar.component"
import { IconCloseComponent } from "../../../../materialUi/icon/Icon.component"

const ModalToolbar = ({
    modalContext: {
        title,
        actions: { onClose }
    }
}) => {
    return (
        <ModalTitleComponent>
            <ToolbarComponent
                title={title}
                rightButtons={
                    onClose ? <IconCloseComponent action={onClose} /> : null
                }
            />
        </ModalTitleComponent>
    )
}

export default withModalContext(ModalToolbar)
