import React from "react"
import { withRouter } from "react-router"
import { useAuthContext } from '../../../contexts/auth/Auth';
import ListContainer from '../../list/container/List.container'
import Request from '../api/History.api'
import { selectHistories } from "../selector/History.selector";

const HistoryListContainer = ({
    match,
    children,
    callback,
}) => {

    const patientId = match.params.patient
    const { authToken } = useAuthContext()

    return (
        <ListContainer
            request={Request.historyList}
            params={{
                access_token: authToken.access_token,
                patientId: patientId
            }}
            node='HISTORY'
            selector={selectHistories}
            callback={callback}>
            {children}            
        </ListContainer>
    )
}

export default withRouter(HistoryListContainer)