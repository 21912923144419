import Actions from '../actions/Messages.actions';

const initialState = {
    error: null,
    success: null,
    alert: null,
}

const messagesReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.SET_ERROR:
            return {
                ...state,
                error: action.payload,
            }

        case Actions.REMOVE_ERROR:
            return {
                ...state,
                error: null,
            }

        case Actions.SET_SUCCESS:
            return {
                ...state,
                success: action.payload,
            }

        case Actions.REMOVE_SUCCESS:
            return {
                ...state,
                success: null,
            }

        default:
            return state
    }
}

export default messagesReducer
