import React from "react"
import PageTemplate from '../../template/Page.template'
import HistoryContentPage from './HistoryContent.page'

const HistoryPage = () => {
    return (
        <PageTemplate>
            <HistoryContentPage />            
        </PageTemplate>
    )
}

export default HistoryPage
