export const styles = theme => ({
    root: {
        width:"100%",
        marginTop: theme.spacing(20),
        fontSize: "26px",
        fontWeight: "bold",
        textAlign: "center",
        backgroundColor:"#fff",
        color: "#5ad8cc",
        boxShadow:"0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)"
    },
    text:{
        marginTop: theme.spacing(2),
        padding:"23px",
        fontSize: "22px"
    },
    button:{
        marginTop:theme.spacing(2)
    }
})