import { connect } from "react-redux"
import AddActions from "../actions/Add.actions";

const AddContainer = ({
    children,
    params,
    request,
    refresh,
    addItem,
    item,
    node,
    callback,
}) => {

    const onSubmit = (values) => {
        addItem({
            params: {
                ...params,
                values
            },
            request: request,
            node: node,
            refresh: refresh ? refresh : null,
            callback: callback
        })
    }
    
    return children?  children({ item, onSubmit }) : null            
}

const mapStateToProps = (state, ownProps) => ({
    item: ownProps.selector? ownProps.selector(state) : null
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    addItem: (params) => {
        dispatch(AddActions.addItem(params))
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(AddContainer)