import { appColor } from "../../library/config/colors/colors";

export const styles = theme => ({
    summary:{
        backgroundColor: appColor.primary.main,        
    },
    details:{
        padding: 0
    },
    list:{
        backgroundColor: appColor.white,
        marginLeft: theme.spacing(2),
        padding: theme.spacing(2)
    },  
    listTitle:{
        fontSize: '22px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        textAlign: 'left',
        color: appColor.primary.main
    },
    title: {
        fontSize: '22px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        textAlign: 'left',
        color: appColor.white
    },
})