import React from "react"
import { withStyles } from "@material-ui/core/styles"
import FormHelperText from "@material-ui/core/FormHelperText"

import { styles } from "../../../styles/form/editError/EditError.component.style"

const EditErrorTemplate = ({ classes, message }) => {
    return <FormHelperText className={classes.error}>{message}</FormHelperText>
}

export const EditErrorComponent = withStyles(styles)(EditErrorTemplate)
