import React from "react"
import { withStyles } from "@material-ui/core/styles"
import { styles } from "./Content.template.style"
import Box from '@material-ui/core/Box';
import Divider from '../library/materialUi/divider/Divider.component'
import Paper from '@material-ui/core/Paper'

const ContentTemplate = ({
    classes,
    children,
    title,
}) => {

    return (
        <Paper>
            {title ?
                <>
                    <Box display="flex" flexDirection="row" className={classes.root}>
                        <Box className={classes.title} flexGrow={1}>
                            {title}
                        </Box>
                    </Box>
                    <Divider />
                </> : null}
            <Box>
                {children}
            </Box>
        </Paper>
    )
}

export default withStyles(styles)(ContentTemplate)