import React from "react"
import { withRouter } from "react-router"
import _isEmpty from 'lodash/isEmpty'
import { useAuthContext } from '../../../contexts/auth/Auth';

import DetailContainer from '../../detail/container/Detail.container'
import { selectTherapy } from "../selector/Therapy.selector";
import Request from '../api/Therapy.api'


const TherapyDetailContainer = ({
    children,
    match,
    selects,
    documentId,
}) => {

    const patientId = match.params.patient
    const { authToken } = useAuthContext()

    return (
        <DetailContainer
            params={{
                access_token: authToken.access_token,
                patientId: patientId,
                id: documentId
            }}
            request={Request.therapyDetail}
            selector={selectTherapy}
            node='THERAPY'>
            {children}
        </DetailContainer>

    )
}


export default withRouter(TherapyDetailContainer)