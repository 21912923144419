import String from "../../res/Strings_ITA.res"

export const PathologyConf = {
    columns: [
        {
            size: 1,
            title: String.pathology.title,
            border: { top: 0 , right: 0, bottom: 0, left: 0 }
        }
    ],    
    body: {
        ischemic_heart_disease: {
            component: 'Checkbox',
            name: 'ischemic_heart_disease',
            label: String.pathology.ischemic,
            required: false,
            disabled: false,
            xs: 6,
            column: 0,
            validation: {
                message: String.general.alert
            },
        },
        heart_failure: {
            component: 'Checkbox',
            name: 'heart_failure',
            label: String.pathology.failure,
            required: false,
            disabled: false,
            xs: 6,
            column: 0,
            validation: {
                message: String.general.alert
            },
        }           
    },
    footer: {
        columns: [
            {
                component: 'ButtonSubmit',
                title: String.therapy.button,
                variant: 'contained',
                color: 'primary',
            },
        ]
    }
};

export const AFibPathologyConf = {
    columns: [
        {
            size: 1,
            title: String.pathology.fibrillation,
            border: { top: 0 , right: 0, bottom: 0, left: 0 }
        }
    ],    
    body: {
        type: {
            component: 'Select',
            name: 'type',
            label: String.pathology.typeAFib,
            required: false,
            disabled: false,
            xs: 6,
            column: 0,
            validation: {
                message: String.general.alert
            },
        },
        implantable_mechanical_valve: {
            component: 'Checkbox',
            name: 'implantable_mechanical_valve',
            label: String.pathology.valve,
            required: false,
            disabled: false,
            xs: 6,
            column: 0,
            validation: {
                message: String.general.alert
            },
        }         
    },
    footer: {
        columns: [
            {
                component: 'ButtonSubmit',
                title: String.therapy.button,
                variant: 'contained',
                color: 'primary',
            },
        ]
    }
};

export const AFlutterConf = {
    columns: [
        {
            size: 1,
            title: String.pathology.flutter,
            border: { top: 0 , right: 0, bottom: 0, left: 0 }
        }
    ],    
    body: {
        type: {
            component: 'Select',
            name: 'type',
            label: String.pathology.typeAFlutter,
            required: false,
            disabled: false,
            xs: 6,
            column: 0,
            validation: {
                message: String.general.alert
            },
        },
        implantable_mechanical_valve: {
            component: 'Checkbox',
            name: 'implantable_mechanical_valve',
            label: String.pathology.valve,
            required: false,
            disabled: false,
            xs: 6,
            column: 0,
            validation: {
                message: String.general.alert
            },
        }         
    },
    footer: {
        columns: [
            {
                component: 'ButtonSubmit',
                title: String.therapy.button,
                variant: 'contained',
                color: 'primary',
            },
        ]
    }
};



export const Panels = [
    {
        id: 0,
        title: String.pathology.title,
    },
    {
        id: 1,
        title: String.pathology.fibrillation,
    },
    {
        id: 2,
        title: String.pathology.flutter,
    }
]