import React from "react"
import { ListContext } from "../context"

const ListProvider = ({
    count,
    page,
    rowsPerPage,
    rowsPerPageOptions,
    selected = [],
    toolbar,
    head,
    body,
    children,
    data = [],
    onChangePage = () => { },
    onChangeRowsPerPage = () => { },
    onDeleteAction = () => { },
    onAddAction = () => { },
    onEditAction = () => { },
    onSelectAll = () => { },
    onSelect = () => { },
    onDetailAction = () => { }
}) => {
    return (
        <ListContext.Provider
            value={{
                listContext: {
                    head: head,
                    body: body,
                    data: data,
                    count: count,
                    selected: selected,
                    page: page,
                    rowsPerPage: rowsPerPage,
                    rowsPerPageOptions: rowsPerPageOptions,
                    toolbar: toolbar,
                    actions: {
                        onAddAction: onAddAction,
                        onDeleteAction: onDeleteAction,
                        onEditAction: onEditAction,
                        onDetailAction: onDetailAction,
                        onSelectAll: onSelectAll,
                        onSelect: onSelect,
                        onChangePage: onChangePage,
                        onChangeRowsPerPage: onChangeRowsPerPage
                    }
                }
            }}
        >
            {children}
        </ListContext.Provider>
    )
}
export default ListProvider
