import String from "../../res/Strings_ITA.res"

export const LoginFormConf = {
	columns: [
		{
			size: 1,
			border: { top: 0, right: 0, bottom: 0, left: 0 }
		}
	],
	body: {
		username: {
			component: "EditText",
			name: "username",
			label: String.login.username,
			required: true,
			disabled: false,
			type: "text",
			column: 0,
            xs: 12,
			validation: {
				message: String.login.userMessage
			}
		},
		password: {
			component: "EditText",
			name: "password",
			label: String.login.password,
			required: true,
			disabled: false,
			column: 0,
            xs: 12,
			type: "password",
			validation: {
				message: ""
			}
		}
	},
	footer: {
		columns: [
			{
				component: "ButtonSubmit",
				title: String.login.button,
				variant: "contained",
				color: "primary"
			},
		]
	}
};
